import React from "react";
import DraggableElement from "./DraggableElement";
import "./ImageElement.scss";

export const ElementTypeHr = "element-hr";
export const ElementOptionsHr = [
	{
		label: "Appearance",
		fields: []
	}
];

export default class HrElement extends React.PureComponent {
	render () {
		const { innerRef, ...props } = this.props;

		return (
			<DraggableElement
				classes={["image-element"]}
				innerRef={innerRef}
				{...props}
			>
				<hr/>
			</DraggableElement>
		);
	}
}
