import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import config, { CfgColors } from "../../../config";

import "./Modal.scss";

class Modal extends PureComponent {
	render () {

		let { children, closeModal, modalState, title, icon, headBgColor, fontColor, width, maxWidth } = this.props;
		if (!modalState) {
			return null;
		}

		const colourCfg = config.get(CfgColors);

		if (!headBgColor) {
			headBgColor = "whitesmoke";
		}
		if (!fontColor) {
			fontColor = colourCfg.black;
		}

		return (
			<div className="modal is-active">
				<div className="modal-background" onClick={closeModal}/>
				<div
					className="modal-card" style={{
					width: width || undefined,
					maxWidth: maxWidth || undefined
				}}
				>
					<header className="modal-card-head" style={{ backgroundColor: headBgColor }}>
						{icon
							? (
								<p className="modal-card-title with-icon" style={{ color: fontColor }}>
                  <span className="icon">
                    <i className={icon}/>
                  </span>
									<span>{title}</span>
								</p>
							)
							: <p className="modal-card-title" style={{ color: fontColor }}>{title}</p>}
						<button className="delete" aria-label="close" onClick={closeModal}/>
					</header>
					{children}
				</div>
			</div>
		);
	}
}

Modal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	modalState: PropTypes.bool.isRequired,
	title: PropTypes.string,
	icon: PropTypes.string,
	headBgColor: PropTypes.string,
	fontColor: PropTypes.string
};

export default Modal;
