import React from "react";
import "./PageBuilderDeleteDropZone.scss";
import { Droppable } from "react-beautiful-dnd";

export const PageBuilderDeleteDropZone = ({ allowType = "DEFAULT" }) => (
	<div className="up-pb-delete-zone">
		<Droppable droppableId="DELETE" type={allowType}>
			{(provided) => (
				<div
					ref={provided.innerRef}
					{...provided.droppableProps}
				>
					{provided.placeholder}
				</div>
			)}
		</Droppable>
		<span>
      <i className="fas fa-trash"/>
    </span>
	</div>
);
