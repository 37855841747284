import { all } from "redux-saga/effects";

import coreSagas from "../core/sagas";
import canvasSagas from "../canvas/sagas";
import pagesSagas from "../sections/nanosites/sagas";

export default function * rootSaga () {
	yield all([
		...coreSagas,
		...canvasSagas,
		...pagesSagas
	]);
}
