import React from "react";
import "./PageCanvas.scss";
import { Droppable } from "react-beautiful-dnd";
import { Layout } from "./elements/Layout";
import { Formik } from "formik";

class PageCanvas extends React.PureComponent {
	render () {
		const { page, components, setActiveElement, setValue } = this.props;

		return (
			<div className="up-pb-canvas">
				<div className="content">

					<Formik initialValues={{}} onSubmit={() => {}}>
						<Droppable droppableId="CANVAS" type="LAYOUT">
							{(provided) => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
									className="up-pb-canvas-body"
								>
									{page.sections.map((section, index) => {
										section = {
											id: section.id,
											type: section.type,
											props: section.props,
											layout: { ...components[section.type] },
											columns: section.children.map((c, idx) => {
												return {
													...(page.columns?.[section.id]?.[idx] || {}),
													children: c.map((cc) => {
														return { ...page.components[cc] };
													})
												};
											})
										};

										return <Layout key={section.id} index={index} def={section}
													   setActiveElement={setActiveElement} setValue={setValue}/>;
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</Formik>

					{/* and, TODO render the (selected) client footer here */}
					<div className="footer">
						&copy; Noot Pty. Ltd. {new Date().getFullYear()}
					</div>
				</div>
			</div>
		);
	}
}

export default PageCanvas;
