import resourcesSagas from "./resources";
import authSagas from "./auth";
import preferenceSagas from "./preferences";
import dataSagas from "./data";

export const coreSagas = [
	...resourcesSagas,
	...authSagas,
	...preferenceSagas,
	...dataSagas
];

export default coreSagas;
