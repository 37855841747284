import React, { Component } from "react";
import PropTypes from "prop-types";

import WindowTaskBar, { TaskBarAction } from "../../../../components/common/window/WindowTaskBar";
import { EnvironmentScopes } from "./Environments";

export class EnvironmentsWindowContent extends Component {
	render () {
		const {
			model, openNewModal, doUpdateEnvironment, doDeleteEnvironment, doUpsertVariable, doDeleteVariable,
			preferences, setPreference, requestElevation, fetchEnvironments, openConfirm, update
		} = this.props;

		const actions = [
			<TaskBarAction key="reload" icon="fas fa-sync-alt" onClick={fetchEnvironments}/>,
			<TaskBarAction key="new" icon="fas fa-layer-plus" onClick={openNewModal}/>
		];

		return (
			<>
				<WindowTaskBar
					title="Environments"
					actions={actions}
				/>

				<EnvironmentScopes
					model={model}
					openNewModal={openNewModal}
					doUpdateEnvironment={doUpdateEnvironment}
					doDeleteEnvironment={doDeleteEnvironment}
					doUpsertVariable={doUpsertVariable}
					doDeleteVariable={doDeleteVariable}
					preferences={preferences}
					setPreference={setPreference}
					requestElevation={requestElevation}
					fetchEnvironments={fetchEnvironments}
					openConfirm={openConfirm}
					update={update}
				/>
			</>
		);
	}
}

EnvironmentsWindowContent.propTypes = {
	model: PropTypes.object.isRequired,
	openNewModal: PropTypes.func.isRequired,
	doUpdateEnvironment: PropTypes.func.isRequired,
	doDeleteEnvironment: PropTypes.func.isRequired,
	doUpsertVariable: PropTypes.func.isRequired,
	doDeleteVariable: PropTypes.func.isRequired,
	preferences: PropTypes.object.isRequired,
	setPreference: PropTypes.func.isRequired,
	requestElevation: PropTypes.func.isRequired,
	fetchEnvironments: PropTypes.func.isRequired,
	openConfirm: PropTypes.func.isRequired,
	update: PropTypes.number.isRequired
};

export default EnvironmentsWindowContent;
