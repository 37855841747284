import React from "react";
import "./PageBuilderPanel.scss";

export class PageBuilderPanel extends React.Component {
	render () {
		let { position, isOpen, children } = this.props;
		if (!position) {
			position = "left";
		}

		return (
			<div className={`up-pb-panel up-pb-panel-${position}` + (isOpen ? " is-visible" : "")}>
				<div className="up-pb-panel-body">
					{children}
				</div>
			</div>
		);
	}
}
