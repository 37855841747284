import React from "react";
import DraggableElement from "../DraggableElement";
import InputField from "core/components/common/form/InputField";
import { commonStyleOptions } from "../../component-settings/common-settings";

export const ElementTypeSelectInput = "element-input-select";
export const ElementOptionsSelectInput = [
	{
		label: "Form Input",
		fields: [
			{
				name: "label",
				label: "Label",
				validations: [],
				default: ""
			},
			{
				name: "required",
				type: "bool",
				label: "Required",
				validations: [],
				default: ""
			}
		]
	},
	{
		label: "Appearance",
		fields: commonStyleOptions
	}
];

const SelectInput = ({
	innerRef,
	def,
	label,
	required,
	placeholder,
	type,
	options,
	...props
}) => {
	options = options || [];
	return (
		<DraggableElement
			innerRef={innerRef}
			disableInteractiveElementBlocking
			{...props}
		>
			<InputField
				name="something"
				type="select"
				label={label}
				options={options}
				required={required}
				placeholder={placeholder}
				autoComplete={false}
				disabled
			/>
		</DraggableElement>
	);
};

export default SelectInput;
