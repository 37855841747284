import newSagas from "./new";
import updateSagas from "./update";
import deleteSagas from "./delete";

import variableSagas from "./variable";
import pathSagas from "./path";

export const environmentSagas = [
	...newSagas,
	...updateSagas,
	...deleteSagas,
	...variableSagas,
	...pathSagas
];

export default environmentSagas;
