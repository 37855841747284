import {Alert, Button, CircularProgress} from "@mui/material";
import {useQuery, useQueryClient} from "react-query";
import {connect} from "react-redux";
import {authTokenPropProvider} from "../../../../../common/tokenProvider";
import "./overview.scss";
import {fromNow} from "../../../../../util/datetime";
import classNames from "classnames";
import {useState} from "react";
import {PlaygroundRun, PlaygroundRunStatus, PlaygroundDispatchApi} from "../../../../api/playground/dispatch";


type PlaygroundDebuggerOverviewProps = {
    token: string;
    playgroundId: string;
}

function PlaygroundDebuggerItemStatus({status}: { status: PlaygroundRunStatus }) {

    return <span className={classNames({
        "status": true,
        "good": status === "COMPLETED" || status === "RUNNING",
        "warning": status === "PENDING" || status === "ENDED",
        "error": status === "FAILED",
    })}>{status}</span>;
}

function PlaygroundDebuggerRunItem(props: { run: PlaygroundRun, onClick?: () => void }) {
    return <div className={"playground-run-item"} onClick={props.onClick}>
        <div className="run-item-column code">
            <span>RUN</span> {props.run.id}</div>
        <div className="run-item-column">
            <PlaygroundDebuggerItemStatus status={props.run.status}/>
        </div>
        <div className="run-item-column">{fromNow(props.run.created_on)}</div>
    </div>;
}

function PlaygroundDebuggerRunView(props: { run: PlaygroundRun, token: string }) {
    const {isFetching, data: logs, error} = useQuery("playground.debugger.overview.logs", () => {
        if (props.token) {
            const api = new PlaygroundDispatchApi(props.token);
            return api.getRunLogs(props.run.id);
        }
        return null;
    });

    if (isFetching || !logs) {
        return <CircularProgress/>;
    }

    if (error) {
        console.error(error);
        return <Alert severity="error">Unable to fetch data...</Alert>;
    }

    return <div>
        {logs.map((log) => {
            return <div key={log.id}>{log.log}</div>;
        })}
    </div>;
}


export function PlaygroundDebuggerOverviewInternal(props: PlaygroundDebuggerOverviewProps): JSX.Element {
    const {token, playgroundId} = props;
    const queryClient = useQueryClient();

    const [run, setRun] = useState<PlaygroundRun | null>(null);
    const {isFetching, data: runs, error} = useQuery("playground.debugger.overview.runs", () => {
        if (token) {
            const api = new PlaygroundDispatchApi(token);
            return api.getRuns(playgroundId);
        }
        return null;
    });

    if (isFetching || !runs) {
        return <CircularProgress/>;
    }

    if (error) {
        return <Alert severity="error">Unable to get latest runs...</Alert>;
    }

    if (runs.length === 0)
        return <Alert severity={"info"}><p>There are no runs yet in this playground.</p></Alert>;

    if (run != null) {
        return <>
            <Button onClick={() => setRun(null)}>Back</Button>
            <PlaygroundDebuggerRunView run={run} token={props.token}/>
        </>;
    }

    return <div className={"playground-run-listing"}>
        <Button onClick={() => queryClient.invalidateQueries("playground.debugger.overview.runs")}>Refresh</Button>
        {runs.map((run) => <PlaygroundDebuggerRunItem key={run.id} run={run} onClick={() => setRun(run)}/>)}
    </div>;
}

export const PlaygroundDebuggerOverview = connect(authTokenPropProvider)(PlaygroundDebuggerOverviewInternal);
export default PlaygroundDebuggerOverview as any;
