import React, { Component } from "react";
import PropTypes from "prop-types";
import Inlet from "./Inlet";

export class Inlets extends Component {
	render () {
		const { playgroundProps } = this.props;
		const { dashboard } = playgroundProps;
		const { playground } = dashboard;

		return (
			<>
				<div className="pg-inlets">
					{playground.schema.inlets.map((inlet, i) =>
						<Inlet
							key={`pg-inlet-${i}`} playgroundProps={playgroundProps}
							inlet={inlet} index={i} count={playground.schema.inlets.length}
						/>
					)}
				</div>
			</>
		);
	}
}

Inlets.propTypes = {
	playgroundProps: PropTypes.object.isRequired
};

export default Inlets;
