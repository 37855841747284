import React, { Component } from "react";
import { connect } from "react-redux";

import config, { CfgColors } from "../../../config";

import Modal from "core/components/common/modal/Modal";
import { CloseNewEnvironmentModalAction, EnvironmentNewAction } from "../../../actions/data/environment/new";
import EnvironmentNewForm from "../../../components/settings/environments/EnvironmentNewForm";
import { EnvironmentVariableQueryAction } from "../../../actions/data/environment/variable/query";
import { EnvironmentScopeAccount } from "../../../lib/constants";

class EnvironmentNewModal extends Component {
	render () {
		let {
			closeModal, environmentNew, environments, doCreateEnvironment,
			fetchEnvironments
		} = this.props;
		if (!environmentNew) {
			environmentNew = {};
		}

		let model = environmentNew.model;
		if (!model) {
			model = {};
		}

		const open = !!(environmentNew && environmentNew.open);
		if (!open) {
			return null;
		}

		if (environmentNew.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const colourCfg = config.get(CfgColors);

		const onSubmit = (form) => {
			doCreateEnvironment({
				model: form,
				onSuccess: () => {
					fetchEnvironments();
				}
			});
		};

		return (
			<>
				<Modal
					title="Create Environment"
					closeModal={closeModal}
					icon="fas fa-layer-plus"
					modalState={open}
					headBgColor={colourCfg.blue}
					fontColor={colourCfg.white}
				>
					<EnvironmentNewForm
						model={model}
						environmentsModel={environments}
						onSubmit={onSubmit}
						buttonBgColor={colourCfg.blue}
						buttonFontColor={colourCfg.white}
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseNewEnvironmentModalAction()),
		doCreateEnvironment: (payload) => dispatch(EnvironmentNewAction(payload)),
		fetchEnvironments: () => dispatch(EnvironmentVariableQueryAction({
			model: {
				scope: EnvironmentScopeAccount,
				path: EnvironmentScopeAccount
			}
		}))
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	const data = core.get("data");
	const environment = data.get("environment");
	const variable = environment.get("variable");

	return {
		core: core,
		environmentNew: environment.get("new"),
		environments: variable.get("query")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentNewModal);
