import React from "react";
import { cloneDeep, isFunction } from "lodash";

import "./PageBuilderComponentSettings.scss";
import { options } from "../elements";
import { commonStyleOptions } from "./common-settings";
import { columnSchema } from "../elements/StructureElements";
import { DynamicForm } from "core/components/common/form";
import config from "canvas/config";
import { CfgColors } from "core/config";

const mergeDefaultVaules = ({ schema, currentValues }) => {
	return Object.assign({},
		// Smush nested schema fields into a { field: value } object
		Object.fromEntries(
			// Combine each option from the 'schema' into [[name, default], ...]
			(schema ?? []).reduce((acc, cur) => {
				return acc.concat(
					cur.fields.map(o => [o.name, currentValues?.[o.name] ?? o.default])
				);
			}, [])
		)
	);
};

const formatFields = (which, section) => {
	section = cloneDeep(section);
	return section.map(s => ({
		...s,
		fields: s.fields.map(f => ({
			...f,
			prefixName: which,
			name: f.name
		}))
	}));
};

/**
 * Remove empty values from the response data
 * @param input
 * @returns {{}}
 */
const cleanResponseValues = (input) => {
	const out = {};
	for (const i in input) {
		if (!Object.prototype.hasOwnProperty.call(input, i)) continue;

		out[i] = {};
		for (const key in input[i]) {
			if (!Object.prototype.hasOwnProperty.call(input[i], key)) continue;

			if (typeof input[i][key] !== "undefined" && input[i][key] !== "") {
				out[i][key] = input[i][key];
			}
		}
	}
	return out;
};

export const PageBuilderComponentSettings = ({ selected, onSubmit, onClose }) => {
	const { component, layout, column } = selected;

	const colourCfg = config.get(CfgColors);

	const handleFormSubmission = (values) => {
		if (isFunction(onSubmit)) {
			onSubmit({
				component: component.id,
				layout: layout.id,
				column: column.id,
				values: cleanResponseValues(values)
			});
		}
	};

	const schema = { sections: [] };
	let formValues = {};

	if (component) {
		schema.sections.push({
			label: "Element",
			position: 1,
			panels: formatFields("component", options[component.type])
		});
		formValues = {
			component: mergeDefaultVaules({
				schema: options[component.type],
				currentValues: component.props
			})
		};
	}

	if (column) {
		schema.sections.push({
			label: "Column",
			position: 2,
			panels: formatFields("column", columnSchema)
		});
		formValues = { ...formValues, column: mergeDefaultVaules({ schema: columnSchema, currentValues: column }) };
	}

	if (layout) {
		schema.sections.push({
			label: "Layout",
			position: 3,
			panels: formatFields("layout", [
				{
					label: "Appearance",
					fields: commonStyleOptions
				}
			])
		});
		formValues = {
			...formValues,
			layout: mergeDefaultVaules({ schema: [{ fields: commonStyleOptions }], currentValues: layout.props ?? {} })
		};
	}

	return (
		<div className="up-pb-element-settings">
			<button className="delete" onClick={onClose}/>
			<DynamicForm
				key={component.id}
				formValues={formValues}
				formSchema={schema}
				onSubmit={handleFormSubmission}
				buttonLabel="Save Changes"
				buttonBgColor={colourCfg.blue}
			/>
		</div>
	);
};
