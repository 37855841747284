import { call, put, takeLatest } from "redux-saga/effects";
import {
	EnvironmentNewAction,
	EnvironmentNewFailedAction,
	EnvironmentNewRequestAction,
	EnvironmentNewSuccessAction
} from "../../../actions/data/environment/new";
import { postEnvironment } from "../../../api/data/environment/new";

/// Environment
// Worker
function * postEnvironmentWorker (action) {
	try {
		yield put(EnvironmentNewRequestAction());

		const { onSuccess, ...payload } = action.payload;

		const r = yield call(postEnvironment, payload);
		if (r.json) {
			if (onSuccess) {
				yield onSuccess(r.json);
			}

			yield put(EnvironmentNewSuccessAction(r.json));
		} else {
			yield put(EnvironmentNewFailedAction("Failed to create environment"));
		}
	} catch (e) {
		yield put(EnvironmentNewFailedAction(e));
	}
}

// Saga
export function * postEnvironmentSaga () {
	yield takeLatest(EnvironmentNewAction.getType(), postEnvironmentWorker);
}

// Export

export const newSagas = [
	postEnvironmentSaga()
];

export default newSagas;
