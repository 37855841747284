import { call, put, takeLatest } from "redux-saga/effects";
import {
	EnvironmentVariableDeleteAction,
	EnvironmentVariableDeleteFailedAction,
	EnvironmentVariableDeleteRequestAction,
	EnvironmentVariableDeleteSuccessAction
} from "../../../../actions/data/environment/variable/delete";
import { deleteEnvironmentVariable } from "../../../../api/data/environment/variable/delete";

/// Environment Variable
// Worker
function * deleteEnvironmentVariableWorker (action) {
	try {
		yield put(EnvironmentVariableDeleteRequestAction());

		const { onSuccess, ...payload } = action.payload;

		const r = yield call(deleteEnvironmentVariable, payload);
		if (r.json) {
			if (onSuccess) {
				yield onSuccess(r.json);
			}

			yield put(EnvironmentVariableDeleteSuccessAction(r.json));
		} else {
			yield put(EnvironmentVariableDeleteFailedAction("Failed to delete environment variable"));
		}
	} catch (e) {
		yield put(EnvironmentVariableDeleteFailedAction(e));
	}
}

// Saga
export function * deleteEnvironmentVariableSaga () {
	yield takeLatest(EnvironmentVariableDeleteAction.getType(), deleteEnvironmentVariableWorker);
}

// Export

export const deleteSagas = [
	deleteEnvironmentVariableSaga()
];

export default deleteSagas;
