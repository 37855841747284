import { registerLoadingActions } from "../../loading";
import { registerErrorActions } from "../../error";
import {
	EnvironmentUpdateAction,
	EnvironmentUpdateFailedAction,
	EnvironmentUpdateSuccessAction
} from "../../../actions/data/environment/update";

registerLoadingActions(
	[EnvironmentUpdateAction.getType()],
	[EnvironmentUpdateSuccessAction.getType(), EnvironmentUpdateFailedAction.getType()]
);

registerErrorActions([
	EnvironmentUpdateFailedAction.getType()
]);

export const updateReducer = (state = null, action) => {
	switch (action.type) {
		default:
			return state;
	}
};
