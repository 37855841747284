import { call, put, takeLatest } from "redux-saga/effects";
import {
	EnvironmentVariableUpsertAction,
	EnvironmentVariableUpsertFailedAction,
	EnvironmentVariableUpsertRequestAction,
	EnvironmentVariableUpsertSuccessAction
} from "../../../../actions/data/environment/variable/upsert";
import { putEnvironmentVariable } from "../../../../api/data/environment/variable/upsert";

/// Environment Variable
// Worker
function * upsertEnvironmentVariableWorker (action) {
	try {
		yield put(EnvironmentVariableUpsertRequestAction());

		const { onSuccess, ...payload } = action.payload;

		const r = yield call(putEnvironmentVariable, payload);
		if (r.json) {
			if (onSuccess) {
				yield onSuccess(r.json);
			}

			yield put(EnvironmentVariableUpsertSuccessAction(r.json));
		} else {
			yield put(EnvironmentVariableUpsertFailedAction("Failed to upsert environment variable"));
		}
	} catch (e) {
		yield put(EnvironmentVariableUpsertFailedAction(e));
	}
}

// Saga
export function * upsertEnvironmentVariableSaga () {
	yield takeLatest(EnvironmentVariableUpsertAction.getType(), upsertEnvironmentVariableWorker);
}

// Export

export const upsertSagas = [
	upsertEnvironmentVariableSaga()
];

export default upsertSagas;
