import React from "react";
import {useStore} from "react-redux";
import {Profile} from "../store";

export type UserContextType = Profile | null

export const UserContext = React.createContext<UserContextType>(null);

export const UserContextProvider = ({children}: { children?: React.ReactNode }) => {

    const store = useStore();

    const profile = store.getState().get("core")?.get("profile") as Profile | null;

    if (!profile) {
        return <>{children}</>;
    }

    if ("loading" in profile) {
        if (profile.loading)
            return <>{children}</>;
    }

    if (!profile.loggedIn)
        return <>{children}</>;

    return (
        <UserContext.Provider value={profile}>
            {children}
        </UserContext.Provider>
    );
};

export function useToken() {
    const context = React.useContext(UserContext);
    if (!context) {
        throw new Error("useToken must be used within a UserContextProvider");
    }

    return context.auth.token;
}

export function useAccountKey() {
    const context = React.useContext(UserContext);
    if (!context) {
        throw new Error("useToken must be used within a UserContextProvider");
    }

    return context.account.account_key;
}

export function useProfile(): Profile {
    const context = React.useContext(UserContext);
    if (!context) {
        throw new Error("useProfile must be used within a UserContextProvider");
    }

    return context;
}
