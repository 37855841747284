import React from "react";
import "froala-editor/js/froala_editor.min";
import "froala-editor/js/plugins/font_size.min";
import "froala-editor/js/plugins/font_family.min";
import "froala-editor/js/plugins/colors.min";
import "froala-editor/js/plugins/align.min";
import "froala-editor/js/plugins/special_characters.min";
import "froala-editor/js/plugins/table.min";
import "froala-editor/js/plugins/link.min";
import "froala-editor/js/plugins/lists.min";
import FroalaEditor from "react-froala-wysiwyg";
import DraggableElement from "./DraggableElement";
import { commonStyleOptions, gatherElementStyles } from "../component-settings/common-settings";
import "./ParagraphElement.scss";

export const ElementTypeParagraph = "element-p";

export const ElementOptionsParagraph = [
	{
		label: "Appearance",
		fields: commonStyleOptions
	}
];

class ParagraphElement extends React.PureComponent {
	static defaultProps = {
		renderAs: "div",
		classes: []
	};

	config = {
		toolbarInline: true,
		toolbarButtons: {
			moreText: {
				buttons: ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript", "fontFamily", "fontSize", "textColor", "backgroundColor", "inlineClass", "inlineStyle", "clearFormatting"]
			},
			moreParagraph: {
				buttons: ["alignLeft", "alignCenter", "formatOLSimple", "alignRight", "alignJustify", "formatOL", "formatUL", "paragraphFormat", "paragraphStyle", "lineHeight", "outdent", "indent", "quote"]
			},
			moreMisc: {
				buttons: ["undo", "redo"],
				align: "right"
			}
		}
	};

	render () {
		const { id, value, children, classes, innerRef, setValue, ...props } = this.props;

		return (
			<DraggableElement
				id={id}
				classes={[...classes]}
				{...props}
				innerRef={innerRef}
				style={gatherElementStyles(props)}
			>
				<FroalaEditor
					config={this.config}
					model={value}
					onModelChange={(value) => setValue && setValue(value)}
				/>
			</DraggableElement>
		);
	}
}

export default ParagraphElement;
