import { FieldTypeNumber, FieldTypeTextArea } from "core";

export const commonStyleOptions = [
	{
		name: "borderWidth",
		type: FieldTypeNumber,
		label: "Border Width",
		validations: [],
		default: 0
	},

	{
		name: "borderColor",
		type: "color",
		label: "Border Colour",
		validations: [],
		default: ""
	},

	{
		name: "borderRadius",
		type: FieldTypeNumber,
		label: "Border Radius",
		validations: [],
		default: 0
	},

	{
		name: "padding",
		type: FieldTypeNumber,
		label: "Padding",
		validations: [],
		default: 0
	},

	{
		name: "customStyles",
		type: FieldTypeTextArea,
		label: "Custom Styles",
		placeholder: "color: hotpink;\nbox-shadow: 0 0 0 hotpink;\n...",
		validations: [],
		default: ""
	}
];

export const gatherElementStyles = (fromProps) => {
	const styles = {};

	if (fromProps.borderRadius) {
		styles.borderRadius = fromProps.borderRadius;
	}

	if (fromProps.padding) {
		styles.padding = fromProps.padding;
	}

	if (fromProps.borderWidth) {
		styles.borderColor = fromProps.borderColor;
		styles.borderWidth = fromProps.borderWidth;
		styles.borderStyle = fromProps.borderStyle || "solid";
	}

	if (fromProps.customStyles) {
		return {
			...styles,
			...parseCustomCssRules(fromProps.customStyles)
		};
	}

	return styles;
};

/**
 * Turn arbitrary CSS rules into camelCased objects
 *
 * @param {string} input A string of CSS rules, excluding a selector and { squiggly braces }
 * @returns {object} camelCase-keyed object containing CSS rules
 *
 * @example
 *    parseCustomCssRules('color: hotpink; box-shadow: 1px 1px 1px magenta')
 *    // { color: "hotpink", boxShadow: "1px 1px 1px magenta" }
 */
export const parseCustomCssRules = (input) => Object.fromEntries(
	input
		.split(";")
		.map((declaration) => {
			return declaration.trim().toLowerCase();
		})
		// Remove empty elements from array
		.filter(d => !!d)
		// Turn into [property, value] array
		.map(d => {
			const [property, ...value] = d.split(":");
			return [
				// property name -> camelCase
				property.trim().replace(/-([a-z])/g, (_, letter) => letter.toUpperCase()),
				// I suppose it's sometimes valid to have a ":" in the value
				value.join(":").trim()
			];
		})
);
