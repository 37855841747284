import React, {SyntheticEvent, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {Alert, Button, MenuItem, Select, Tab, Tabs} from "@mui/material";
import {API_BASE_URL, FormatData} from "./formatters/formatter";
import {format_js} from "./formatters/format_js";
import {format_curl} from "./formatters/format_curl";
import {format_py} from "./formatters/format_py";
import "./TestCaseWizardForAPI.scss";

import {Playground, Variable} from "../types";
import {Environment, EnvironmentSpec, flattenCategories} from "./EnvironmentPicker";
import SyntaxHighlighter from "react-syntax-highlighter";
import {UpwireModalCentered, UpwireModalLoading} from "../../common/modal/modal";
import {a11yDark as darkCodeStyle, a11yLight as lightCodeStyle} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import {useAccountKey, useToken} from "../../../../state/UserContextProvider";
import {filterValidTests, SavedTestCase, TestCase, useTestRunnerDispatchApi} from "../../common/testCase";
import {usePlaygroundDispatchApi} from "../../../api/playground/dispatch";

type CodeBlockProps = {
    children?: string[] | string;
    language?: string;
    mode?: "light" | "dark";
}
const CodeBlock = ({mode = "light", language = "javascript", children}: CodeBlockProps) => {

    if (!children)
        return <></>;

    let code = (typeof children === "string" ? [children] : children).join("");
    code = code.replace(/^\s+|\s+$/g, "");

    const style = mode == "light" ? lightCodeStyle : darkCodeStyle;

    return (
        <div className="syntax-highlight">
            <SyntaxHighlighter language={language} wrapLongLines={true} style={style}>
                {code}
            </SyntaxHighlighter>
        </div>
    );
};

type ApiBlockProps = {
    endpoint: string;
    payload?: any;
}

export const ApiBlock = ({payload, endpoint}: ApiBlockProps) => {

    type Format = "payload" | "javascript" | "python" | "curl";

    const [format, setFormat] = useState<Format>("payload");
    const changeTab = (event: SyntheticEvent, format: Format) => {
        setFormat(format);
    };

    const token = useToken();
    const accountKey = useAccountKey();

    const formatData: FormatData = {
        token: token,
        accountKey: accountKey,
        payload,
        endpoint,
    };

    const jsCode = format_js(formatData);
    const pyCode = format_py(formatData);
    const curlCode = format_curl(formatData);

    let exampleTabContent: JSX.Element;
    if (format === "payload") {
        exampleTabContent = <div>
            <div className="payload-endpoint">
                <div>POST to the following endpoint with the payload shown below.</div>
                <div className="endpoint-view">{API_BASE_URL + "/" + endpoint}</div>
                <div></div>
            </div>

            <CodeBlock>{JSON.stringify(payload, null, 2)}</CodeBlock>
        </div>;
    } else if (format === "javascript") {
        exampleTabContent = <CodeBlock>{jsCode.code}</CodeBlock>;
    } else if (format === "python") {
        exampleTabContent = <CodeBlock language={pyCode.language}>{pyCode.code}</CodeBlock>;
    } else if (format === "curl") {
        exampleTabContent = <CodeBlock language={curlCode.language}>{curlCode.code}</CodeBlock>;
    } else {
        throw new Error(`Unknown format: ${format}`);
    }

    return <div className="code-part">
        <div className="tab-container">
            <Tabs value={format} onChange={changeTab}>
                <Tab label="Payload" value="payload"/>
                <Tab label="Curl" value="curl"/>
                <Tab label="Javascript" value="javascript"/>
                <Tab label="Python" value="python"/>
            </Tabs>
        </div>
        {exampleTabContent}
    </div>;

};


type ApiPayloadData = {
    environmentName: string | null;
    testCase: TestCase | null;
    variables: Variable[];
    playgroundId: string;
    namespace: string;
    playgroundVersion: string;
}

export function constructApiPayload(data: ApiPayloadData): [string, any] {
    const endpoint = `api/v1/playground/${data.namespace}/${data.playgroundId}/exec/${data.environmentName}`;
    if (!data.environmentName) {
        return ["", {"error": "No environment selected"}];
    }

    const params: any = {};
    for (let variable of data.variables) {
        let value: string = variable.default ?? "";
        if (data.testCase)
            if (variable.name in data.testCase.values)
                value = data.testCase.values[variable.name];

        params[variable.name] = value;
    }

    const payload = {
        "params": params,
        "version": data.playgroundVersion,
    };

    return [endpoint, payload];
}

export type ApiWizardLoadedProps = {
    testCases: SavedTestCase[]
    playground: Playground;
    environments: Environment[],
}

export function ApiWizardLoaded(props: ApiWizardLoadedProps) {
    const {testCases, playground, environments} = props;

    const [testCaseId, setTestCaseId] = useState<string | null>(null);
    const [environmentName, setEnvironmentName] = useState<string | null>(environments[0]?.name ?? null);
    const [playgroundVersion, setPlaygroundVersion] = useState<string>("head");


    const playgroundApi = usePlaygroundDispatchApi();
    const accountKey = useAccountKey();

    const {
        isLoading: loadingRun,
        mutate: runTestCase,
        data: runResponse,
        error: runError,
        reset: resetRun,
    } = useMutation(async ({testCase, environmentName}: { testCase: TestCase, environmentName: string }) => {
        return await playgroundApi.run(playground.namespace, playground.playground_id,
            environmentName, testCase.values, accountKey);
    });

    if (loadingRun) {
        return <UpwireModalLoading/>;
    }

    if (runError) {
        return <ErrorView error={runError} message={"Unable to run test case"}/>;
    }

    if (runResponse) {
        return <ResponseView response={runResponse} onGoBack={() => {
            resetRun();
        }}/>;
    }
    
    const fields = <div className="fields">
        {testCases.length > 0 && <div className="field">
			<label>Test Case</label>
			<Select value={testCaseId ?? ""}
					onChange={(ev) => setTestCaseId(ev.target.value)}>
                {
                    testCases.map(it => <MenuItem key={it.id} value={it.id}>{it.name}</MenuItem>)
                }
			</Select>
		</div>}

        {
            testCases.length === 0 &&
			<Alert severity="info">Create a valid test case to see an API call with your data</Alert>
        }

        <div className="field">
            <label>Environment</label>
            <Select value={environmentName ?? ""}
                    onChange={(ev) => setEnvironmentName(ev.target.value)}>
                {
                    environments.map(it => <MenuItem
                        key={it.id}
                        value={it.name}
                    >
                        {it.name}
                    </MenuItem>)
                }
            </Select>
        </div>

        <div className="field">
            <label>Playground Version</label>
            <Select value={playgroundVersion}
                    onChange={(ev) => setPlaygroundVersion(ev.target.value)}>
                <MenuItem value="head">Head - Latest Editor Version</MenuItem>
                <MenuItem value="test">Test - Latest Version promoted to Test</MenuItem>
                <MenuItem value="prod">Prod - Latest Version promoted to Prod</MenuItem>
            </Select>
        </div>
    </div>;

    const testCase = testCases.find(it => it.id === testCaseId) ?? null;

    const [endpoint, payload] = constructApiPayload({
        environmentName: environmentName,
        testCase: testCase,
        variables: playground.settings.variables,
        playgroundId: playground.playground_id,
        namespace: playground.namespace,
        playgroundVersion: playgroundVersion,
    });

    return <div className="execution-block">
        <ApiBlock endpoint={endpoint} payload={payload}/>

        <div className="fields-part">
            <div className="divider-bar">
                {
                    testCase && environmentName &&
					<Button onClick={() => {
                        runTestCase({testCase, environmentName: environmentName});
                    }} variant="contained">Run in browser</Button>
                }
            </div>
            {fields}
        </div>
    </div>;
}

type ResponseViewProps = {
    response: {},
    onGoBack: () => void,
}


function ResponseView(props: ResponseViewProps) {

    return <UpwireModalCentered>
        <h1>API Call Response:</h1>
        <CodeBlock>{JSON.stringify(props.response, null, 2)}</CodeBlock>
        <Button onClick={props.onGoBack} variant="contained">Retry</Button>
    </UpwireModalCentered>;
}

function ErrorView({error, message}: { error: any, message: string }) {
    if (error instanceof Error)
        return <Alert severity="error">{message}: {error.message}</Alert>;
    return <Alert severity="error">{message}</Alert>;
}

export type ApiWizardProps = {
    playground: Playground,
    spec: EnvironmentSpec
}

export function ApiWizard(props: ApiWizardProps) {

    const {playground, spec} = props;
    const playgroundId = playground.playground_id;
    const testRunnerApi = useTestRunnerDispatchApi();

    const {
        isLoading: loading,
        data: testCases,
        error: testCaseLoadError,
    } = useQuery("playground.testCases", async () => {
        const testCases = await testRunnerApi.getTestCases(playgroundId);
        return filterValidTests(testCases, playground.settings.variables);
    });

    if (loading || !testCases)
        return <UpwireModalLoading/>;

    if (testCaseLoadError)
        return <ErrorView error={testCaseLoadError} message={"Failed to load test cases"}/>;


    return <ApiWizardLoaded testCases={testCases}
                            playground={playground}
                            environments={flattenCategories(spec)}/>;
}
