import React from "react";

import MenuItem, {IMenuItem} from "./MenuItem";

import "./MainSideMenu.scss";
import {connect} from "react-redux";
import {authTokenPropProvider} from "../../../common/tokenProvider";
import {useQuery} from "react-query";
import {PrototypesDispatchApi} from "../../../sections/prototypes/PrototypesSection";
import {LinearProgress, Stack} from "@mui/material";

interface IMenuBlock {
    label: string;
    items: IMenuItem[];
}

const MainMenu: IMenuBlock[] = [
    {
        label: "General",
        items: [
            {to: "/", label: "Dashboard", iconClass: "tachometer-alt"},
            {to: "/playgrounds", label: "Playgrounds", iconClass: "code-branch"},
            {to: "/numbers", label: "Numbers", iconClass: "book"},
        ],
    }, {
        label: "Builders",
        items: [
            {to: "/templates/voice", label: "Voice", iconClass: "phone-square-alt"},
            {to: "/templates/sms", label: "SMS", iconClass: "sms"},
            {to: "/templates/email", label: "Email", iconClass: "envelope"},
            // {to: "/pages", label: "Nanosites", iconClass: "browser"},
        ]
    }, {
        label: "Settings",
        items: [
            {to: "/account/environments", label: "Environments", iconClass: "cubes"},
            {to: "/account", label: "My Account", iconClass: "user"},
        ],
    }
];

function MainSideMenuInternal({token}: { token: string }) {

    const {isFetching, data: prototypes} = useQuery("prototypes", () => {
        if (token) {
            const api = new PrototypesDispatchApi(token);
            return api.list();
        }
        return null;
    }, {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });

    const className = "main-side-menu";
    const groupElements = [];
    for (let group of MainMenu) {
        const items = [];

        for (let item of group.items) {
            const key = `menu-item-${group.label}-${item.to}`;
            items.push(<li key={key}><MenuItem {...item}/></li>);
        }

        groupElements.push(
            <React.Fragment key={group.label}>
                <h2 className="menu-label">{group.label}</h2>
                <ul className="menu-list">{items}</ul>
            </React.Fragment>
        );
    }


    if (prototypes && prototypes.length > 0) {
        groupElements.push(
            <React.Fragment key="prototypes">
                <h2 className="menu-label" key="prototypes">Prototypes</h2>
                <ul className="menu-list" key="prototype-list">
                    {prototypes.map(prototype => {
                        return <li key={prototype.id}>
                            <MenuItem to={`/prototypes/${prototype.id}`}
                                      label={prototype.name} iconClass="puzzle-piece"/></li>;
                    })}
                </ul>
            </React.Fragment>
        );
    } else if (isFetching) {
        groupElements.push(<div className="menu-item-loading" key="load-stack">
            <Stack sx={{width: "100%", color: "grey.200"}} spacing={2}>
                <LinearProgress color="inherit"/>
            </Stack>
        </div>);
    }

    return (
        <aside className={className}>
            {groupElements}
        </aside>
    );
}

export const MainSideMenu = connect(authTokenPropProvider)(MainSideMenuInternal);
export default MainSideMenu as any;
