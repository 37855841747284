import React from "react";
import { connect } from "react-redux";
import PageBuilderContent from "../../components/pagebuilder/PageBuilderContent";
import WindowContainer from "components/common/window/WindowContainer";
import WindowContent from "components/common/window/WindowContent";
import Stub from "../../../../components/common/Stub/Stub";

const PageBuilder = ({ components, componentGroups, page, setFullscreen, isFullscreen }) => (
	<>
		<Stub/>
		<WindowContainer isFullscreen={isFullscreen}>
			<WindowContent>
				<PageBuilderContent
					page={page}
					components={components}
					componentGroups={componentGroups}
					setFullscreen={setFullscreen}
					isFullscreen={isFullscreen}
				/>
			</WindowContent>
		</WindowContainer>
	</>
);

const mapDispatchToProps = (dispatch /* , props */) => {
	return {};
};

const mapStateToProps = (state /* , props */) => {
	const pages = state.get("pages");
	const page = pages.get("page");
	const { components, componentGroups } = pages.get("components");

	return {
		components,
		componentGroups,
		page
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PageBuilder);
