import { apiFetch } from "core/api/base";

export function * postPlaygroundExec ({ namespace, playgroundId, environment, model }) {
	return yield apiFetch({
		endpoint: `/v1/playground/${namespace}/${playgroundId}/exec/${environment}`,
		method: "POST",
		authenticated: true,
		body: JSON.stringify(model)
	});
}

export function * getPlaygroundExecByRunId ({ namespace, playgroundId, runId, verbose }) {
	const verboseStr = (verbose) ? "true" : "false";

	return yield apiFetch({
		endpoint: `/v1/playground/${namespace}/${playgroundId}/exec/id/${runId}?verbose=${verboseStr}`,
		method: "GET",
		authenticated: true
	});
}

export function * getPlaygroundExecByCorrelationId ({ namespace, playgroundId, correlationId, verbose }) {
	const verboseStr = (verbose) ? "true" : "false";

	return yield apiFetch({
		endpoint: `/v1/playground/${namespace}/${playgroundId}/exec/correlation/${correlationId}?verbose=${verboseStr}`,
		method: "GET",
		authenticated: true
	});
}

export function * getPlaygroundExecQuery ({
	namespace, playgroundId, env, versionId, jobId,
	correlationId, status, dataSourceTypes
}) {
	return yield apiFetch({
		endpoint: `/v1/playground/${namespace}/${playgroundId}/exec/query`,
		method: "GET",
		authenticated: true,
		params: {
			env: env || "",
			version_id: versionId || "",
			job_id: jobId || "",
			correlation_id: correlationId || "",
			status: status || "",
			data_source_types: dataSourceTypes || ""
		}
	});
}
