import { apiFetch } from "../../../base";
import { getNamespace } from "../../../../lib/auth/token";
import { EnvironmentScopeNamespace } from "../../../../lib/constants";

export function * deleteEnvironmentVariable (payload) {
	const { model } = payload;
	const { scope, environmentName, path, name } = model;

	const namespace = getNamespace();

	return yield apiFetch({
		endpoint: `/data/v1/environment/${namespace}/${environmentName}/variable/${path}:/${name}`,
		method: "DELETE",
		authenticated: true,
		withoutAccount: (scope === EnvironmentScopeNamespace)
	});
}
