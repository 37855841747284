import React, {useState} from "react";
import BigSelector, {BigAction, BigSelectorItem} from "../../components/common/BigSelector/BigSelector";
import {DispatchApi} from "../../core/dispatch/dispatch";
import {useQuery, useQueryClient} from "react-query";
import {Alert, Button, CircularProgress, Dialog} from "@mui/material";
import {useHistory, useRouteMatch} from "react-router-dom";
import "./PrototypesSection.scss";
import {setupFrameTokenProvider} from "./frameTokenProvider";


import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {useToken} from "../../state/UserContextProvider";

type PlaygroundPrototype = {
    name: string;
    url: string;
    id: string;
}


export class PrototypesDispatchApi extends DispatchApi {

    constructor(token: string | undefined | null) {
        super(token, "playground/prototypes");
    }

    async list(): Promise<PlaygroundPrototype[]> {
        return await this.dispatch<PlaygroundPrototype[]>("list");
    }

    async delete(id: string) {
        return await this.dispatch<void>("delete", {prototypeId: id});
    }

    async add(name: string, url: string) {
        return await this.dispatch<void>("add", {name, url});
    }
}


type CreatedPrototype = {
    name: string;
    url: string;
}

type PrototypeCreationProps = {
    open: boolean;
    close: (proto?: CreatedPrototype) => void;
}


export function PrototypesSection() {

    const api = new PrototypesDispatchApi(useToken());
    const client = useQueryClient();
    const [working, setWorking] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const history = useHistory();

    const {isFetching, error, data: prototypes} = useQuery("prototypes", () => {
        return api.list();
    });

    if (error)
        return <Alert severity="error">Unable to fetch prototypes</Alert>;

    const actions: BigAction[] = [
        {
            icon: "fas fa-sync",
            onClick: async () => {
                await client.invalidateQueries("prototypes");
            }
        },
        {
            icon: "fas fa-plus",
            onClick: async () => {
                setDialogOpen(true);
            },
        }];

    const items: BigSelectorItem[] = [];
    if (!isFetching && prototypes) {
        for (let proto of prototypes) {
            items.push({
                id: proto.id,
                name: proto.name,
                subtitle: proto.url,
                icon: "fas fa-puzzle-piece",
                onActivate: () => {
                    history.push("/prototypes/" + proto.id);
                },
                deleter: {
                    onDelete: async () => {
                        try {
                            setWorking(true);
                            await api.delete(proto.id);
                            await client.invalidateQueries("prototypes");
                        } finally {
                            setWorking(false);
                        }
                    }
                }
            });
        }
    }

    let loading = isFetching || working;

    async function closeDialog(proto?: CreatedPrototype) {
        setDialogOpen(false);
        if (proto) {
            setWorking(true);
            await api.add(proto.name, proto.url);
            await client.invalidateQueries("prototypes");
            setWorking(false);
        }
    }

    return <>
        <BigSelector
            title="Prototypes"
            emptyMessage="There are no prototypes defined."
            items={items}
            actions={actions}
            loading={loading}
        />
        <NewPrototypeDialog open={dialogOpen} close={closeDialog}/>
    </>;

}

export function PrototypeFrame() {

    const token = useToken();
    const api = new PrototypesDispatchApi(token);

    setupFrameTokenProvider();

    const {isFetching, error, data: prototypes} = useQuery("prototypes", () => {
        if (!token) return [];
        return api.list();
    });

    let match = useRouteMatch<{ pid: string }>();

    if (!match)
        return null;

    if (isFetching || !token)
        return <div className="full-screen">
            <CircularProgress/>
        </div>;

    if (error || !prototypes)
        return <Alert severity="error">Unable open this prototype</Alert>;


    const prototype = prototypes.find(p => p.id === match.params.pid);

    if (!prototype)
        return <Alert severity="error">Unable open this prototype</Alert>;

    return <iframe title={prototype.name} className="prototype-frame" src={prototype.url}/>;
}

export function NewPrototypeDialog(props: PrototypeCreationProps) {

    const {open, close} = props;

    const [name, setName] = useState("");
    const [url, setUrl] = useState("");

    const nameField = <TextField
        autoComplete={undefined}
        autoFocus margin="dense" label="Name" type="text" fullWidth
        value={name} onChange={(e) => setName(e.target.value)}
        variant="standard"/>;

    const urlField = <TextField
        margin="dense"
        label="URL"
        type="url"
        fullWidth
        value={url} onChange={(e) => setUrl(e.target.value)}
        variant="standard"/>;

    const closeWithData = () => {

        if (name.length > 0 && url.length > 0) {
            const proto = {name, url};
            setName("");
            setUrl("");
            close(proto);
        } else {
            close();
        }

    };

    return (
        <div>

            <Dialog open={open} onClose={closeWithData}>
                <DialogTitle>Add Prototype</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add a new prototype by providing a name, and a url to embed. Note that the embedded page must be
                        trusted, as it will get your JWT token.
                    </DialogContentText>

                    {nameField}
                    {urlField}

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => close()}>Cancel</Button>
                    <Button onClick={closeWithData} color="primary" variant="contained">Add Prototype</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


