import { ColumnElement, ElementTypeColumn, ElementTypeRow, RowElement } from "./StructureElements";
import ParagraphElement, { ElementOptionsParagraph, ElementTypeParagraph } from "./ParagraphElement";
import ImageElement, { ElementOptionsImage, ElementTypeImage } from "./ImageElement";
import HrElement, { ElementOptionsHr, ElementTypeHr } from "./HrElement";
import GenericInput, { ElementOptionsGenericInput, ElementTypeGenericInput } from "./form/GenericInputElement";
import SelectInput, { ElementOptionsSelectInput, ElementTypeSelectInput } from "./form/SelectInputElement";

export const elements = {
	[ElementTypeRow]: RowElement,
	[ElementTypeColumn]: ColumnElement,
	[ElementTypeParagraph]: ParagraphElement,
	[ElementTypeImage]: ImageElement,
	[ElementTypeHr]: HrElement,
	[ElementTypeGenericInput]: GenericInput,
	[ElementTypeSelectInput]: SelectInput
};

export const options = {
	[ElementTypeImage]: ElementOptionsImage,
	[ElementTypeParagraph]: ElementOptionsParagraph,
	[ElementTypeHr]: ElementOptionsHr,
	[ElementTypeGenericInput]: ElementOptionsGenericInput,
	[ElementTypeSelectInput]: ElementOptionsSelectInput
};
