import React from "react";
import DraggableElement from "../DraggableElement";
import InputField from "core/components/common/form/InputField";
import { commonStyleOptions } from "../../component-settings/common-settings";
import { commonFormInputOptions } from "./common-form-options";

export const ElementTypeGenericInput = "element-input-textarea";

export const ElementOptionsGenericInput = [
	{
		label: "Form Input",
		fields: [
			...commonFormInputOptions
		]
	},
	{
		label: "Appearance",
		fields: commonStyleOptions
	}
];

const GenericInput = ({
	innerRef,
	def,
	label,
	required,
	placeholder,
	type,
	...props
}) => {
	return (
		<DraggableElement
			innerRef={innerRef}
			disableInteractiveElementBlocking
			{...props}
		>
			<InputField
				name="something"
				type={type}
				label={label}
				required={required}
				placeholder={placeholder}
				autoComplete={false}
				disabled
			/>
		</DraggableElement>
	);
};

export default GenericInput;
