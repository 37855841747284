import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { GroupVariables } from "./GroupVariables";
import { GroupSettings } from "./GroupSettings";

import "./Group.scss";

// GroupNew

export const GroupNew = ({ path, env, openNewModal }) => {
	return (
		<div className="environment-group-new-outer">
			<div
				className="button is-small environment-group-new"
				onClick={() => {
					openNewModal({
						model: {
							scope: path,
							env: env
						}
					});
				}}
			>
        <span className="icon is-small">
          <i className="fa fa-layer-plus"/>
        </span>
				<span>Create</span>
			</div>
		</div>
	);
};

GroupNew.propTypes = {
	path: PropTypes.string.isRequired,
	env: PropTypes.string.isRequired,
	openNewModal: PropTypes.func.isRequired,
	setPreference: PropTypes.func.isRequired
};

// Group

export class Group extends Component {
	render () {
		const {
			group, env, scope, path, preferencesPath, preferences, setPreference, openConfirm,
			doUpdateEnvironment, doDeleteEnvironment, doUpsertVariable, doDeleteVariable,
			fetchEnvironments, update, allowEdit
		} = this.props;

		const isOurPath = group.scope === path;
		const isLocked = (group && group.settings && group.settings.locked);

		const preferenceKey = `${preferencesPath}.${group.id}.open`;
		const open = preferences[preferenceKey];

		const handleLock = () => {
			if (!isOurPath) {
				return false;
			}

			const settings = group.settings || {};

			doUpdateEnvironment({
				model: {
					...group,
					scope: scope,
					settings: {
						...settings,
						locked: !isLocked
					}
				},
				onSuccess: () => {
					fetchEnvironments();
				}
			});
		};

		const handleSetOpen = (open) => {
			setPreference({
				[preferenceKey]: open
			});
		};

		return (
			<div className="environment-group-outer">
				<div className={classNames("environment-group", { "open": open })}>
					<div className={classNames("environment-group-bar", "text-selection-none", { "open": open })}>
						<div className="start" onClick={handleLock}>
							<div className={classNames(
								"locked-icon",
								{ locked: isLocked },
								{ "can-lock": isOurPath }
							)}
							>
								{isLocked
									? (
										<span key="locked" className="icon">
                      <i className="fad fa-lock"/>
                    </span>
									)
									: (
										<span key="unlocked" className="icon">
                      <i className="fad fa-lock-open"/>
                    </span>
									)}
							</div>
						</div>
						<div className="middle" onClick={() => handleSetOpen(!open)}>
							<div className="group-name">
								{group.name}
							</div>
						</div>
						<div className="end" onClick={() => handleSetOpen(!open)}>
							{open
								? (
									<span key="open" className="icon">
                    <i className="fas fa-chevron-down"/>
                  </span>
								)
								: (
									<span key="closed" className="icon">
                    <i className="fas fa-chevron-right"/>
                  </span>
								)}
						</div>
					</div>
					{open && (
						<div className="environment-group-body">
							{(!isLocked && allowEdit) && (
								<GroupSettings
									group={group}
									env={env}
									scope={scope}
									path={path}
									isLocked={isLocked}
									openConfirm={openConfirm}
									doUpdateEnvironment={doUpdateEnvironment}
									doDeleteEnvironment={doDeleteEnvironment}
									fetchEnvironments={fetchEnvironments}
								/>
							)}
							<GroupVariables
								group={group}
								env={env}
								scope={scope}
								path={path}
								isLocked={isLocked}
								doUpsertVariable={doUpsertVariable}
								doDeleteVariable={doDeleteVariable}
								fetchEnvironments={fetchEnvironments}
								update={update}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

Group.propTypes = {
	group: PropTypes.object.isRequired,
	env: PropTypes.string.isRequired,
	scope: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	inherits: PropTypes.array.isRequired,
	preferencesPath: PropTypes.string.isRequired,
	preferences: PropTypes.object.isRequired,
	setPreference: PropTypes.func.isRequired,
	openConfirm: PropTypes.func,
	doUpdateEnvironment: PropTypes.func.isRequired,
	doDeleteEnvironment: PropTypes.func.isRequired,
	doUpsertVariable: PropTypes.func.isRequired,
	doDeleteVariable: PropTypes.func.isRequired,
	fetchEnvironments: PropTypes.func.isRequired,
	update: PropTypes.number.isRequired,
	allowEdit: PropTypes.bool.isRequired
};
