import querySagas from "./query";
import upsertSagas from "./upsert";
import deleteSagas from "./delete";

export const variableSagas = [
	...querySagas,
	...upsertSagas,
	...deleteSagas
];

export default variableSagas;
