import { combineReducers } from "redux-immutable";

import { resourcesReducer } from "./resources";
import { loadingReducer } from "./loading";
import { errorReducer } from "./error";
import { infoReducer } from "./info";

import { loginReducer } from "./auth/login";
import { resetReducer } from "./auth/reset";
import { activateReducer } from "./auth/activate";
import { registerReducer } from "./auth/register";
import { privateReducer } from "./auth/private";
import { profileReducer } from "./auth/profile";

import logicReducer from "./logic";
import contextReducer from "./context";
import { confirmReducer } from "./info/confirm";

import dataReducer from "./data";
import { preferencesReducer } from "./preferences";

export default combineReducers({
	resources: resourcesReducer,
	loading: loadingReducer,
	error: errorReducer,
	info: infoReducer,
	confirm: confirmReducer,
	login: loginReducer,
	reset: resetReducer,
	activate: activateReducer,
	register: registerReducer,
	private: privateReducer,
	profile: profileReducer,
	logic: logicReducer,
	context: contextReducer,
	preferences: preferencesReducer,
	data: dataReducer
});
