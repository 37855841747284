import { apiFetch } from "../../base";
import { getNamespace } from "../../../lib/auth/token";
import { EnvironmentScopeNamespace } from "../../../lib/constants";

export function * postEnvironment (payload) {
	const { model } = payload;
	const { scope, ...rest } = model;

	const namespace = getNamespace();

	return yield apiFetch({
		endpoint: `/data/v1/environment/${namespace}`,
		method: "POST",
		authenticated: true,
		withoutAccount: (scope === EnvironmentScopeNamespace),
		body: JSON.stringify(rest)
	});
}
