import React from "react";
import PropTypes from "prop-types";

import { authRequestedSuperOrRoot } from "../../../lib/auth/token";

import "./ElevatedAuthRequired.scss";

export const ElevatedAuthRequired = ({ requestElevation, children }) => {
	if (!authRequestedSuperOrRoot()) {
		return (
			<div className="elevated-auth-required-container not-requested">
				<p>Elevated access is required to access this section.</p>

				<button className="button is-small is-primary" onClick={requestElevation}>
          <span className="icon is-small">
            <i className="fas fa-shield-check"/>
          </span>
					<span>Request</span>
				</button>
			</div>
		);
	}

	return children;
};

ElevatedAuthRequired.propTypes = {
	requestElevation: PropTypes.func.isRequired
};
