import React from "react";
import DropShadowSvgFilter from "../../common/svg/defs/DropShadowSvgFilter";
import ShapeButtonSvgElem from "../../common/svg/elem/ShapeButtonSvgElem";
import {initNodeProps} from "../../../lib/common/diagram/node";
import {initStyleProps} from "../../../lib/common/diagram/style";
import {extractNodeProps} from "../../../lib/playground/canvas/phase/node/props";
import {UpwireNamedColors} from "../../../../core/config";
import {Tooltip} from "@mui/material";
import {getNodeTypeOptions} from "../../../config/node";

export type NodeInfo = {
    id: string;
    name: string,
    component: React.ReactNode;
}

export interface PlaygroundInfo {
    getNodeInfo(nodeId: string): NodeInfo | null;
}

export function MissingNodeView() {
    return <Tooltip title={"This node is missing, it has probably been deleted."}>
        <div className="missing-node-view"></div>
    </Tooltip>;
}

function convertNamedColor(color: string): string {
    if (color in UpwireNamedColors)
        return (UpwireNamedColors as any)[color] as string;
    return color;
}

export function NodeView({
                             nodeId,
                             nodeData,
                             phaseData,
                             playgroundProps,
                         }: { nodeId: string, nodeData: any, phaseData: any, playgroundProps: any }) {

    const nodePropsF = getNodeTypeOptions(nodeData.type,
        extractNodeProps(nodeData, playgroundProps)
    );

    const nodeProps = initNodeProps(
        initStyleProps({
            id: nodeId,
            shapePad: 12,
            ...nodePropsF,
            size: 96,
            playgroundProps: playgroundProps,
        })
    );

    const colorProps = ["fill", "fillHover", "iconFill", "iconFillHover"];
    for (const colorProp of colorProps) {
        if (nodeProps[colorProp])
            nodeProps[colorProp] = convertNamedColor(nodeProps[colorProp]);
    }

    function openEditNodeDialog() {
        playgroundProps.openEditNode({
            "data": phaseData,
            "nodeProps": nodeProps,
            "nodeData": nodeData,
            "moduleData": {}
        });
    }

    return <svg width={nodeProps.size} height={nodeProps.size}>
        <defs>
            <DropShadowSvgFilter id="drop-shadow"/>
        </defs>
        <ShapeButtonSvgElem
            style={nodeProps.style}
            size={nodeProps.shapeSize}
            padding={nodeProps.shapePad}
            fill={nodeProps.fill}
            fillHover={nodeProps.fillHover}
            iconFill={nodeProps.iconFill}
            iconFillHover={nodeProps.iconFillHover}
            iconPad={nodeProps.iconPad}
            iconHoverPad={nodeProps.iconHoverPad}
            iconUrl={nodeProps.iconUrl}
            iconSvg={nodeProps.iconSvg}
            iconSvgSprite={nodeProps.iconSvgSprite}
            iconSvgHover={nodeProps.iconSvgHover}
            iconSvgSpriteHover={nodeProps.iconSvgSpriteHover}
            iconHoverUrl={undefined}
            disableHover={undefined}
            disableDropShadow={undefined}
            stroke={undefined}
            strokeWidth={undefined}
            onContextMenu={undefined}
            onMouseOver={undefined}
            onMouseLeave={undefined}
            // onClick={() => { openEditNodeDialog(); }}
        />
    </svg>;
}

export function createPlaygroundInfo(playgroundProps: any): PlaygroundInfo {

    const dashboard = playgroundProps.dashboard;
    return {
        getNodeInfo: (nodeId: string) => {

            for (const canvasId in dashboard.canvases) {

                const canvas = dashboard.canvases[canvasId];
                const phases = canvas.schema.phases;

                for (const phase of phases) {
                    const nodes = phase.nodes;
                    for (const phaseNodeId in nodes) {
                        if (phaseNodeId === nodeId) {

                            const nodeData = nodes[phaseNodeId];

                            const playgroundPropsExtended = {
                                ...playgroundProps,
                                canvas: canvas,
                            };

                            return {
                                id: nodeId,
                                "name": nodeData.name ?? "",
                                "component": <NodeView
                                    nodeId={nodeId}
                                    nodeData={nodeData}
                                    phaseData={phase}
                                    playgroundProps={playgroundPropsExtended}/>,
                            };
                        }
                    }
                }
            }

            return {
                id: nodeId,
                "name": "",
                "component": <MissingNodeView/>,
            };
        }
    };
}
