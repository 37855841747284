import {
	PlaygroundDashboardAction,
	PlaygroundDashboardFailedAction,
	PlaygroundDashboardSuccessAction
} from "../../actions/playground/dashboard";
import {
	PlaygroundUpdateAction,
	PlaygroundUpdateFailedAction,
	PlaygroundUpdateSuccessAction
} from "../../actions/playground/update";
import {
	CanvasNewAction,
	CanvasNewFailedAction,
	CanvasNewSuccessAction
} from "../../actions/playground/inlet/filter/canvas/new";
import { ensureCanvasInitialised } from "../../lib/playground/canvas/init";
import {
	CanvasUpdateAction,
	CanvasUpdateFailedAction,
	CanvasUpdateSuccessAction
} from "../../actions/playground/inlet/filter/canvas/update";
import { registerLoadingActions } from "core/reducers/loading";
import { registerErrorActions } from "core/reducers/error";

const remapDashboardPayload = (payload) => {
	const canvases = {};
	const modules = {};

	if (payload) {
		if (payload.canvases) {
			payload.canvases.forEach(c => {
				canvases[c.canvas_id] = ensureCanvasInitialised(c);
			});
		}
		if (payload.modules) {
			payload.modules.forEach(m => {
				if (!modules[m.proto]) {
					modules[m.proto] = {};
				}
				if (!modules[m.proto][m.namespace]) {
					modules[m.proto][m.namespace] = {};
				}

				modules[m.proto][m.namespace][m.module_id] = m;
			});
		}
	}

	return {
		...payload,
		canvases: canvases,
		modules: modules
	};
};

const applyPlaygroundCanvasPayload = (state, payload) => {
	const canvases = {
		...state.canvases || {}
	};

	if (payload.canvas) {
		canvases[payload.canvas.canvas_id] = ensureCanvasInitialised(payload.canvas);
	}

	return {
		...state,
		playground: payload.playground,
		canvases: canvases
	};
};

const applyPlaygroundPayload = (state, payload) => {
	return {
		...state,
		playground: payload
	};
};

registerLoadingActions(
	[
		PlaygroundDashboardAction.getType(),
		PlaygroundUpdateAction.getType(),
		CanvasNewAction.getType(),
		CanvasUpdateAction.getType()
	],
	[
		PlaygroundDashboardSuccessAction.getType(),
		PlaygroundDashboardFailedAction.getType(),
		PlaygroundUpdateSuccessAction.getType(),
		CanvasNewSuccessAction.getType(),
		CanvasNewFailedAction.getType(),
		CanvasUpdateSuccessAction.getType(),
		CanvasUpdateFailedAction.getType(),
		PlaygroundUpdateFailedAction.getType()
	]
);

registerErrorActions([
	PlaygroundUpdateFailedAction.getType(),
	CanvasNewFailedAction.getType(),
	CanvasUpdateFailedAction.getType()
]);

export const dashboardReducer = (state = {}, action) => {
	switch (action.type) {
		case PlaygroundDashboardAction.getType():
			return { loading: true, error: null, ...action.payload };
		case PlaygroundDashboardSuccessAction.getType():
			return { loading: false, ...remapDashboardPayload(action.payload) };
		case PlaygroundDashboardFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		case CanvasNewFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		case CanvasUpdateFailedAction.getType():
			return { ...state, loading: false, error: action.payload };

		// Side effect updates.
		case CanvasNewSuccessAction.getType():
			return applyPlaygroundCanvasPayload(state, action.payload);
		case CanvasUpdateSuccessAction.getType():
			return applyPlaygroundCanvasPayload(state, action.payload);
		case PlaygroundUpdateSuccessAction.getType():
			return applyPlaygroundPayload(state, action.payload);
		default:
			return state;
	}
};
