import {applyMiddleware, compose, createStore, Store} from "redux";
import {fromJS} from "immutable";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";
import rootSaga from "./sagas";
import tokenRefreshSaga from "core/sagas/token";
import {responsiveStoreEnhancer} from "redux-responsive";

function configureStore(initialState = fromJS({})) {
    const composer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    const composeEx = (composer || compose);

    const sagaMiddleware = createSagaMiddleware();
    const tokenRefreshMiddleware = createSagaMiddleware();

    const store: Store<StoreData, any> = createStore(
        rootReducer,
        initialState,
        composeEx(
            responsiveStoreEnhancer,
            applyMiddleware(sagaMiddleware, tokenRefreshMiddleware)
        )
    );

    sagaMiddleware.run(rootSaga);
    tokenRefreshMiddleware.run(tokenRefreshSaga);
    return store;
}


export type StoreData = {}

export type BrowserSize = {
    xsmall: boolean;
    small: boolean;
    medium: boolean;
    desktop: boolean;
    large: boolean;
    xlarge: boolean;
    infinity: boolean;
}

export type Browser = {
    is: BrowserSize
}

export type Profile = {
    loggedIn: boolean;
    loading: boolean;
    auth: { token: string; };
    claims: {
        username: string;
    };
    account: {
        account_key: string;
        namespace: string;
    };
}

export const store: Store<StoreData, any> = configureStore();
