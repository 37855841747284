import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import connect from "react-redux/es/connect/connect";

import LoginForm from "../../../components/auth/login/LoginForm";
import { ErrCodeUserEmailPending, ErrCodeUserNotActivated } from "../../../errs/index";
import Dialog from "../../../components/common/dialog/Dialog";
import ActivateResend from "../activate/ActivateResend.container";
import {
	ActivateModeVerification,
	LoginAction,
	PrivateRouteAction,
	PrivateRouteLoginAction,
	PrivateRouteModeLogin,
	PrivateRouteModeRegister,
	PrivateRouteRegisterAction,
	RegisterAction,
	ResetPasswordOpenModalAction
} from "../../../actions/auth";
import RegisterForm from "../../../components/auth/register/RegisterForm";
import Loading from "../../../../components/common/loading/Loading";
import SplashContainer from "../../../../components/layout/splash/SplashContainer";

class PrivateRouteAuth extends PureComponent {
	componentDidMount () {
		this.props.resetState();

		if (this.props.setFullscreen) {
			this.props.setFullscreen(true);
		}
	}

	render () {
		const {
			profile,
			login,
			register,
			setLogin,
			doLogin,
			setRegister,
			doRegister,
			setVerificationMode,
			openResetModal
		} = this.props;
		const loggedIn = (profile && profile.loggedIn);
		const pvt = this.props.private;

		if (profile && profile.loading) {
			return <Loading/>;
		}

		if (loggedIn) {
			return <Redirect to="/"/>;
		}

		let mode = PrivateRouteModeLogin;
		if (pvt && pvt.mode) {
			mode = pvt.mode;
		} else if (this.props.mode) {
			mode = this.props.mode;
		}

		if (login && login.error) {
			if (login.error.error_code === ErrCodeUserNotActivated) {
				return (
					<SplashContainer>
						<ActivateResend email={login.username}/>
					</SplashContainer>
				);
			} else if (login.error.error_code === ErrCodeUserEmailPending) {
				setVerificationMode();

				return (
					<SplashContainer>
						<ActivateResend email={login.username} forVerify/>
					</SplashContainer>
				);
			}
		}

		if (mode === PrivateRouteModeRegister) {
			return (
				<SplashContainer>
					<Dialog title="Register">
						<RegisterForm
							register={register}
							onSubmit={doRegister}
							openLogin={setLogin}
							isDialog
						/>
					</Dialog>
				</SplashContainer>
			);
		}

		return (
			<SplashContainer>
				<Dialog title="Login">
					<LoginForm
						login={login}
						doLogin={doLogin}
						openRegister={setRegister}
						openReset={openResetModal}
						requestElevation={profile.requestElevation}
						isDialog
					/>
				</Dialog>
			</SplashContainer>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		resetState: (payload) => dispatch(PrivateRouteAction()),
		setLogin: (payload) => dispatch(PrivateRouteLoginAction(payload)),
		doLogin: (payload) => dispatch(LoginAction(payload)),
		doRegister: (payload) => dispatch(RegisterAction(payload)),
		setRegister: (payload) => dispatch(PrivateRouteRegisterAction(payload)),
		openResetModal: (payload) => dispatch(ResetPasswordOpenModalAction(payload)),
		setVerificationMode: () => dispatch(ActivateModeVerification())
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	if (!core) {
		return {};
	}

	return {
		private: core.get("private"),
		login: core.get("login"),
		register: core.get("register")
	};
};

PrivateRouteAuth.propTypes = {
	setFullscreen: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRouteAuth);
