import React from "react";
import DraggableElement from "./DraggableElement";
import { FieldTypeSelect, FieldTypeText, ValidationFieldRequired } from "core";
import { commonStyleOptions, gatherElementStyles } from "../component-settings/common-settings";
import "./ImageElement.scss";

export const ElementTypeImage = "element-image";
export const ElementOptionsImage = [
	{
		label: "Basic",
		fields: [
			{
				name: "src",
				type: FieldTypeText,
				label: "Image URL",
				validations: [ValidationFieldRequired],
				default: ""
			},
			{
				name: "style",
				type: FieldTypeSelect,
				label: "Style",
				options: [
					{ label: "Default", value: "" },
					{ label: "Round", value: "round" }
				]
			}
		]
	},

	{
		label: "Appearance",
		fields: commonStyleOptions
	}
];

export default class ImageElement extends React.PureComponent {
	static defaultProps = {
		renderAs: "img",
		classes: []
	};

	render () {
		let { children, renderAs, style, classes, innerRef, ...props } = this.props;
		let imgClasses = [];

		if (style === "round") {
			classes = [...classes, "image", "is-square"];
			imgClasses = [...imgClasses, "is-rounded"];
		}

		if (!props.src) {
			classes.push("el-image-empty");
		}

		return (
			<DraggableElement
				classes={["image-element"]}
				innerRef={innerRef}
				{...props}
			>
				<div className={classes.join(" ")}>
					<img
						className={imgClasses.join(" ")}
						src={props.src || "/images/pages/el-photo.svg"}
						style={{ ...gatherElementStyles(props) }}
						alt=""
					/>
				</div>
			</DraggableElement>
		);
	}
}
