import { combineReducers } from "redux-immutable";

import { newReducer } from "./new";
import { updateReducer } from "./update";
import { deleteReducer } from "./delete";

import variableReducer from "./variable";
import pathReducer from "./path";

export default combineReducers({
	new: newReducer,
	update: updateReducer,
	delete: deleteReducer,
	variable: variableReducer,
	path: pathReducer
});
