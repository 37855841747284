import React, {useEffect} from "react";

import "./InletBar.scss";
import {Alert, IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import TestCaseWizard from "../TestCaseWizzard/TestCaseWizard";
import {UpwireModalError, UpwireModalLoading, useUpwireModal} from "../../common/modal/modal";
import {Playground} from "../types";
import {PlaygroundRun, Status, usePlaygroundDispatchApi} from "../../../api/playground/dispatch";
import {useMutation, useQuery} from "react-query";
import classNames from "classnames";
import {fromNow} from "../../../../util/datetime";
import {createPlaygroundInfo} from "../kibitzer/playground";
import {PlaygroundRunKibitzer} from "../kibitzer/kibitzer";
import {RefreshRounded} from "@mui/icons-material";
import {raiseGlobalError} from "../../../../core/dispatch/errors";
import {useAccountKey} from "../../../../state/UserContextProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useHashDisplay} from "../../../../util/strings";
import {ApiWizard} from "../TestCaseWizzard/ApiWizard";


import {DropOptions, DropOptionsProps} from "../../common/Droptions/droptions";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import DataObjectIcon from "@mui/icons-material/DataObject";
import {TestCase, VariableValues} from "../../common/testCase";


function PlaygroundRunKibitzerView({playgroundProps, runId}: { playgroundProps: any, runId: string }) {
    const playgroundApi = usePlaygroundDispatchApi();

    const {
        data: runtimeSeries,
        isLoading: runLoading,
        isFetching: runFetching,
        error: runError,
        refetch: refreshRun,
    } = useQuery(["playground", "run", runId], async () => {
        try {
            return await playgroundApi.getRuntimeSeries(runId);
        } catch (e) {
            console.log("This is the error", e);
            raiseGlobalError("Failed to fetch run info");
            throw e;
        }
    });

    useEffect(() => {
        if (runtimeSeries) {
            if (runtimeSeries.run.status === "RUNNING" || runtimeSeries.run.status === "PENDING") {
                const handler = setInterval(async () => {
                    if (runFetching || runLoading)
                        return;
                    await refreshRun();
                }, 2000);
                return () => clearInterval(handler);
            }
        }
    }, [runtimeSeries]);

    if (runError) {
        const error = runError as Error;
        return <Alert severity="error">{error.toString()}</Alert>;
    }

    if (runLoading) {
        return <UpwireModalLoading message="Loading Test Run Details..."/>;
    }

    if (!runtimeSeries) {
        return <UpwireModalError message="Error getting run details data"/>;
    }

    const playground = createPlaygroundInfo(playgroundProps);
    return <PlaygroundRunKibitzer playground={playground} runtime={runtimeSeries}/>;
}

function PlaygroundRunKibitzerModal({
                                        runId,
                                        playgroundProps,
                                        onClose
                                    }: { runId: string, playgroundProps: any, onClose: () => void }) {

    const runIdDisplayed = useHashDisplay(runId, 8);

    const {modal, show} = useUpwireModal(<PlaygroundRunKibitzerView playgroundProps={playgroundProps} runId={runId}/>, {
        title: <><span>Test Run {runIdDisplayed}</span><i>{runId}</i></>,
        wide: true,
        onClose: onClose
    });

    useEffect(() => {
        show();
    });

    return modal;
}

function getRunDetails(run: PlaygroundRun) {

    function shortStatus(status: Status) {
        switch (status) {
            case "PENDING":
                return "...";
            case "RUNNING":
                return "RUN";
            case "COMPLETED":
                return "OK";
            case "FAILED":
                return "FAIL";
            case "ENDED":
                return "STOP";
            case "NOOP":
                return "NOOP";
            case "QUEUED":
                return "QUEUE";
            case "DEFERRED":
                return "DEFER";
            case "RETRY":
                return "RETRY";
            case "TIMED_OUT":
                return "TIMED-OUT";
            case "CANCELLED":
                return "CANCEL";
        }
    }

    const status = run.status.toLowerCase();
    const timeAgo = fromNow(run.created_on);
    const shortStatusText = shortStatus(run.status);

    return {
        status, timeAgo, shortStatusText
    };
}

type TestCaseTooltipProps = {
    run: PlaygroundRun
    onClick: () => void
}

function TestCaseTooltip(props: TestCaseTooltipProps) {

    const {run, onClick} = props;
    const {shortStatusText, timeAgo, status} = getRunDetails(run);

    const runDisplay = useHashDisplay(run.id);

    return <Tooltip
        arrow
        title={
            <>
                <div className="tooltip-row">{`RUN ${runDisplay}`}</div>
                <div className="tooltip-row">{`ENVIRONMENT ${run.init_env.name}`}</div>
            </>
        } key={run.id}>

        <div onClick={onClick}
             className={classNames("latest-run-item", "status-name", status)}>
            <strong>{shortStatusText}</strong> {timeAgo}
        </div>
    </Tooltip>;
}

function LatestRunsView({playgroundId, playgroundProps}: { playgroundId: string, playgroundProps: any }) {
    const playgroundApi = usePlaygroundDispatchApi();

    const {
        data: runs,
        isLoading: runsLoading,
        isFetching: runsFetching,
        refetch: refreshRuns
    } = useQuery(["playground", playgroundId, "runs"], async () => {
        return await playgroundApi.getRuns(playgroundId, 10);
    });

    const [selectedRun, setSelectedRun] = React.useState<string | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const additionalMenuIsOpen = !!(anchorEl);

    useEffect(() => {
        setAnchorEl(null);
    }, [runsLoading, runsFetching]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function canRefresh() {
        if (runsLoading || runsFetching)
            return false;

        return !selectedRun;
    }

    useEffect(() => {
        const handler = setInterval(() => {

            if (!canRefresh())
                return;

            if (runs)
                for (let run of runs)
                    if (run.status === "RUNNING")
                        return refreshRuns();
        }, 5000);

        return () => clearInterval(handler);
    }, []);

    useEffect(() => {
        const handler = setInterval(() => {
            if (canRefresh())
                return refreshRuns();
        }, 20_000);

        return () => clearInterval(handler);
    }, []);


    if (runsLoading || runsFetching) {
        return <div className="latest-runs-list">
            <div className="latest-run-status">Updating...</div>
        </div>;
    }

    function selectRun(runId: string) {
        setSelectedRun(runId);
    }

    function closeRun() {
        setSelectedRun(null);
    }

    const refresh = <IconButton onClick={async () => {
        await refreshRuns();
    }}>
        <RefreshRounded/>
    </IconButton>;


    if (runs?.length) {

        const fastPreviewRuns = runs?.slice(0, 3);
        const moreRuns = runs?.slice(3);

        return <div className="latest-runs-list">
            {selectedRun &&
				<PlaygroundRunKibitzerModal runId={selectedRun} playgroundProps={playgroundProps} onClose={closeRun}/>}
            {fastPreviewRuns.map((run) => {
                return <TestCaseTooltip key={run.id} run={run}
                                        onClick={() => selectRun(run.id)}/>;
            })}
            {moreRuns.length > 0 &&
				<div>
					<IconButton
						aria-controls={additionalMenuIsOpen ? "basic-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={additionalMenuIsOpen ? "true" : undefined}
						onClick={handleClick}
						color="success"
					>
						<ExpandMoreIcon/>
					</IconButton>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={additionalMenuIsOpen}
						onClose={handleClose}
						MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}

					>
                        {moreRuns?.map((run) => {
                            const {shortStatusText, timeAgo, status} = getRunDetails(run);
                            return <MenuItem
                                key={run.id}
                                sx={{
                                    margin: "5px", borderRadius: 1,
                                }}
                                dense={true}

                                onClick={() => {
                                    selectRun(run.id);
                                    handleClose();
                                }}
                                className={classNames("latest-run-item", "status-name", status)}
                            >
                                <strong>{shortStatusText}</strong>{timeAgo} ({"RUN " + run.id})
                            </MenuItem>;

                        })}

					</Menu>
				</div>
            }
            {refresh}
        </div>;
    }

    return <>
        <div className="latest-runs-list">
            <div className="latest-run-status">No recent tests...</div>
            {refresh}
        </div>
    </>;
}

function fillTestCaseWithDefaults(testCase: TestCase, playground: Playground): VariableValues {
    const result = {...testCase.values};

    for (let variable of playground.settings.variables) {
        if (variable.default && variable.required && !result[variable.name]) {
            result[variable.name] = variable.default;
        }
    }

    return result;
}

export function InletBar(props: {
    playgroundProps: any,
    inlet: any
}) {

    const accountKey = useAccountKey();
    const playgroundApi = usePlaygroundDispatchApi();

    const playground = props.playgroundProps.dashboard.playground as Playground;
    const [launchedRunId, setLaunchedRunId] = React.useState<string | null>(null);

    const {
        mutate: launch,
        isLoading: launching,
    } = useMutation(async (params: { values: VariableValues, environmentName: string }) => {
        const {values, environmentName} = params;
        const data = await playgroundApi.run(playground.namespace, playground.playground_id, environmentName, values, accountKey);
        setLaunchedRunId(data.run_id);
    });

    const {
        modal: testCaseWizardModal,
        show: showTestCaseWizardModal,
        hide: hideTestCaseWizardModal
    } = useUpwireModal(<TestCaseWizard
        playground={playground}
        environments={props.playgroundProps.environments}
        variables={playground.settings.variables}
        onRun={(testCase, environmentName) => {
            hideTestCaseWizardModal();
            launch({
                values: fillTestCaseWithDefaults(testCase, playground),
                environmentName: environmentName
            });
        }}
    />, {
        title: "Run a Test Case",
        wide: true,
    });

    const {
        modal: apiExampleModal,
        show: showApiExampleModal,
    } = useUpwireModal(
        <ApiWizard
            playground={playground}
            spec={props.playgroundProps.environments}/>, {
            title: "Run a Test Case Using API Call",
            wide: true
        }
    );

    const runModal = launchedRunId ?
        <PlaygroundRunKibitzerModal
            runId={launchedRunId}
            playgroundProps={props.playgroundProps}
            onClose={() => setLaunchedRunId(null)}/> : null;

    const dropOptions: DropOptionsProps = {
        text: "Run Playground",
        options: [
            {
                icon: <PlayCircleOutlineIcon/>,
                text: "Run a test case",
                onSelect: () => showTestCaseWizardModal()
            }, {
                icon: <DataObjectIcon/>,
                text: "Run with API call",
                onSelect: () => showApiExampleModal()
            }
        ]
    };

    return <>
        {runModal}
        {testCaseWizardModal}
        {apiExampleModal}
        <div className="pg-inlet-bar">
            <div className="start">
                <DropOptions {...dropOptions}/>
            </div>
            <div className="end">
                <LatestRunsView playgroundId={playground.playground_id} playgroundProps={props.playgroundProps}/>
            </div>
        </div>
    </>;
}
