import { registerLoadingActions } from "../../../loading";
import { registerErrorActions } from "../../../error";
import {
	EnvironmentVariableDeleteAction,
	EnvironmentVariableDeleteFailedAction,
	EnvironmentVariableDeleteSuccessAction
} from "../../../../actions/data/environment/variable/delete";

registerLoadingActions(
	[EnvironmentVariableDeleteAction.getType()],
	[EnvironmentVariableDeleteSuccessAction.getType(), EnvironmentVariableDeleteFailedAction.getType()]
);

registerErrorActions([
	EnvironmentVariableDeleteFailedAction.getType()
]);

export const deleteReducer = (state = {}, action) => {
	switch (action.type) {
		case EnvironmentVariableDeleteAction.getType():
			return { ...state, loading: true, error: null, model: action.payload };
		case EnvironmentVariableDeleteSuccessAction.getType():
			return { ...state, loading: false, ...action.payload };
		case EnvironmentVariableDeleteFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};
