import {DispatchApi} from "../../../core/dispatch/dispatch";
import {useToken} from "../../../state/UserContextProvider";
import {VariableValues} from "../../components/common/testCase";
import {raiseGlobalError} from "../../../core/dispatch/errors";

export type InitEnv = {
    id: string;
    env: string;
    name: string;
    path: string;
}

export type PlaygroundRunStatus =
    "RUNNING" | "PENDING" | "COMPLETED" | "FAILED" | "ENDED" | "NOOP"
    | "QUEUED" | "DEFERRED" | "RETRY" | "TIMED_OUT" | "CANCELLED";

export type Data = { [key: string]: any };
export type Status = PlaygroundRunStatus
export type Outcome = "success" | "failure"

export enum LogLevel {
    INFO = 4,
    Warning = 3,
    Error = 2,
    Fatal = 1
}

export function getLogLevelName(level: LogLevel) {
    switch (level) {
        case LogLevel.INFO:
            return "info";
        case LogLevel.Warning:
            return "warning";
        case LogLevel.Error:
            return "error";
        case LogLevel.Fatal:
            return "fatal";
    }
}

export type PlaygroundRun = {
    "id": string,
    "namespace": string,
    "playground_id": string,
    "version_id": string,
    "correlation_id": string,
    "status": Status,
    "data_source_type": "DATA",
    "init_env": {
        "env": string,
        "name": string,
    },
    "init_params": Data,
    "active_params": Data,
    "session": Data,
    "debug": {
        "@playground_name": string,
        "@taken_ms": number,
        "error"?: {
            "message": string,
        }
    }

    "created_on": string,
    "created_by": string,
    "canvas_runs": RunCanvas[],
}

export type RunCanvas = {
    "id": string,
    "canvas_id": string,
    "version_id": string,
    "status": Status,
    "debug": {
        "@canvas_name": string,
        "@taken_ms": number,
    }
    "phase_runs": PhaseRun[]
}

export type PhaseRun = {
    "id": string,
    "phase_id": string,
    "status": Status,
    "init_env": Data,
    "init_params": Data,
    "active_params": Data,
    "session": Data,
    "debug": {
        "@phase_name": string,
        "@taken_ms": number,
    }
    "nodes": RunNode[]
}

export type RunNode = {
    "id": string,
    "node_id": string,
    "state": {
        "@node_execution_count": number,
    }
    "debug": {
        "@node_name": string,
        "@node_type": string,
    },
    "steps": RunStep[]
}

export type RunStep = {
    "id": string,
    "from_step_id": string,
    "status": Status,
    "state": {
        "@node_execution_seq": number,
    }
    "outcome": Outcome,
    "init_env": Data,
    "in_params": Data,
    "out_params": Data,
    "session": Data,
    "created_on": string,
    "debug": {
        "@node_module": string,
        "@taken_ms": number,
        "attempts": RunAttempt[]
    }
}

export type RunAttempt = {
    "debug": {
        "@attempted_on": string,
        "@module_run_id": string,
        "@module_taken_ms": number,
        "@taken_ms": number,
        "logs": RunLog[]
        "error"?: {
            "message": string,
        }
    }
    "env": Data,
    "in_params": Data,
    "in_status": Status,
    "out_params": Data,
    "session": Data,
    "settings": {
        "@node_name": string,
    }
    "state": {
        "@node_execution_seq": number,
        "@node_execution_count": number,
        "@step_attempt_count": number,
    }
}

export type RunLog = {
    "created_on": string,
    "id": number, // BROKEN!!!
    "level": LogLevel,
    "log": string,
}


export type PlaygroundRunLogLine = {
    id: number;
    level: number;
    log: string;
    created_on: Date;
}


export type RuntimeEvent = {
    attempt: RunAttempt
    step: RunStep
    node: RunNode
    phase: PhaseRun
    canvas: RunCanvas
    status: Status

    causedBy: RuntimeEvent | "start"
    caused: RuntimeEvent[]
}

export type RunCondition = {
    canvas: RunCanvas,
    events: RuntimeEvent[]
    overallStatus: Status
}

export type RuntimeSeries = {
    run: PlaygroundRun,
    conditions: RunCondition[]
    overallStatus: Status
}

function getRuntimeSeriesFromPlaygroundRun2(run: PlaygroundRun): RuntimeSeries {
    run = JSON.parse(JSON.stringify(run));

    const runSteps = new Map<string, RunStep>();
    const stepReferencesFromTo = new Map<string, string[]>();

    const conditions: RunCondition[] = [];

    function registerStep(step: RunStep) {
        const stepId = step.id;
        const fromStepId = step.from_step_id;

        if (!stepReferencesFromTo.has(fromStepId))
            stepReferencesFromTo.set(fromStepId, []);
        stepReferencesFromTo.get(fromStepId)?.push(stepId);
        runSteps.set(stepId, step);
    }

    for (const canvas of run.canvas_runs || []) {

        const condition: RunCondition = {
            canvas: canvas,
            events: [],
            overallStatus: canvas.status,
        };

        const events = condition.events;
        conditions.push(condition);

        const phaseRuns = canvas.phase_runs || [];
        for (const phase of phaseRuns) {
            const nodes = phase.nodes || [];
            for (const node of nodes) {
                const steps = node.steps || [];
                for (const step of steps) {

                    if (!(step?.debug?.attempts?.length))
                        continue;

                    registerStep(step);

                    for (let i = 0; i < step.debug.attempts.length; i++) {
                        const attempt = step.debug.attempts[i];
                        const isLast = i === step.debug.attempts.length - 1;
                        if (!attempt.debug.logs)
                            attempt.debug.logs = [];
                        attempt.debug.logs.reverse();
                        events.push({
                            attempt,
                            step,
                            node,
                            phase,
                            canvas,
                            status: isLast ? step.status : "FAILED",
                            caused: [],
                            causedBy: "start",
                        });
                    }
                }
            }
        }
    }

    for (const condition of conditions) {
        const events = condition.events;

        for (const event of events) {
            const stepStart = new Date(event.step.created_on).getTime();
            event.attempt.debug.logs = event.attempt.debug.logs.filter(log => new Date(log.created_on).getTime() >= stepStart);
        }

        for (const event of events) {
            const stepReferences = stepReferencesFromTo.get(event.step.id) || [];
            for (const stepReference of stepReferences) {

                const step = runSteps.get(stepReference);
                if (!step)
                    continue;

                for (const causedEvent of events.filter(e => e.step.id === step.id)) {
                    event.caused.push(causedEvent);
                    causedEvent.causedBy = event;
                }
            }
        }

        condition.events = events.sort((a, b) => {
            return new Date(a.attempt.debug["@attempted_on"]).getTime() - new Date(b.attempt.debug["@attempted_on"]).getTime();
        });
    }


    return {
        run,
        conditions: conditions,
        overallStatus: run.status
    };
}


export class PlaygroundDispatchApi extends DispatchApi {

    constructor(token: string | undefined | null) {
        super(token, "playground", false);
    }

    async getRuns(playgroundId: string, limit: number = 10) {
        return await this.dispatch<PlaygroundRun[]>("listRuns", {
            "playgroundId": playgroundId,
            "limit": limit
        });
    }

    async getRuntimeSeries(runId: string): Promise<RuntimeSeries> {
        const run = await this.dispatch<PlaygroundRun>("getRunInfo", {"runId": runId});
        return getRuntimeSeriesFromPlaygroundRun2(run);
    }

    async getMultipleRuntimeSeries(playgroundId: string, limit: number = 10): Promise<RuntimeSeries[]> {
        const runs = await this.getRuns(playgroundId, limit);
        return runs.map(run => getRuntimeSeriesFromPlaygroundRun2(run));
    }

    async getRunLogs(runId: string) {
        return await this.dispatch<PlaygroundRunLogLine[]>("getRunLogs", {"runId": runId});
    }

    async promote(playgroundId: number, target: string) {
        return await this.dispatch<void>("promotePlayground", {"playgroundId": playgroundId, "target": target});
    }

    async run(namespace: string,
              playgroundId: string,
              env: string,
              values: VariableValues,
              accountKey: string): Promise<any> {

        const url = `/api/v1/playground/${namespace}/${playgroundId}/exec/${env}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.token}`,
                "Content-Type": "application/json",
                "UP-Account-Key": accountKey
            },
            body: JSON.stringify({
                "params": values,
                "version": "head"
            })
        });

        if (!response.ok) {
            raiseGlobalError("Unable to launch test case");
        }

        return await response.json();
    }
}


export function usePlaygroundDispatchApi() {
    return new PlaygroundDispatchApi(useToken());
}
