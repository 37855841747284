import { apiFetch } from "../../base";
import { getNamespace } from "../../../lib/auth/token";
import { EnvironmentScopeNamespace } from "../../../lib/constants";

export function * updateEnvironment (payload) {
	const { model } = payload;
	const { scope, name, newName, ...rest } = model;

	const namespace = getNamespace();

	if (newName) {
		rest.name = newName;
	} else {
		rest.name = name;
	}

	if (!name) {
		throw new Error("name missing in request");
	}

	return yield apiFetch({
		endpoint: `/data/v1/environment/${namespace}/${name}`,
		method: "PUT",
		authenticated: true,
		withoutAccount: (scope === EnvironmentScopeNamespace),
		body: JSON.stringify(rest)
	});
}
