import React, { Component } from "react";
import { connect } from "react-redux";

import config, { CfgColors } from "../../../config";
import Modal from "../../../components/common/modal/Modal";

import { EnvironmentUpdateAction } from "../../../actions/data/environment/update";
import { EnvironmentDeleteAction } from "../../../actions/data/environment/delete";
import { EnvironmentVariableUpsertAction } from "../../../actions/data/environment/variable/upsert";
import { EnvironmentVariableDeleteAction } from "../../../actions/data/environment/variable/delete";
import { SetPreferenceAction } from "../../../actions/preferences";
import { EnvironmentVariableQueryAction } from "../../../actions/data/environment/variable/query";
import { EnvironmentScopeAccount, EnvironmentScopeNamespace } from "../../../lib/constants";
import { CloseEditEnvironmentPathModalAction } from "../../../actions/data/environment/path/edit";
import { Environments } from "../../../components/settings/environments/Environments";

class EnvironmentPathEditModal extends Component {
	render () {
		const {
			edit, environments, doUpdateEnvironment,
			doDeleteEnvironment, doUpsertVariable, doDeleteVariable,
			preferences, setPreference, fetchEnvironments, closeModal
		} = this.props;

		const open = !!(edit && edit.open);
		if (!open) {
			return null;
		}

		const title = edit.title || "Edit Environment Path";
		const scope = edit.scope || EnvironmentScopeAccount;
		const path = edit.path || EnvironmentScopeAccount;
		const inherits = edit.inherits || [EnvironmentScopeNamespace];
		const update = environments.update || 0;

		const colourCfg = config.get(CfgColors);

		return (
			<>
				<Modal
					title={title}
					closeModal={closeModal}
					icon="fas fa-layer-group"
					modalState={open}
					headBgColor={colourCfg.blue}
					fontColor={colourCfg.white}
					width="900px"
					maxWidth="100vw"
				>
					<div className="up-form">
						<section className="modal-card-body scroll-overflow">
							<Environments
								model={environments}
								scope={scope}
								path={path}
								inherits={inherits}
								doUpdateEnvironment={doUpdateEnvironment}
								doDeleteEnvironment={doDeleteEnvironment}
								doUpsertVariable={doUpsertVariable}
								doDeleteVariable={doDeleteVariable}
								preferences={preferences}
								setPreference={setPreference}
								fetchEnvironments={() => {
									fetchEnvironments(path);
								}}
								update={update}
								allowNew={false}
								allowEdit={false}
							/>
						</section>
						<footer className="modal-card-foot">
							<button type="submit" className="button" onClick={closeModal}>Close</button>
						</footer>
					</div>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseEditEnvironmentPathModalAction()),
		doUpdateEnvironment: (payload) => dispatch(EnvironmentUpdateAction(payload)),
		doDeleteEnvironment: (payload) => dispatch(EnvironmentDeleteAction(payload)),
		doUpsertVariable: (payload) => dispatch(EnvironmentVariableUpsertAction(payload)),
		doDeleteVariable: (payload) => dispatch(EnvironmentVariableDeleteAction(payload)),
		setPreference: (payload) => dispatch(SetPreferenceAction(payload)),
		fetchEnvironments: (path) => dispatch(EnvironmentVariableQueryAction({
			model: {
				scope: EnvironmentScopeAccount,
				path: path
			}
		}))
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	const data = core.get("data");
	const environment = data.get("environment");
	const path = environment.get("path");
	const variable = environment.get("variable");

	return {
		core: core,
		preferences: core.get("preferences"),
		environments: variable.get("query"),
		edit: path.get("edit")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentPathEditModal);
