import React from "react";

import ErrorModal from "./containers/error/ErrorModal.container";
import ConfirmModal from "./containers/info/ConfirmModal.container";
import InfoModal from "./containers/info/InfoModal.container";
import ActivateModal from "./containers/auth/activate/ActivateModal.container";
import ResetPasswordModal from "./containers/auth/reset/ResetPasswordModal.container";
import LoadingModal from "./containers/loading/LoadingModal.container";
import ConditionBuilderModal from "./containers/logic/condition/builder/ConditionBuilderModal.container";
import ContextMenu from "./containers/context/ContextMenu.container";
import EnvironmentPathEditModal from "./containers/settings/data/EnvironmentPathEditModal.container";
import EnvironmentNewModal from "./containers/settings/data/EnvironmentNewModal.container";

export const CoreModals = () => {
	return (
		<>
			<ActivateModal/>
			<ResetPasswordModal/>
			<ConditionBuilderModal/>
			<EnvironmentPathEditModal/>
			<EnvironmentNewModal/>
			{/* MUST BE LAST */}
			<LoadingModal/>
			<ConfirmModal/>
			<InfoModal/>
			<ErrorModal/>
			<ContextMenu/>
		</>
	);
};

export default CoreModals;
