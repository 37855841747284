import { call, put, takeLatest } from "redux-saga/effects";
import {
	EnvironmentUpdateAction,
	EnvironmentUpdateFailedAction,
	EnvironmentUpdateRequestAction,
	EnvironmentUpdateSuccessAction
} from "../../../actions/data/environment/update";
import { updateEnvironment } from "../../../api/data/environment/update";

/// Environment
// Worker
function * updateEnvironmentWorker (action) {
	try {
		yield put(EnvironmentUpdateRequestAction());

		const { onSuccess, ...payload } = action.payload;

		const r = yield call(updateEnvironment, payload);
		if (r.json) {
			if (onSuccess) {
				yield onSuccess(r.json);
			}

			yield put(EnvironmentUpdateSuccessAction(r.json));
		} else {
			yield put(EnvironmentUpdateFailedAction("Failed to update environment"));
		}
	} catch (e) {
		yield put(EnvironmentUpdateFailedAction(e));
	}
}

// Saga
export function * updateEnvironmentSaga () {
	yield takeLatest(EnvironmentUpdateAction.getType(), updateEnvironmentWorker);
}

// Export

export const updateSagas = [
	updateEnvironmentSaga()
];

export default updateSagas;
