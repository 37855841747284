import React from "react";
import Element from "./Element";
import { gatherElementStyles } from "../component-settings/common-settings";
import { FieldTypeSelect, FieldTypeTextArea } from "core";

export const ElementTypeRow = "row";
export const ElementTypeColumn = "column";

export const columnSchema = [
	{
		label: "Appearance",
		fields: [
			{
				name: "layout",
				label: "Layout",
				type: FieldTypeSelect,
				options: [
					{ label: "Default", value: null },
					{ label: "Center Horizontally", value: "ch" },
					{ label: "Center Vertically", value: "cv" },
					{ label: "Center Both", value: "c" }
				]
			}
		]
	},
	{
		label: "Advanced",
		fields: [
			{
				name: "customStyles",
				type: FieldTypeTextArea,
				label: "Custom Styles",
				placeholder: "color: hotpink;\nbox-shadow: -1 0 0 hotpink;\n...",
				validations: [],
				default: ""
			}
		]
	}
];

export class RowElement extends React.PureComponent {
	static defaultProps = {
		renderAs: "section",
		classes: []
	};

	render () {
		const { children, classes, ...props } = this.props;
		return (
			<Element
				classes={[...classes, "columns"]}
				style={gatherElementStyles(props)}
				{...props}
			>
				{children}
			</Element>
		);
	}
}

export class ColumnElement extends React.PureComponent {
	static defaultProps = {
		renderAs: "div",
		classes: []
	};

	render () {
		const { children, classes, innerRef, draggingOver, ...props } = this.props;

		return (
			<Element
				classes={[...classes, "column"]}
				{...props}
				style={gatherElementStyles(props)}
				innerRef={innerRef}
			>
				{children}
			</Element>
		);
	}
}
