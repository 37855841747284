export type FormatData = {
    token: string;
    payload: any;
    endpoint: string;
    accountKey: string;
}

export type FormatterOutput = {
    code: string;
    language: string;
}

// export const API_BASE_URL = "https://dev.test.play.upwire.com";
export const API_BASE_URL = "http://localhost:9011";
