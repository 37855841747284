import React from "react";
import { Draggable } from "react-beautiful-dnd";
import Element from "./Element";

import "./DraggableElement.scss";

class DraggableElement extends React.PureComponent {
	static defaultProps = {
		renderAs: "div",
		classes: []
	};

	render () {
		const {
			index,
			id,
			parentId,
			parentZone,
			propertiesActive,
			setActiveElement,
			classes,
			draggableType,
			children,
			disableInteractiveElementBlocking,
			...props
		} = this.props;

		return (
			<Draggable
				draggableId={id}
				index={index}
				type={draggableType || "ELEMENT"}
				disableInteractiveElementBlocking={!!disableInteractiveElementBlocking}
			>
				{(provided, snapshot) => (
					<div
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
						className={(["draggable-element", snapshot.isDragging ? "is-dragging" : "is-in-place", propertiesActive ? "is-active" : "", ...classes]).join(" ")}
					>
						<div className="controls">
							<button
								className="button is-small" onClick={() => setActiveElement({
								layout: parentId,
								dropzone: parentZone,
								component: id
							})}
							>
								<i className="fas fa-trash"/>
							</button>
							<button
								className="button is-small" onClick={() => setActiveElement({
								layout: parentId,
								dropzone: parentZone,
								component: id
							})}
							>
								<i className="fas fa-cogs"/>
							</button>
							<span className="button is-small">
                <i className="fas fa-expand-arrows-alt"/>
              </span>
						</div>
						{children || <Element {...props} />}
					</div>
				)}
			</Draggable>
		);
	}
}

export default DraggableElement;
