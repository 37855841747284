import {Alert, Button, Chip, CircularProgress, Dialog, Tab, Tabs} from "@mui/material";
import {NumberConfig, NumberDescriptor, useNumberDispatchApi} from "./api";
import {useQuery, useQueryClient} from "react-query";
import BigSelector, {BigSelectorItem} from "../../components/common/BigSelector/BigSelector";

import parsePhoneNumber, {CountryCode} from "libphonenumber-js";
import Flags from "country-flag-icons/react/3x2";
import "./NumbersSection.scss";
import React, {useEffect, useReducer, useState} from "react";
import {TemplateKind} from "../editors/types";
import {capitalizeFirstLetter} from "../../util/strings";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {InboundTemplatePicker} from "../../core/components/common/form/TemplatePickerField";
import {ITemplateDescriptor} from "../editors/api";

const numbersQueryKey = "numbers-list";

type NumberTagProps = {
    kind: TemplateKind;
    templateId: string | null;
}

type NumberConfigAction = {
    type: "set-name" | "set-voice-template" | "set-sms-template" | "set-callback-url" | "set-callback-method" | "set-callback-format" | "set-callback-username" | "set-callback-password";
    value: string | null;
} | { type: "init", from: NumberDescriptor };

type NumberConfigProps = NumberConfig & {
    number: NumberDescriptor;
    dispatchConfig: React.Dispatch<NumberConfigAction>
}

type NumberSettingsDialogProps = {
    number: NumberDescriptor;
    open: boolean;
    close: () => void;
}

function formatPhoneNumber(number: NumberDescriptor): string {

    try {
        const fmt = parsePhoneNumber(number.number, number.country as CountryCode);
        if (fmt) {
            return fmt.formatInternational();
        }
    } catch (e) {
        console.error(e);
    }
    return number.number;
}

function configFromNumber(number: NumberDescriptor): NumberConfig {
    return {
        name: number.name,
        voiceTemplateId: number.voiceTemplateId,
        smsTemplateId: number.smsTemplateId,
        $modified: false,
        callbackUsername: number.callbackUsername,
        callbackPassword: number.callbackPassword,
        callbackUrl: number.callbackUrl,
        callbackMethod: number.callbackMethod,
        callbackFormat: number.callbackFormat,

    };
}

function configReducer(state: NumberConfig, action: NumberConfigAction): NumberConfig {

    switch (action.type) {
        case "init":
            return configFromNumber(action.from);

        case "set-name":
            return {...state, name: action.value ?? "", $modified: true};
        case "set-voice-template":
            return {...state, voiceTemplateId: action.value, $modified: true};
        case "set-sms-template":
            return {...state, smsTemplateId: action.value, $modified: true};

        case "set-callback-url":
            return {...state, callbackUrl: action.value, $modified: true};

        case "set-callback-method":
            return {...state, callbackMethod: action.value, $modified: true};

        case "set-callback-format":
            return {...state, callbackFormat: action.value, $modified: true};

        case "set-callback-username":
            return {...state, callbackUsername: action.value, $modified: true};

        case "set-callback-password":
            return {...state, callbackPassword: action.value, $modified: true};

    }
}

function NumberTag(props: NumberTagProps): JSX.Element {
    const {templateId, kind} = props;

    let icon = null;
    const assigned = !!templateId;

    if (assigned) {
        icon = <span className="tag-value">
            <i className={"fa fa-check-circle"}></i>
        </span>;
    }

    const inners = <>
        <div className="tag-content">
            <span className="tag-name">{capitalizeFirstLetter(kind)}</span>
            {icon}
        </div>
    </>;

    return <Chip className="number-tag" label={inners}/>;
}

function NumberGeneralSettings(props: NumberConfigProps) {
    const {name, dispatchConfig} = props;

    const nameField = <TextField
        autoComplete={undefined}
        autoFocus
        margin="dense"
        label="Name"
        type="text"
        fullWidth
        value={name ?? ""}
        onChange={(e) => dispatchConfig({type: "set-name", value: e.target.value})}
        variant="standard"/>;

    return <>
        {nameField}
    </>;
}

function NumberTemplateSettings(props: NumberConfigProps) {
    const {number, dispatchConfig} = props;
    const {voiceTemplateId, smsTemplateId} = props;
    const [showSmsWarning, setShowSmsWarning] = useState(false);
    const [showVoiceWarning, setShowVoiceWarning] = useState(false);

    const onAssignSMS = (template: ITemplateDescriptor | null) => {
        dispatchConfig({
            type: "set-sms-template",
            value: template?.templateId ?? ""
        });

        if (template && template.publishedSettings.variables.length > 0) {
            setShowSmsWarning(true);
        } else {
            setShowSmsWarning(false);
        }
    };
    const onAssignVoice = (template: ITemplateDescriptor | null) => {
        dispatchConfig({
            type: "set-voice-template",
            value: template?.templateId ?? ""
        });

        if (template && template.publishedSettings.variables.length > 0) {
            setShowVoiceWarning(true);
        } else {
            setShowVoiceWarning(false);
        }
    };

    return <>
        {number.isSMS &&
			<div>
				<InboundTemplatePicker kind="sms" onAssign={onAssignSMS} templateId={smsTemplateId}/>
                {showSmsWarning && <Alert severity="warning">
					This SMS template requires input variables.
                    Make sure that the template can work as an inbound template without them.
				</Alert>}
			</div>}
        {number.isVoice &&
			<div>
                <InboundTemplatePicker kind="voice" onAssign={onAssignVoice} templateId={voiceTemplateId}/>
                {showVoiceWarning && <Alert severity="warning">
					This voice template requires input variables.
					Make sure that the template can work as an inbound template without them.
				</Alert>}
            </div>}
    </>;
}

function NumberCallbackSettings(props: NumberConfigProps) {

    const fields = ["url", "method", "format", "username", "password"];
    const changeEvents = fields.map(field => (e: any) => props.dispatchConfig({
        type: `set-callback-${field}` as any,
        value: e.target.value
    }));
    const fieldLabels = ["Callback URL", "Callback Method", "Callback Formats", "Callback Username", "Callback Password"];

    const inputs = [];
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const label = fieldLabels[i];
        const onChange = changeEvents[i];

        const pp = props as any;
        const currentFieldValue = pp[`callback${capitalizeFirstLetter(field)}`] ?? "";

        const input =
            <div className="input-field" key={field}>
                <TextField
                    fullWidth
                    margin="dense"
                    value={currentFieldValue}
                    onChange={onChange}
                    label={label}/>
            </div>;
        inputs.push(input);
    }

    return <>{inputs}</>;
}

function NumbersSettingsDialog(props: NumberSettingsDialogProps) {

    const {open, close, number} = props;
    const [config, dispatchConfig] = useReducer(configReducer, configFromNumber(number));
    const [tab, setTab] = useState(0);
    const [saving, setSaving] = useState(false);
    const api = useNumberDispatchApi();
    const client = useQueryClient();

    useEffect(() => {
        setTab(0);
        dispatchConfig({type: "init", from: number});
    }, [number, open]);

    const saveChanges = async () => {
        try {
            setSaving(true);
            await api.updateNumber(number.numberId, config);
        } finally {
            await client.invalidateQueries(numbersQueryKey);
            setSaving(false);
            close();
        }
    };

    const tabProps = {...config, dispatchConfig, number: number};

    let buttons = <>
        <Button onClick={() => close()}>Close</Button>
    </>;
    if (config.$modified) {
        buttons = <>
            <Button onClick={() => close()}>Cancel</Button>
            <Button onClick={saveChanges} variant="contained">Save Changes</Button>
        </>;
    }

    const dialogBody = <>
        <Tabs value={tab} onChange={(_, idx) => setTab(idx)} className="tabs">
            <Tab label="General"/>
            <Tab label="Attach Inbound Templates"/>
            <Tab label="Callbacks"/>
        </Tabs>
        <DialogContent className="big-dialog-content">
            {tab === 0 && <NumberGeneralSettings {...tabProps}/>}
            {tab === 1 && <NumberTemplateSettings {...tabProps}/>}
            {tab === 2 && <NumberCallbackSettings {...tabProps}/>}
        </DialogContent>
        <DialogActions className="big-dialog-actions">
            {buttons}
        </DialogActions>
    </>;

    const saveBody = <>
        <DialogContent className="big-dialog-content centerfold">
            <CircularProgress/>
        </DialogContent>
    </>;

    return (
        <Dialog open={open} className="number-settings-dialog" maxWidth="xl">
            <DialogTitle className="big-dialog-title">Change Number Settings</DialogTitle>
            {!saving && dialogBody}
            {saving && saveBody}
        </Dialog>
    );
}

export function NumbersSection() {

    const numbersApi = useNumberDispatchApi();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentNumber, setCurrentNumber] = useState<NumberDescriptor | null>(null);

    const {
        isFetching: isFetchingNumbers,
        isError: isErrorFetchingNumbers,
        error: numberFetchingError,
        data: numbers
    } = useQuery(numbersQueryKey, async () => {
        return await numbersApi.getNumbers();
    }, {
        staleTime: Infinity,
    });

    if (isErrorFetchingNumbers) {
        console.error(numberFetchingError);
        return <Alert severity="error">Error getting numbers</Alert>;
    }

    const items: BigSelectorItem[] = [];
    const selector = <BigSelector
        className={"numbers-section"}
        title="Your Numbers"
        emptyMessage="You don't appear to have any numbers yet."
        loading={isFetchingNumbers}
        items={items}
    />;

    if (isFetchingNumbers)
        return selector;

    if (!numbers)
        return selector;

    const countryFlags = {
        "EE": Flags.EE,
        "US": Flags.US,
        "AU": Flags.AU,
        "GB": Flags.GB,
    } as any;

    for (const number of numbers) {

        const inline: JSX.Element[] = [];

        if (number.isSMS)
            inline.push(<NumberTag kind="sms" templateId={number.smsTemplateId}/>);

        if (number.isVoice)
            inline.push(<NumberTag kind="voice" templateId={number.voiceTemplateId}/>);


        const FlagComponent = countryFlags[number.country] ?? Flags.EU;
        const flagContainer = <div className="flag flag-container"><FlagComponent className="flag"/></div>;

        const numberDisplay = <div className="number-display">
            <div className="big-number">{formatPhoneNumber(number)}</div>
            {number.name && <div className="name">{number.name}</div>}
        </div>;

        items.push({
            id: (number.numberId || number.number).toString(),
            name: numberDisplay,
            icon: flagContainer,
            inlineComponents: inline,
            onActivate: () => {
                setCurrentNumber(number);
                setDialogOpen(true);
            }
        });
    }


    return <>
        {selector}
        {currentNumber && <NumbersSettingsDialog
			number={currentNumber}
			open={dialogOpen}
			close={() => setDialogOpen(false)}/>
        }
    </>;
}

export default NumbersSection;
