export const commonFormInputOptions = [
	{
		name: "label",
		label: "Label",
		validations: [],
		default: ""
	},
	{
		name: "placeholder",
		label: "Placeholder",
		validations: [],
		default: ""
	},
	{
		name: "type",
		type: "select",
		label: "Type",
		validations: [],
		default: "",
		options: [
			{ label: "Text (single-line)", value: "text" },
			{ label: "Text Area (mutli-line)", value: "textarea" },
			{ label: "Email Address", value: "email" },
			{ label: "Number", value: "number" }
		]
	},
	{
		name: "required",
		type: "bool",
		label: "Required",
		validations: [],
		default: false
	}
];
