// eslint-disable-next-line no-redeclare
/* global alert */

import React, { useState } from "react";
import { PageBuilderPanel } from "./PageBuilderPanel";
import "./PageBuilderContent.scss";
import PageCanvas from "./PageCanvas";
import PageBuilderComponentList from "./component-list/PageBuilderComponentList";
import { DragDropContext } from "react-beautiful-dnd";
import { PageBuilderDeleteDropZone } from "./PageBuilderDeleteDropZone";
import { PageBuilderComponentSettings } from "./component-settings/PageBuilderComponentSettings";
import { v4 as uuidv4 } from "uuid";
import WindowTaskBar, { TaskBarAction } from "components/common/window/WindowTaskBar";

/**
 * @param {string} input
 * @param {string} delimiter
 */
function splitOnce (input, delimiter = " ") {
	const parts = input.split(delimiter);
	return [parts.pop(), parts.join(delimiter)];
}

const PageBuilderContent = ({ page, components, componentGroups, setFullscreen, isFullscreen }) => {
	const [data, setData] = useState(page);
	const [selected, setSelected] = useState(null);
	const [draggingType, setDraggingType] = useState(null);

	const onDragEnd = (result) => {
		const { source, destination, draggableId, type } = result;
		setDraggingType(null);

		// Didn't drop in a suitable droppable area
		if (destination === null) {
			return;
		}

		// Dropped back in starting position
		if (
			source.droppableId === destination.droppableId &&
			source.index === destination.index
		) {
			return;
		}

		if (draggableId.match(/^block-/)) {
			return addCompoundSection(result);
		}

		const [srcZone, srcLayout] = splitOnce(source.droppableId, "-");
		const [zone, layout] = splitOnce(destination.droppableId, "-");

		// Delete a layout or an element
		if (destination.droppableId === "DELETE") {
			if (type === "LAYOUT") {
				return deleteSection({
					layout: draggableId,
					index: source.index
				});
			}
			if (type === "ELEMENT") {
				return deleteElement({
					id: draggableId,
					layout: srcLayout,
					zone: srcZone,
					index: source.index
				});
			}
		}

		// Dropped a layout
		if (type === "LAYOUT") {
			if (source.droppableId === "CANVAS") {
				// If it came from the CANVAS then it's being moved..
				moveSection(result);
			} else {
				// Otherwise we must be adding something to the page
				const { update } = addSection(result);
				setData(update);
			}
			return;
		}

		if (srcLayout.match(/^group/)) {
			// Add element
			const componentDef = components[draggableId];
			const { update } = addElement({
				type: componentDef.type,
				layout,
				zone,
				index: destination.index,
				value: componentDef.defaultValue,
				def: componentDef
			});
			return setData(update);
		}

		moveElement({
			srcLayout,
			srcZone,
			srcIndex: source.index,
			layout,
			zone,
			index: destination.index
		});
	};

	const onFormSubmitted = ({ component, column, layout, values }) => {
		const update = {};

		if (component) {
			update.components = {
				...data.components,
				[component]: {
					...data.components[component],
					props: values.component
				}
			};
		}

		if (layout) {
			const sections = [...data.sections];
			const sidx = sections.findIndex(s => s.id === layout);
			sections[sidx].props = values.layout;
		}

		if (typeof column !== "undefined") {
			update.columns = {
				...data.columns,
				[layout]: {
					...data.columns[layout],
					[column]: { ...values.column }
				}
			};
		}

		setData({ ...data, ...update });
		setActiveElement(null);
	};

	const setActiveElement = (payload) => {
		if (!payload) {
			return setSelected(null);
		}

		const selected = {};
		if (payload && payload.component) {
			selected.component = data.components?.[payload.component] ?? null;
		}
		if (payload.layout) {
			selected.layout = {
				...data.sections.find(s => s.id === payload.layout),
				id: payload.layout
			};
		}
		if (payload.layout && typeof payload.dropzone === "number") {
			selected.column = {
				...data.columns[payload.layout]?.[payload.dropzone] ?? {},
				id: payload.dropzone
			};
		}

		setSelected(selected);
	};

	const addSection = ({ destination, draggableId }) => {
		const id = "layout-" + uuidv4().split("-").pop();
		const sections = [...data.sections];
		const newSection = {
			id,
			type: draggableId,
			children: []
		};
		sections.splice(destination.index, 0, newSection);
		return {
			id,
			update: { ...data, sections }
		};
	};

	const moveSection = ({ source, destination }) => {
		const sections = [...data.sections];
		const moving = sections.splice(source.index, 1).pop();
		sections.splice(destination.index, 0, moving);
		setData({ ...data, sections });
	};

	const deleteSection = ({ layout }) => {
		const sections = [...data.sections];
		const sourceLayoutIndex = sections.findIndex(s => s.id === layout);
		if (sourceLayoutIndex === -1) {
			return;
		}

		layout = sections.splice(sourceLayoutIndex, 1).pop();
		const components = { ...data.components };
		for (const c of layout.children) {
			if (Array.isArray(c) && c.length) {
				for (const i of c) {
					delete components[i];
				}
			}
		}

		setData({
			...data,
			sections,
			components
		});
	};

	const addElement = ({ layout, zone, index, type, value, def }, state) => {
		if (!state) { state = data; }
		const id = uuidv4().split("-").pop();
		const sections = [...state.sections];
		const sidx = sections.findIndex(s => s.id === layout);
		const children = sections[sidx].children[zone] || [];
		children.splice(index, 0, id);
		sections[sidx].children[zone] = children;

		return {
			id,
			update: {
				...state,
				components: {
					...state.components,
					[id]: {
						id: id,
						type: type,
						// children: value,
						props: { value, ...def.def }
					}
				},
				sections
			}
		};
	};

	const moveElement = ({ srcLayout, layout, srcZone, zone, srcIndex, index }) => {
		const sections = [...data.sections];
		const sourceLayoutIndex = sections.findIndex(s => s.id === srcLayout);
		const destLayoutIndex = sections.findIndex(s => s.id === layout);

		const srcChildren = sections[sourceLayoutIndex].children[srcZone] || [];
		const moving = srcChildren.splice(srcIndex, 1);

		const destChildren = sections[destLayoutIndex].children[zone] || [];
		destChildren.splice(index, 0, moving);
		sections[destLayoutIndex].children[zone] = destChildren;

		setData({ ...data, sections });
	};

	const deleteElement = ({ layout, zone, index, id }) => {
		const sections = [...data.sections];
		const sourceLayoutIndex = sections.findIndex(s => s.id === layout);
		if (typeof sections[sourceLayoutIndex] === "undefined") {
			return;
		}

		const children = sections[sourceLayoutIndex].children[zone] || [];
		children.splice(index, 1);
		sections[sourceLayoutIndex].children[zone] = children;
		const components = { ...data.components };
		delete components[id];

		setData({ ...data, sections, components });
	};

	const addCompoundSection = (props) => { // { destination, draggableId }) => {
		const { def } = components[props.draggableId];

		// Add layout in place
		let { id: layoutId, update: state } = addSection({ destination: props.destination, draggableId: def.layout });

		// Add each child element
		for (const zone of Object.keys(def.elements)) {
			for (let i = 0; i < def.elements[zone].length; i++) {
				let el = def.elements[zone][i];
				let value = null;

				if (typeof el === "object") {
					const { type, value: p } = el;
					el = type;
					value = p;
				}

				const componentDef = components[el];
				value = value || componentDef.defaultValue;

				const { update } = addElement({
					type: componentDef.type,
					layout: layoutId,
					zone,
					index: i,
					value: value,
					def: componentDef
				}, state);
				state = update;
			}
		}

		setData(state);
	};

	const onBeforeCapture = (result) => {
		setDraggingType(result.draggableId.match(/layout/) ? "LAYOUT" : "ELEMENT");
	};

	const setValue = (id, value) => {
		const components = data.components;
		if (!Object.prototype.hasOwnProperty.call(components, id)) {
			return;
		}

		if (!Object.prototype.hasOwnProperty.call(components[id], "props")) {
			components[id].props = {};
		}

		components[id].props.value = value;
		setData({ ...data, components });
	};

	const onClickSave = (e) => {
		e.preventDefault();
		alert(JSON.stringify(data, null, 4));
	};

	const actions = [
		<TaskBarAction key="save" icon="fas fa-save" onClick={onClickSave}/>
	];

	if (isFullscreen) {
		actions.push(<TaskBarAction
			key="window-restore" icon="fas fa-window-restore"
			onClick={() => setFullscreen(false)}
		/>);
	} else {
		actions.push(<TaskBarAction
			key="window-maximize" icon="fas fa-window-maximize"
			onClick={() => setFullscreen(true)}
		/>);
	}

	return (
		<>
			<WindowTaskBar title="Page Builder" actions={actions}/>

			<DragDropContext
				onBeforeCapture={onBeforeCapture}
				onDragEnd={onDragEnd}
			>
				<>
					<div className="up-pb-content">
						{draggingType && <PageBuilderDeleteDropZone allowType={draggingType}/>}
						<PageBuilderPanel position="left" isOpen>
							<PageBuilderComponentList components={components} componentGroups={componentGroups}/>
						</PageBuilderPanel>

						<PageCanvas page={data} components={components} setActiveElement={setActiveElement}
									setValue={setValue}/>

						<PageBuilderPanel position="right" isOpen={!!selected}>
							{selected && <PageBuilderComponentSettings
								selected={selected}
								onSubmit={onFormSubmitted}
								onClose={() => setActiveElement(null)}
							/>}
						</PageBuilderPanel>
					</div>
					{/* <PageBuilderPanel position={'bottom'} isOpen={ui.showBottom}>Center</PageBuilderPanel> */}
				</>
			</DragDropContext>
		</>
	);
};

export default PageBuilderContent;
