import {createAction} from "redux-act";

type TODO = any;

export const ActivateOpenModalAction = createAction<TODO>("Activate - Open Modal");
export const ActivateCloseModalAction = createAction("Activate - Close Modal");

export const ActivateAction = createAction("Activate");
export const ActivateRequestAction = createAction("Activate - Request");
export const ActivateSuccessAction = createAction("Activate - Success");
export const ActivateFailedAction = createAction("Activate - Failed");

export const ResendActivateAction = createAction("Resend Activate");
export const ResendActivateRequestAction = createAction("Resend Activate - Request");
export const ResendActivateSuccessAction = createAction("Resend Activate - Success");
export const ResendActivateFailedAction = createAction("Resend Activate - Failed");
export const LoginAction = createAction("Login");
export const LoginRequestAction = createAction("Login - Request");
export const LoginSuccessAction = createAction("Login - Success");
export const LoginFailedAction = createAction("Login - Failed");
export const LogoutAction = createAction("Logout");
export const LogoutAndElevateAction = createAction("Logout & Elevate");
export const PrivateRouteModeLogin = "LOGIN";
export const PrivateRouteModeRegister = "REGISTER";
export const PrivateRouteAction = createAction("Private Route");
export const PrivateRouteLoginAction = createAction("Private Route - Login");
export const PrivateRouteRegisterAction = createAction("Private Route - Register");

export const CheckFetchProfileAction = createAction("Check Fetch Profile");

export const FetchProfileAction = createAction("Fetch Profile");
export const FetchProfileRequestAction = createAction("Fetch Profile - Request");
export const FetchProfileSuccessAction = createAction("Fetch Profile - Success");
export const FetchProfileFailedAction = createAction("Fetch Profile - Failed");

export const RegisterAction = createAction("Register");
export const RegisterRequestAction = createAction("Register - Request");
export const RegisterSuccessAction = createAction("Register - Success");
export const RegisterFailedAction = createAction("Register - Failed");

export const ResetPasswordModeRequest = "REQUEST";
export const ResetPasswordModeProcess = "PROCESS";

export const ResetPasswordOpenModalAction = createAction("Reset Password - Open Modal");
export const ResetPasswordCloseModalAction = createAction("Reset Password - Close Modal");

// Send Reset Email
export const SendResetPasswordAction = createAction<TODO>("Reset Password");
export const ResetPasswordRequestAction = createAction("Reset Password - Request");
export const SendResetPasswordSuccessAction = createAction("Reset Password - Success");
export const SendResetPasswordFailedAction = createAction("Reset Password - Failed");

// Verify Reset Token
export const VerifyResetTokenAction = createAction("Verify Reset Token");
export const VerifyResetTokenRequestAction = createAction("Verify Reset Token - Request");
export const VerifyResetTokenSuccessAction = createAction("Verify Reset Token - Success");
export const VerifyResetTokenFailedAction = createAction("Verify Reset Token - Failed");

// Process Reset
export const ProcessResetAction = createAction<TODO>("Process Reset");
export const ProcessResetRequestAction = createAction("Process Reset - Request");
export const ProcessResetSuccessAction = createAction("Process Reset - Success");
export const ProcessResetFailedAction = createAction("Process Reset - Failed");

export const TokenExpiredAction = createAction("Token Expired");
export const TokenRefreshAction = createAction("Token Refresh");
export const TokenRefreshRequestAction = createAction("Token Refresh - Request");
export const TokenRefreshSuccessAction = createAction("Token Refresh - Success");
export const TokenRefreshFailedAction = createAction("Token Refresh - Failed");


export const ActivateModeVerification = createAction("Activate Mode Verification");

export const VerifyAction = createAction("Verify");
export const VerifyRequestAction = createAction("Verify - Request");
export const VerifySuccessAction = createAction("Verify - Success");
export const VerifyFailedAction = createAction("Verify - Failed");

export const ResendVerificationAction = createAction("Resend Verification");
export const ResendVerificationRequestAction = createAction("Resend Verification - Request");
export const ResendVerificationSuccessAction = createAction("Resend Verification - Success");
export const ResendVerificationFailedAction = createAction("Resend Verification - Failed");
