import {Button} from "@mui/material";
import {PlayArrow} from "@mui/icons-material";
import {titleCase} from "../../../../util/strings";
import {UpwireModalSubheader} from "../../common/modal/modal";
import React from "react";

export type EnvironmentSpec = {
    "@account": {
        "test": { [name: string]: Environment } | undefined
        "prod": { [name: string]: Environment } | undefined
    }
    "@namespace": {
        "test": { [name: string]: Environment } | undefined
        "prod": { [name: string]: Environment } | undefined
    }
}

export type Environment = {
    id: string;
    scope: "@account" | "@namespace"
    env: "test" | "prod",
    name: string;
    settings: any
}

export type EnvironmentCategoryType = {
    name: string,
    environments: Environment[]
    test: boolean
}

function flattenEnvironments(environments: EnvironmentSpec): EnvironmentCategoryType[] {

    const categories: EnvironmentCategoryType[] = [];

    for (const scope in environments) {
        for (const env in (environments as any)[scope]) {
            const data = (environments as any)[scope][env];
            if (data) {
                let attrib = scope === "@account" ? "Your" : "Global";
                categories.push({
                    name: titleCase(`${attrib} ${env} Environments`),
                    environments: Object.values(data),
                    test: env === "test"
                });
            }
        }
    }

    // sort categories, so that test environments are first
    categories.sort((a, b) => {
        if (a.test === b.test)
            return 0;
        else if (a.test)
            return -1;
        else
            return 1;
    });

    return categories;
}

export function flattenCategories(spec: EnvironmentSpec): Environment[] {
    const categories = flattenEnvironments(spec);
    const environments: Environment[] = [];
    for (const category of categories)
        environments.push(...category.environments)
    return environments;
}


export function findEnvironmentById(environmentId: string, spec: EnvironmentSpec): Environment | undefined {
    for (const scope in spec) {
        for (const env in (spec as any)[scope]) {
            const envs = (spec as any)[scope][env];
            if (envs) {
                for (const name in envs) {
                    const env = envs[name];
                    if (env.id === environmentId)
                        return env;
                }
            }
        }
    }
}

export function EnvironmentItem({environment, onSelect}: { environment: Environment, onSelect: () => void }) {
    return <div className="environment-item">
        <Button onClick={onSelect} variant="contained">
            <PlayArrow/> {environment.name}
        </Button>
    </div>;
}

export function EnvironmentCategory({environments, onSelect, category}: {
    environments: Environment[],
    onSelect: (environmentId: string) => void,
    category: string
}) {
    return <div className="environment-category">
        <h3>{category}</h3>
        {
            environments.map(it => <EnvironmentItem
                key={it.id}
                environment={it}
                onSelect={() => onSelect(it.id)}/>)
        }
    </div>;
}

export function EnvironmentPicker(props: {
    environments: EnvironmentSpec,
    onSelect(environmentId: string): void
    onBack(): void
}) {

    const categories = flattenEnvironments(props.environments);

    if (categories.length === 0) {
        return <div>
            Please setup <a href="/account/environments">a new environment</a> first.
        </div>;
    }

    return <div className="environment-picker">
        <UpwireModalSubheader text={"Select an environment"} onBack={props.onBack}/>
        <div className="environment-picker-categories">
            {
                categories.map(it => <EnvironmentCategory
                    key={it.name}
                    category={it.name}
                    environments={it.environments}
                    onSelect={props.onSelect}/>)
            }
        </div>
    </div>;
}