import { combineReducers } from "redux-immutable";

const sample = {
	"page": {
		"id": "1",
		"sections": [
			{
				"id": "layout-8ea9c7ea0310",
				"type": "layout-3",
				"children": [
					[
						"089d87f1c325",
						"bc68c6479c08"
					],
					[]
				]
			}
		],
		"components": {
			"089d87f1c325": {
				"id": "089d87f1c325",
				"type": "element-image",
				"props": {}
			},
			"bc68c6479c08": {
				"id": "bc68c6479c08",
				"type": "element-p",
				"props": {
					"value": "<h3 style='text-align: center'>Title</h3><p style='text-align: center'>Enter some text</p>"
				}
			}
		},
		"columns": {
			"_layout-1": {
				"2": {
					"customStyles": "border: solid 4px green"
				}
			}
		}
	},
	"componentGroups": [
		{
			"id": "group-layouts",
			"title": "Page Layout",
			"icon": "th-large",
			"type": "LAYOUT",
			"components": [
				"layout-1",
				"layout-2",
				"layout-3",
				"layout-4",
				"layout-5",
				"layout-6",
				"layout-7"
			]
		},
		{
			"id": "group-blocks",
			"title": "Blocks",
			"icon": "th-large",
			"type": "LAYOUT",
			"components": [
				"block-1",
				"block-2",
				"block-3",
				"block-4",
				"block-5",
				"block-6",
				"block-7",
				"block-8"
			]
		},
		{
			"id": "group-elements",
			"title": "Elements",
			"icon": "clone",
			"components": [
				"element-p",
				"element-image",
				"element-hr"
			]
		},
		{
			"id": "group-form",
			"title": "Form Fields",
			"icon": "clone",
			"components": [
				"element-input",
				"element-input-email",
				"element-input-textarea",
				"element-input-select"
			]
		}
	],
	"components": {
		"layout-1": {
			"id": "layout-1",
			"type": "layout",
			"image": "/images/pages/layout-1.svg",
			"label": "nested deep",
			"columns": 5,
			"def": {
				"type": "row",
				"children": [
					{
						"type": "column",
						"dropzone": 0,
						"classes": [
							"is-4"
						],
						"props": {
							"label": "Aside"
						}
					},
					{
						"type": "column",
						"classes": [
							"is-8"
						],
						"children": [
							{
								"type": "row",
								"children": [
									{
										"type": "column",
										"dropzone": 1,
										"classes": [
											"is-6"
										],
										"props": {
											"label": "Noot A"
										}
									},
									{
										"type": "column",
										"dropzone": 2,
										"classes": [
											"is-6"
										],
										"props": {
											"label": "Noot B"
										}
									}
								]
							},
							{
								"type": "row",
								"children": [
									{
										"type": "column",
										"dropzone": 3,
										"classes": [
											"is-12"
										],
										"props": {
											"label": "Double Noot"
										}
									}
								]
							}
						]
					}
				]
			}
		},
		"layout-7": {
			"id": "layout-7",
			"type": "layout",
			"image": "/images/pages/layout-7.svg",
			"label": "nested deep",
			"columns": 3,
			"def": {
				"type": "row",
				"children": [
					{
						"type": "column",
						"classes": [
							"is-4"
						],
						"children": [
							{
								"type": "row",
								"children": [
									{
										"type": "column",
										"dropzone": 0,
										"props": {
											"label": "Noot B"
										}
									}
								]
							},
							{
								"type": "row",
								"children": [
									{
										"type": "column",
										"dropzone": 1,
										"props": {
											"label": "Noot B"
										}
									}
								]
							}
						]
					},
					{
						"type": "column",
						"dropzone": 2,
						"classes": [
							"is-8"
						],
						"props": {
							"label": "Aside"
						}
					}
				]
			}
		},
		"layout-2": {
			"id": "layout-2",
			"type": "layout",
			"image": "/images/pages/layout-2.svg",
			"label": "Not nested",
			"columns": 2,
			"def": {
				"type": "row",
				"children": [
					{
						"type": "column",
						"dropzone": 0
					},
					{
						"type": "column",
						"dropzone": 1
					}
				]
			}
		},
		"layout-5": {
			"id": "layout-5",
			"type": "layout",
			"image": "/images/pages/layout-5.svg",
			"label": "Not nested",
			"columns": 3,
			"def": {
				"type": "row",
				"children": [
					{
						"type": "column",
						"dropzone": 0
					},
					{
						"type": "column",
						"dropzone": 1
					},
					{
						"type": "column",
						"dropzone": 2
					}
				]
			}
		},
		"layout-6": {
			"id": "layout-6",
			"type": "layout",
			"image": "/images/pages/layout-6.svg",
			"label": "Not nested",
			"columns": 4,
			"def": {
				"type": "row",
				"children": [
					{
						"type": "column",
						"dropzone": 0
					},
					{
						"type": "column",
						"dropzone": 1
					},
					{
						"type": "column",
						"dropzone": 2
					},
					{
						"type": "column",
						"dropzone": 3
					}
				]
			}
		},
		"layout-4": {
			"id": "layout-4",
			"type": "layout",
			"image": "/images/pages/layout-4.svg",
			"columns": 1,
			"def": {
				"type": "row",
				"children": [
					{
						"type": "column",
						"dropzone": 0
					}
				]
			}
		},
		"layout-3": {
			"id": "layout-3",
			"type": "layout",
			"image": "/images/pages/layout-3.svg",
			"label": "Not nested",
			"columns": 2,
			"def": {
				"type": "row",
				"children": [
					{
						"type": "column",
						"dropzone": 0,
						"classes": [
							"is-4"
						]
					},
					{
						"type": "column",
						"dropzone": 1,
						"classes": [
							"is-8"
						]
					}
				]
			}
		},
		"element-image": {
			"id": "element-image",
			"type": "element-image",
			"image": "/images/pages/el-photo.svg"
		},
		"element-p": {
			"id": "element-p",
			"type": "element-p",
			"image": "/images/pages/el-paragraph.svg",
			"defaultValue": "<h3>Title</h3><p>Enter some text</p>",
			"def": {
				"type": "p"
			}
		},
		"element-hr": {
			"id": "element-hr",
			"type": "element-hr",
			"image": "/images/pages/el-hr.svg",
			"def": {
				"type": "hr"
			}
		},
		"element-input": {
			"id": "element-input",
			"type": "element-input-textarea",
			"image": "/images/pages/el-input.svg",
			"def": {
				"label": "Text Input",
				"placeholder": "Input text here",
				"type": "text"
			}
		},
		"element-input-email": {
			"id": "element-input-email",
			"type": "element-input-textarea",
			"image": "/images/pages/el-input-email.svg",
			"def": {
				"label": "Email address",
				"placeholder": "tom@example.com",
				"type": "email"
			}
		},
		"element-input-textarea": {
			"id": "element-input-textarea",
			"type": "element-input-textarea",
			"image": "/images/pages/el-input-textarea.svg",
			"def": {
				"label": "Textarea",
				"placeholder": "Please enter some text",
				"type": "textarea"
			}
		},
		"element-input-select": {
			"id": "element-input-select",
			"type": "element-input-select",
			"image": "/images/pages/el-input-select.svg",
			"def": {
				"label": "Select",
				"placeholder": "Enter some text",
				"options": [
					{
						"label": "a",
						"value": "a"
					},
					{
						"label": "b",
						"value": "b"
					},
					{
						"label": "c",
						"value": "c"
					}
				]
			}
		},
		"block-1": {
			"id": "block-1",
			"image": "/images/pages/block-1.svg",
			"def": {
				"layout": "layout-2",
				"elements": {
					"0": [
						"element-image"
					],
					"1": [
						"element-p"
					]
				}
			}
		},
		"block-2": {
			"id": "block-2",
			"image": "/images/pages/block-2.svg",
			"def": {
				"layout": "layout-2",
				"elements": {
					"0": [
						"element-image",
						{
							"type": "element-p",
							"value": "<div style='text-align: center'><h3>Title</h3><p>Enter Some text</div>",
							"props": {}
						}
					],
					"1": [
						"element-image",
						{
							"type": "element-p",
							"value": "<div style='text-align: center'><h3>Title</h3><p>Enter Some text</div>",
							"props": {}
						}
					]
				}
			}
		},
		"block-3": {
			"id": "block-3",
			"image": "/images/pages/block-3.svg",
			"def": {
				"layout": "layout-5",
				"elements": {
					"0": [
						"element-image",
						{
							"type": "element-p",
							"value": "<div style='text-align: center'><h3>Title</h3><p>Enter Some text</div>",
							"props": {}
						}
					],
					"1": [
						"element-image",
						{
							"type": "element-p",
							"value": "<div style='text-align: center'><h3>Title</h3><p>Enter Some text</div>",
							"props": {}
						}
					],
					"2": [
						"element-image",
						{
							"type": "element-p",
							"value": "<div style='text-align: center'><h3>Title</h3><p>Enter Some text</div>",
							"props": {}
						}
					]
				}
			}
		},
		"block-4": {
			"id": "block-4",
			"image": "/images/pages/block-4.svg",
			"def": {
				"layout": "layout-6",
				"elements": {
					"0": [
						"element-image",
						{
							"type": "element-p",
							"value": "<div style='text-align: center'><h3>Title</h3><p>Enter Some text</div>",
							"props": {}
						}
					],
					"1": [
						"element-image",
						{
							"type": "element-p",
							"value": "<div style='text-align: center'><h3>Title</h3><p>Enter Some text</div>",
							"props": {}
						}
					],
					"2": [
						"element-image",
						{
							"type": "element-p",
							"value": "<div style='text-align: center'><h3>Title</h3><p>Enter Some text</div>",
							"props": {}
						}
					],
					"3": [
						"element-image",
						{
							"type": "element-p",
							"value": "<div style='text-align: center'><h3>Title</h3><p>Enter Some text</div>",
							"props": {}
						}
					]
				}
			}
		},
		"block-5": {
			"id": "block-5",
			"image": "/images/pages/block-5.svg",
			"def": {
				"layout": "layout-6",
				"elements": {
					"0": [
						"element-image"
					],
					"1": [
						"element-p"
					],
					"2": [
						"element-image"
					],
					"3": [
						"element-p"
					]
				}
			}
		},
		"block-6": {
			"id": "block-6",
			"image": "/images/pages/block-6.svg",
			"def": {
				"layout": "layout-7",
				"elements": {
					"0": [
						"element-image"
					],
					"1": [
						"element-image"
					],
					"2": [
						"element-image"
					]
				}
			}
		},
		"block-7": {
			"id": "block-7",
			"image": "/images/pages/block-7.svg",
			"def": {
				"layout": "layout-3",
				"elements": {
					"0": [
						"element-image"
					],
					"1": [
						"element-image"
					]
				}
			}
		},
		"block-8": {
			"id": "block-8",
			"image": "/images/pages/block-8.svg",
			"def": {
				"layout": "layout-2",
				"elements": {
					"0": [
						"element-image"
					],
					"1": [
						"element-image"
					]
				}
			}
		}
	}
};

const { components, componentGroups, page } = sample;

const componentsReducer = (state, action) => {
	if (typeof state === "undefined") {
		return { components, componentGroups };
	}
	return state;
};

const pageReducer = (state, action) => {
	if (typeof state === "undefined") {
		return page;
	}
	return state;
};

export default combineReducers({
	components: componentsReducer,
	page: pageReducer
});
