import React, { Component } from "react";
import { connect } from "react-redux";

import PlaygroundModal from "../../../../../../../components/common/modal/PlaygroundModal";
import { getNestedState } from "core/lib/state";
import NodeEditForm from "../../../../../../../components/playground/inlet/filter/canvas/phase/node/edit/NodeEditForm";
import { CloseEditNodeModalAction } from "../../../../../../../actions/playground/inlet/filter/canvas/phase/node/edit";
import { updateCanvasPhase } from "../../../../../../../lib/playground/canvas/phase/update";

class NodeEditModal extends Component {
	state = {
		isHidden: false
	};

	setIsHidden = (isHidden) => {
		this.setState(() => ({
			isHidden: isHidden
		}));

		return false;
	};

	render () {
		let { closeModal, nodeEdit, browser } = this.props;
		const { isHidden } = this.state;

		if (!nodeEdit) {
			nodeEdit = {};
		}
		const open = !!(nodeEdit && nodeEdit.open);
		if (!open) {
			return null;
		}
		let { nodeProps, moduleData } = nodeEdit;
		if (!nodeProps) {
			nodeProps = {};
		}
		if (!moduleData) {
			moduleData = {};
		}

		let { playgroundProps } = nodeProps;
		if (!playgroundProps) {
			playgroundProps = {};
		}

		const doClose = () => {
			closeModal();

			this.setIsHidden(false);

			if (nodeEdit.onClose) {
				nodeEdit.onClose();
			}
		};

		const onSubmit = (phase) => {

			console.log("onSubmit", { playgroundProps });

			const { dashboard, canvas, updateCanvas } = playgroundProps;
			const { playground } = dashboard;



			updateCanvas({
				namespace: playground.namespace,
				playgroundId: playground.playground_id,
				canvasId: canvas.canvas_id,
				model: updateCanvasPhase(dashboard, canvas.canvas_id, phase.id, phase)
			});

			doClose();
		};

		const title = moduleData?.node_props?.name || moduleData?.name || "Module";

		return (
			<>
				<PlaygroundModal
					title={title}
					nodeProps={nodeProps}
					closeModal={doClose}
					icon="fas fa-diagnoses"
					modalState={open}
					browser={browser}
					isHidden={isHidden}
				>
					<NodeEditForm
						edit={nodeEdit}
						onSubmit={onSubmit}
						playgroundProps={playgroundProps}
						setModalHidden={this.setIsHidden}
					/>
				</PlaygroundModal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseEditNodeModalAction())
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {
		browser: state.get("browser")
	};

	const node = getNestedState(state, ["canvas", "playground", "inlet", "filter", "canvas", "phase", "node"]);
	if (!node) {
		return mapped;
	}

	return {
		nodeEdit: node.get("edit"),
		...mapped
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeEditModal);
