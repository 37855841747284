import React from "react";
import PropTypes from "prop-types";

import { authHasAnyRoles } from "../../../lib/auth/token";

import "./RoleRequired.scss";

export const RoleRequired = ({ requireAnyRoles, children }) => {
	if (!authHasAnyRoles(requireAnyRoles)) {
		return (
			<div key="role-required" className="role-required">
        <span className="icon">
          <i className="fas fa-shield-check"/>
        </span>
				<span>Access denied, sorry you do not access this.</span>
			</div>
		);
	}

	return children;
};

RoleRequired.propTypes = {
	requireAnyRoles: PropTypes.array.isRequired
};
