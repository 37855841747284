import { call, put, takeLatest } from "redux-saga/effects";
import { getEnvironmentVariableQuery } from "../../../../api/data/environment/variable/query";
import {
	EnvironmentVariableQueryAction,
	EnvironmentVariableQueryFailedAction,
	EnvironmentVariableQueryRequestAction,
	EnvironmentVariableQuerySuccessAction
} from "../../../../actions/data/environment/variable/query";

/// Environment Variable Query
// Worker
function * getEnvironmentVariableQueryWorker (action) {
	try {
		yield put(EnvironmentVariableQueryRequestAction());

		const r = yield call(getEnvironmentVariableQuery, action.payload);
		if (r.json) {
			yield put(EnvironmentVariableQuerySuccessAction(r.json));
		} else {
			yield put(EnvironmentVariableQueryFailedAction("Failed to query environment variable"));
		}
	} catch (e) {
		yield put(EnvironmentVariableQueryFailedAction(e));
	}
}

// Saga
export function * getEnvironmentVariableQuerySaga () {
	yield takeLatest(EnvironmentVariableQueryAction.getType(), getEnvironmentVariableQueryWorker);
}

// Export

export const querySagas = [
	getEnvironmentVariableQuerySaga()
];

export default querySagas;
