import { extractUpdatableCanvas } from "../update";
import { v4 as uuid } from "uuid";

import { defaultCanvasPhase, ensurePhaseInitialised } from "./init";

export const addNewCanvasPhase = (dashboard, canvasId, opts) => {
	if (!dashboard) {
		throw new Error("no 'dashboard' provided");
	}
	if (!canvasId) {
		throw new Error("no 'canvasId' provided");
	}
	if (!opts) {
		opts = {};
	}

	const model = extractUpdatableCanvas(dashboard, canvasId);
	const { canvas } = model;
	const { name } = opts;
	const id = uuid();

	if (!canvas.schema.phases) {
		canvas.schema.phases = [];
	}
	canvas.schema.phases.push({
		id: id,
		name: name
	});

	return [id, { playground: dashboard.playground, canvas: canvas }];
};

export const updateCanvasPhase = (dashboard, canvasId, phaseId, opts) => {
	if (!dashboard) {
		throw new Error("no 'dashboard' provided");
	}
	if (!canvasId) {
		throw new Error("no 'canvasId' provided");
	}
	if (!opts) {
		opts = {};
	}

	const model = extractUpdatableCanvas(dashboard, canvasId);
	const { canvas } = model;

	if (!canvas.schema.phases) {
		canvas.schema.phases = [];
	}

	let found = false;
	if (canvas.schema.phases) {
		canvas.schema.phases.forEach((p, ii) => {
			if (p.id === phaseId) {
				found = true;
				canvas.schema.phases[ii] = ensurePhaseInitialised({
					...canvas.schema.phases[p],
					...opts
				});
			}
		});
	}

	if (!found) {
		throw new Error("failed to find phaseId: " + phaseId + " in dashboard data");
	}

	return { playground: dashboard.playground, canvas: canvas };
};

export const deleteCanvasPhase = (dashboard, canvasId, phaseId) => {
	if (!dashboard) {
		throw new Error("no 'dashboard' provided");
	}
	if (!canvasId) {
		throw new Error("no 'canvasId' provided");
	}
	if (!phaseId) {
		throw new Error("no 'phaseId' provided");
	}

	const model = extractUpdatableCanvas(dashboard, canvasId);
	const { canvas } = model;

	if (!canvas.schema.phases) {
		canvas.schema.phases = [];
	}

	const phases = [];

	let found = false;
	if (canvas.schema.phases) {
		canvas.schema.phases.forEach((p, ii) => {
			if (p.id === phaseId) {
				found = true;
			} else {
				phases.push(p);
			}
		});
	}

	if (!found) {
		throw new Error("failed to find phaseId: " + phaseId + " in dashboard data");
	}

	if (phases.length <= 0) {
		// Always ensure there is at least the default phase.
		phases[0] = defaultCanvasPhase();
	}

	canvas.schema.phases = phases;

	return { playground: dashboard.playground, canvas: canvas };
};

export const deleteCanvasPhaseTrash = (dashboard, canvasId, phaseId, danglingNodes) => {
	if (!dashboard) {
		throw new Error("no 'dashboard' provided");
	}
	if (!canvasId) {
		throw new Error("no 'canvasId' provided");
	}
	if (!phaseId) {
		throw new Error("no 'phaseId' provided");
	}

	const model = extractUpdatableCanvas(dashboard, canvasId);
	const { canvas } = model;

	const removeIds = {};

	danglingNodes.forEach((n, ni) => {
		removeIds[n.id] = n.id;
	});

	let found = false;
	if (canvas.schema.phases) {
		canvas.schema.phases.forEach((p, pi) => {
			if (p.id === phaseId) {
				found = true;

				for (const nodeId in canvas.schema.phases[pi].nodes) {
					if (Object.prototype.hasOwnProperty.call(canvas.schema.phases[pi].nodes, nodeId)) {
						if (removeIds[nodeId]) {
							delete canvas.schema.phases[pi].nodes[nodeId];
						}
					}
				}
			}
		});
	}

	if (!found) {
		throw new Error("failed to find phaseId: " + phaseId + " in dashboard data");
	}

	return { playground: dashboard.playground, canvas: canvas };
};

export const getPhaseById = (canvas, phaseId) => {
	let phase = null;
	if (canvas.schema.phases) {
		canvas.schema.phases.forEach((p, pi) => {
			if (p.id === phaseId) {
				phase = p;
			}
		});
	}

	if (!phase) {
		throw new Error("failed to find phaseId: " + phaseId + " in canvas id: " + canvas.canvas_id);
	}

	return phase;
};
