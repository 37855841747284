import { apiFetch } from "../../base";
import { getNamespace } from "../../../lib/auth/token";
import { EnvironmentScopeNamespace } from "../../../lib/constants";

export function * deleteEnvironment (payload) {
	const { model } = payload;
	const { scope, name } = model;

	const namespace = getNamespace();

	if (!name) {
		throw new Error("name missing in request");
	}

	return yield apiFetch({
		endpoint: `/data/v1/environment/${namespace}/${name}`,
		method: "DELETE",
		authenticated: true,
		withoutAccount: (scope === EnvironmentScopeNamespace)
	});
}
