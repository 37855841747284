import React from "react";

import "./ElevatedAuthRequestMsg.scss";

export const ElevatedAuthRequestMsg = () => {
	return (
		<div className="request-elevated-access">
      <span className="icon">
        <i className="fas fa-shield-check"/>
      </span>
			<span>Requesting elevated access rights.</span>
		</div>
	);
};
