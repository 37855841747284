import { call, put, takeLatest } from "redux-saga/effects";

import { putPlaygroundCanvasUpdate } from "../../../api/playground/canvas/update";
import {
	CanvasUpdateAction,
	CanvasUpdateFailedAction,
	CanvasUpdateRequestAction,
	CanvasUpdateSuccessAction
} from "../../../actions/playground/inlet/filter/canvas/update";

/// / Playground Canvas Update
// Worker
function * updatePlaygroundCanvasWorker (action) {
	try {
		yield put(CanvasUpdateRequestAction());

		const r = yield call(putPlaygroundCanvasUpdate, action.payload);
		if (r.json) {
			yield put(CanvasUpdateSuccessAction(r.json));
		} else {
			yield put(CanvasUpdateFailedAction("Failed to put playground update"));
		}
	} catch (e) {
		yield put(CanvasUpdateFailedAction(e));
	}
}

// Saga
export function * updatePlaygroundCanvasSaga () {
	yield takeLatest(CanvasUpdateAction.getType(), updatePlaygroundCanvasWorker);
}

// Export

export const updateSagas = [
	updatePlaygroundCanvasSaga()
];

export default updateSagas;
