import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "underscore";

import config, { CfgColors } from "../../../config";
import { InPlaceInput } from "../../common/form/InPlaceInput";

import "./GroupSettings.scss";

const colourCfg = config.get(CfgColors);

// Validation

const groupNameRe = /^[a-z]+[a-z0-9-]*$/;

export const validateGroupName = (env) => {
	const validPrefix = env + "-";

	return (val) => {
		if (isEmpty(val) || val === "") {
			return "Name cannot be empty";
		}

		if (val.toLowerCase() !== val) {
			return "Name cannot be uppercase";
		}

		if (!groupNameRe.test(val)) {
			return "Name is invalid (must contain a-z, 0-9 or -)";
		}

		if (!val.startsWith(validPrefix)) {
			return "Name must start with " + validPrefix;
		}

		return null;
	};
};

// GroupSettings

export const GroupSettings = ({
	group, env, scope, path, isLocked, openConfirm, doUpdateEnvironment, doDeleteEnvironment,
	fetchEnvironments
}) => {
	if (scope !== group.scope) {
		return "";
	}

	const handleSaveName = (name) => {
		doUpdateEnvironment({
			model: {
				...group,
				scope: scope,
				newName: name
			},
			onSuccess: () => {
				fetchEnvironments();
			}
		});
	};

	const handleDelete = () => {
		openConfirm({
			title: "Delete Environment?",
			confirmLabel: "Delete",
			headBgColor: colourCfg.red,
			fontColor: colourCfg.white,
			content: "Are you sure you want to delete this environment? This action is not reversible and all the variables " +
				"defined within it (if any) will be lost.",
			onConfirm: () => {
				doDeleteEnvironment({
					model: {
						scope: scope,
						name: group.name
					},
					onSuccess: () => {
						fetchEnvironments();
					}
				});
			}
		});
	};

	return (
		<div className="group-settings">
			<div className="group-settings-fields">
				<div className="field">
					<label className="label">Name</label>
					<div className="control group-name">
						<InPlaceInput
							value={group.name}
							onSave={handleSaveName}
							editDisabled={isLocked}
							validate={validateGroupName(env)}
						/>
					</div>
				</div>
			</div>
			<div className="group-settings-actions">
				<button className="button" onClick={handleDelete}>
          <span className="icon is-small">
            <i className="fas fa-trash"/>
          </span>
				</button>
			</div>
		</div>
	);
};

GroupSettings.propTypes = {
	group: PropTypes.object.isRequired,
	env: PropTypes.string.isRequired,
	scope: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	isLocked: PropTypes.bool,
	doUpdateEnvironment: PropTypes.func.isRequired,
	doDeleteEnvironment: PropTypes.func.isRequired,
	fetchEnvironments: PropTypes.func.isRequired
};
