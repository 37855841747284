import {
	CloseEditEnvironmentPathModalAction,
	OpenEditEnvironmentPathModalAction
} from "../../../../actions/data/environment/path/edit";

export const editReducer = (state = {}, action) => {
	switch (action.type) {
		case OpenEditEnvironmentPathModalAction.getType():
			return { open: true, ...action.payload };
		case CloseEditEnvironmentPathModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
