import {useQuery} from "react-query";
import {
    ITemplateDescriptor,
    ITemplateDescriptorMap,
    TemplateDispatchApi,
    useTemplateDispatchApi
} from "../../../../sections/editors/api";
import {connect} from "react-redux";
import {Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton} from "@mui/material";
import {capitalizeFirstLetter} from "../../../../util/strings";
import {TemplateKind} from "../../../../sections/editors/types";
import {FieldDataProvider, FormField, getFieldDataProvider} from "./formtypes";
import {FormikProps} from "formik";
import * as Yup from "yup";

import "./TemplatePickerField.scss";
import {useEffect, useState} from "react";
import classNames from "classnames";
import {Profile} from "../../../../store";

type Bindings = { [templateVariable: string]: string | null; };

type Data = {
    templateId: string;
    bindings: Bindings;
}

type PlaygroundVariable = { name: string }
type PlaygroundDashboard = { loading: true } | {
    loading: false,
    playground: {
        settings: {
            variables: PlaygroundVariable[],
        }
    }
};

interface ITemplatePickerInternalProps extends FormikProps<any> {
    field: FormField;
    kind: TemplateKind;
    token: string | null;
    dashboard: PlaygroundDashboard;
}

interface ITemplatePickerSimplifiedProps {
    templates: ITemplateDescriptor[];
    kind: TemplateKind;
    data: FieldDataProvider<Data>;
    playgroundVariables: PlaygroundVariable[];
    error: PickerFieldError;
}

const TemplateVariablePill = (props: { name: string }) => {
    return <div className="variable-pill">
        <span className="brackets">{"{{"}</span>
        <span className="variable">{props.name}</span>
        <span className="brackets">{"}}"}</span>
    </div>;
};


export const getTemplatePickSchema = () => {
    return Yup.object().shape({

        templateId: Yup.string()
            .min(1, "Please select a template")
            .required("Please select a template"),

        bindings: Yup.object<Bindings>().test("bindings", "Please assign all required variables", (value: any) => {
            if(!value) return false;
            return Object.values(value).every(v => v !== null);
        })
    });
};

const VariableBinder = ({
                            templateVariable,
                            playgroundVariables,
                            assign,
                            initialValue,
                            showError
                        }: {
    templateVariable: string,
    initialValue: string | null,
    playgroundVariables: PlaygroundVariable[],
    showError: boolean,
    assign: (value: string) => void
}) => {
    return <>
        <div className={classNames({"variable-binder": true, "error": showError})}>
            <FormControl className="playground-variable-label binding">
                <Select
                    className="selector"
                    variant="outlined"
                    value={initialValue ?? ""}
                    onChange={(e: SelectChangeEvent) => {
                        assign(e.target.value);
                    }}>
                    {
                        playgroundVariables.map(variable =>
                            <MenuItem key={variable.name} value={variable.name}>{variable.name}</MenuItem>)
                    }
                </Select>
            </FormControl>

            <div className="arrow">
                <i className="fas fa-long-arrow-alt-right"/>
            </div>

            <div className="template-variable-label binding">
                <TemplateVariablePill name={templateVariable}/>
            </div>
        </div>

        {showError && <Alert severity="error">
			Please assign a value to this template variable</Alert>
        }
    </>;
};


function makeDataFor(template: ITemplateDescriptor): Data {

    const settings = template.publishedSettings as { variables: string[] };

    const bindings: Bindings = {};
    for (let variable of settings.variables)
        bindings[variable] = null;

    return {
        "templateId": template.templateId,
        "bindings": bindings
    };
}

const TemplatePicker = (props: ITemplatePickerSimplifiedProps) => {
    const {templates, kind, data, playgroundVariables, error} = props;

    useEffect(() => {
        if (data.get() === null && templates.length > 0)
            data.set(makeDataFor(templates[0]));
    });

    if (templates.length === 0) {
        return <Alert severity="warning">
            There are no published {kind} templates available.
            Create a new template first, publish it, and then try again.
        </Alert>;
    }

    const changeTemplate = (event: SelectChangeEvent) => {
        const {value: templateId} = event.target;
        const template = templates.find(it => it.templateId === templateId);
        if (!template)
            return;

        data.set(makeDataFor(template));
    };

    const spec = data.get() ?? makeDataFor(templates[0]);
    const requiredVariables = Object.keys(spec.bindings);

    function makeAssigner(variable: string) {
        return function (value: string) {
            spec.bindings[variable] = value;
            data.set(spec);
        };
    }

    let binders = <Alert severity="success">This template runs without variables.</Alert>;
    if (requiredVariables.length > 0) {
        const bindingError = error && error.bindings;
        binders = <div className="variable-binders">
            <div className="note">Assign data to template variables</div>
            {
                requiredVariables.map(variable =>
                    <VariableBinder
                        key={variable}
                        templateVariable={variable}
                        initialValue={spec.bindings[variable]}
                        assign={makeAssigner(variable)}
                        showError={!!bindingError && data.get()?.bindings[variable] === null}
                        playgroundVariables={playgroundVariables}
                    />)
            }
        </div>;
    }

    return <>
        <FormControl variant="filled" fullWidth>
            <InputLabel>Select {capitalizeFirstLetter(kind)} Template</InputLabel>
            <Select value={spec.templateId} label="Pick a template..." onChange={changeTemplate}>
                {
                    templates.map(template =>
                        <MenuItem key={template.templateId} value={template.templateId}>{template.name}</MenuItem>
                    )
                }
            </Select>
        </FormControl>
        {error && error.templateId ? <Alert severity="error">{error.templateId}</Alert> : null}

        {binders}
    </>;
};


type OutboundTemplatePickerProps = {
    templates: ITemplateDescriptorMap;
    kind: TemplateKind;

    templateId: string | null;
    onAssign: (template: ITemplateDescriptor | null) => void;
}

function suitableForInbound(template: ITemplateDescriptor): boolean {
    if (!template.published)
        return false;
    return true;

    // const settings = template.publishedSettings as { variables: string[] };
    // return settings.variables.length === 0;
}

const InboundTemplatePickerInternal = (props: OutboundTemplatePickerProps) => {

    const MISSING = "@@missing";

    const {kind, templates, onAssign} = props;
    const label = `Inbound ${capitalizeFirstLetter(kind)} Template`;
    const [templateId, setTemplateId] = useState<string>(props.templateId ?? MISSING);

    const changeTemplate = (templateId: string) => {
        if (templateId)
            setTemplateId(templateId);
        setTemplateId(MISSING);
    };

    const templateMenuItems = templates[kind].filter(suitableForInbound).map(template => {
        return <MenuItem
            key={template.templateId}
            onClick={() => changeTemplate(template.templateId)}
            value={template.templateId}>{template.name}
        </MenuItem>;
    });

    const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value as string;
        setTemplateId(value);

        if (value === MISSING) {
            onAssign(null);
        } else {
            const template = templates[kind].find(it => it.templateId === value);
            if (template)
                onAssign(template);
            else onAssign(null);
        }
    };

    return <div className="inline-template-picker-form">
        <FormControl variant="standard" className="form-control">
            <InputLabel>{label}</InputLabel>
            <Select label={label} value={templateId} onChange={handleChange}>
                <MenuItem value={MISSING}>
                    <em>Do not use as inbound for {kind}</em>
                </MenuItem>
                {templateMenuItems}
            </Select>
        </FormControl>
    </div>;
};

type PickerFieldError = {
    templateId?: string;
    bindings?: string;
}

const TemplatePickerDataProvider = (props: ITemplatePickerInternalProps) => {

    const {token, dashboard} = props;
    const listingQueryKey = "templates-all";
    const {isFetching, error, data} = useQuery(listingQueryKey, () => {
        const dispatcher = new TemplateDispatchApi(token);
        return dispatcher.getAllTemplates();
    });

    if (isFetching || dashboard.loading)
        return <Skeleton variant="text" className="select-placeholder"/>;

    if (error || !data) {
        return <Alert severity="error">
            An unknown error has occurred fetching template list.
        </Alert>;
    }

    const {field, values, setFieldValue} = props;
    const fieldData = getFieldDataProvider(field, setFieldValue, values);

    const {kind} = props;
    const templates = data[kind].filter(it => it.published);
    const playgroundVariables = dashboard.playground.settings.variables;

    const {errors} = props;
    const fieldError = errors[field.name] as PickerFieldError;

    return <TemplatePicker {...{
        templates,
        kind,
        data: fieldData,
        playgroundVariables,
        error: fieldError
    }}/>;
};

const TemplatePickerTokenProvider = (props: ITemplatePickerInternalProps) => {
    const {token} = props;
    if (!token)
        return <Alert severity="info">Authenticating...</Alert>;

    return <div className="template-picker">
        <TemplatePickerDataProvider {...props}/>
    </div>;
};


function mapStateToProps(state: Map<string, any>) {

    let mapping: any = {
        token: null,
        dashboard: {
            loading: true,
        }
    };

    const core = state.get("core");
    if (!core) return mapping;

    const profile = core.get("profile") as Profile;
    if (!profile) return mapping;

    mapping.token = profile.auth.token;
    mapping.dashboard = state.get("canvas")?.get("playground")?.get("dashboard");

    return mapping;
}

export const TemplatePickerField = connect(mapStateToProps)(TemplatePickerTokenProvider);


type OutboundTemplatePickerPropsExtended = { token: string } & OutboundTemplatePickerProps;

function OutboundTemplatePickerData(props: OutboundTemplatePickerPropsExtended) {
    const listingQueryKey = "templates-all";
    const templateApi = useTemplateDispatchApi();
    const {isFetching, error, data: templates} = useQuery(listingQueryKey, () => {
        return templateApi.getAllTemplates();
    }, {
        staleTime: 60_000
    });

    if (isFetching) {
        return <Skeleton animation="pulse" height={60}/>;
    }

    if (error || !templates) {
        return <Alert severity="error">
            An unknown error has occurred fetching template list.
        </Alert>;
    }

    return <div className="template-picker">
        <InboundTemplatePickerInternal {...props} templates={templates}></InboundTemplatePickerInternal>
    </div>;
}

function InboundTemplatePickerProviderInternal(props: OutboundTemplatePickerPropsExtended) {
    const {token} = props;
    if (!token)
        return <Alert severity="info">Authenticating...</Alert>;

    return <OutboundTemplatePickerData {...props}/>;
}

export const InboundTemplatePicker = connect(mapStateToProps)(InboundTemplatePickerProviderInternal) as any;


