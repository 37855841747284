import { apiFetch } from "../base";

export function * postLogin (payload) {
	const { requestElevation, ...model } = payload;

	const opts = {
		endpoint: "/auth/v1/auth/login",
		method: "POST",
		authenticated: false,
		body: JSON.stringify(model)
	};

	if (requestElevation) {
		opts.headers = {
			"UP-Allow-Super": true,
			"UP-Allow-Root": true
		};
	}

	return yield apiFetch(opts);
}
