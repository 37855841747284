// eslint-disable-next-line no-redeclare

import { NodeModelTypeTarget, NodeTypePlaceholder } from "../../../../../config/node";
import { addCanvasPhaseNodeTarget, replaceCanvasPhaseNodeTarget, unlinkCanvasPhaseNode } from "./update";
import { SetTargetModeCreate } from "../../../constants";
import { updateNodeLayout } from "./layout";

import { ContextMenuPositionVerticalDefault } from "core/components/common/context/ContextMenuContent";

export const setTargetAction = (
	nodeProps, nodeId
) => {
	return (type) => {
		const { nodeData, data, moduleData, playgroundProps } = nodeProps;
		const { dashboard, openSetTarget, updateCanvas } = playgroundProps;

		openSetTarget({
			data: data,
			nodeProps: nodeProps,
			nodeData: nodeData,
			moduleData: moduleData,
			onSetTarget: (target) => {
				const { playground } = dashboard;
				const { canvas, phase } = playgroundProps;

				updateCanvas({
					namespace: playground.namespace,
					playgroundId: playground.playground_id,
					canvasId: canvas.canvas_id,
					model: addCanvasPhaseNodeTarget(dashboard, canvas.canvas_id, phase.id,
						nodeId, type, target)
				});
			}
		});
	};
};

export const replaceTargetAction = (
	nodeProps
) => {
	return () => {
		const { playgroundProps } = nodeProps;
		const { dashboard, openNewNode, updateCanvas } = playgroundProps;

		openNewNode({
			onSelect: (payload) => {
				const { playground } = dashboard;
				const { canvas, phase } = playgroundProps;

				updateCanvas({
					namespace: playground.namespace,
					playgroundId: playground.playground_id,
					canvasId: canvas.canvas_id,
					model: replaceCanvasPhaseNodeTarget(dashboard, canvas.canvas_id, phase.id,
						nodeProps.id, {
							mode: SetTargetModeCreate,
							payload: payload
						})
				});
			}
		});
	};
};

export const editNodeAction = (
	nodeProps
) => {
	return () => {
		const { nodeData, data, moduleData, playgroundProps } = nodeProps;
		const { openEditNode } = playgroundProps;

		const payload = {
			data: data,
			nodeProps: nodeProps,
			nodeData: nodeData,
			moduleData: moduleData
		}
		openEditNode(payload);
	};
};

export const clickNodeAction = (
	nodeProps
) => {
	return () => {
		const { node, nodeData, linkData } = nodeProps;

		if (node.positionChanged) {
			// When moving we don't trigger the action.
			return;
		}

		if (nodeProps.type === NodeModelTypeTarget) {
			setTargetAction(nodeProps, nodeProps.parentNodeId)(linkData.type);
		} else if (nodeData.type === NodeTypePlaceholder) {
			replaceTargetAction(nodeProps)();
		} else {
			editNodeAction(nodeProps)();
		}
	};
};

export const clickNodeDeleteAction = (
	nodeProps
) => deleteNodeAction(nodeProps);

export const clickNodeContextMenuAction = (
	nodeProps, height, width, isMenuOpen, setIsMenuOpen
) => {
	return (e) => {
		const { node, nodeData, data, playgroundProps } = nodeProps;
		const { showContextMenu, hideContextMenu } = playgroundProps;

		if (nodeProps.type === NodeModelTypeTarget) {
			// not supported
			return;
		}

		const positionVertical = ContextMenuPositionVerticalDefault;
		let [eventY, eventX] = [];

		if (e) {
			eventX = e.clientX;
			eventY = e.clientY;
		} else {
			// not supported
			return;
		}

		if (isMenuOpen) {
			hideContextMenu();
		} else {
			const actions = [{
				label: "Replace Module",
				icon: "fad fa-exchange",
				onClick: replaceTargetAction(nodeProps)
			}, {
				label: "Delete Module",
				icon: "fad fa-trash",
				onClick: deleteNodeAction(nodeProps)
			}, {
				label: "Reset Layout",
				icon: "fad fa-border-inner",
				onClick: updateNodePositionAction(node, playgroundProps, data)
			}];

			if (nodeData.type !== NodeTypePlaceholder) {
				actions.push({
					label: "Settings",
					icon: "fad fa-cog",
					onClick: editNodeAction(nodeProps)
				});
			}

			showContextMenu({
				onClose: () => {
					setIsMenuOpen(false);
				},
				actions: actions,
				eventY: eventY,
				eventX: eventX,
				positionVertical: positionVertical,
				itemActiveColor: (nodeData.type !== NodeTypePlaceholder)
					? nodeProps.fillHover
					: nodeProps.fill,
				itemActiveFontColor: (nodeData.type !== NodeTypePlaceholder)
					? nodeProps.iconFillHover
					: nodeProps.iconFill
			});
		}

		setIsMenuOpen(!isMenuOpen);
	};
};

export const updateNodePositionAction = (node, playgroundProps, data) => {
	return (position) => {
		const { dashboard, canvas, phase, updateCanvas } = playgroundProps;
		const { playground } = dashboard;

		const layout = (data.layout) ? data.layout : {};
		const modelsLayout = (layout.models) ? layout.models : {};

		let curLayout = modelsLayout[node.getID()];
		if (!curLayout) {
			curLayout = {};
		}

		curLayout.position = position;

		updateCanvas({
			namespace: playground.namespace,
			playgroundId: playground.playground_id,
			canvasId: canvas.canvas_id,
			model: updateNodeLayout(dashboard, canvas.canvas_id,
				phase.id, node.getID(), curLayout)
		});
	};
};

export const deleteNodeAction = (
	nodeProps
) => {
	return () => {
		const { playgroundProps, node } = nodeProps;
		const { dashboard, canvas, phase, updateCanvas } = playgroundProps;

		const canvasId = canvas.canvas_id;
		const phaseId = phase.id;
		const nodeId = node.options.id;

		updateCanvas({
			namespace: dashboard.playground.namespace,
			playgroundId: dashboard.playground.playground_id,
			canvasId: canvas.canvas_id,
			model: unlinkCanvasPhaseNode(dashboard, canvasId, phaseId, nodeId)
		});
	};
};
