import { call, put, takeLatest } from "redux-saga/effects";
import {
	EnvironmentDeleteAction,
	EnvironmentDeleteFailedAction,
	EnvironmentDeleteRequestAction,
	EnvironmentDeleteSuccessAction
} from "../../../actions/data/environment/delete";
import { deleteEnvironment } from "../../../api/data/environment/delete";

/// Environment
// Worker
function * deleteEnvironmentWorker (action) {
	try {
		yield put(EnvironmentDeleteRequestAction());

		const { onSuccess, ...payload } = action.payload;

		const r = yield call(deleteEnvironment, payload);
		if (r.json) {
			if (onSuccess) {
				yield onSuccess(r.json);
			}

			yield put(EnvironmentDeleteSuccessAction(r.json));
		} else {
			yield put(EnvironmentDeleteFailedAction("Failed to delete environment"));
		}
	} catch (e) {
		yield put(EnvironmentDeleteFailedAction(e));
	}
}

// Saga
export function * deleteEnvironmentSaga () {
	yield takeLatest(EnvironmentDeleteAction.getType(), deleteEnvironmentWorker);
}

// Export

export const deleteSagas = [
	deleteEnvironmentSaga()
];

export default deleteSagas;
