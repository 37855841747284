import React, { Component } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import InlineError from "core/components/common/message/InlineError";
import Tabs, { ErrorSection, Tab } from "core/components/common/tabs/Tabs";
import Loading from "components/common/loading/Loading";
import BasicFields, { getBasicInitialValues, getBasicYupSchema } from "./basic/BasicFields";
import LogicConditionBuilderFields, {
	BuilderTypeSimple,
	extractConditionBuilderForm,
	getConditionBuilderInitialValues
} from "core/components/common/logic/condition/builder/LogicConditionBuilderFields";

const topLevelVariables = [
	"name",
	"enabled",
	"condition"
];

const FilterEditMessages = ({ error }) => {
	if (error) {
		return (
			<InlineError>
				<p>There was an error processing your request, please try again.</p>
			</InlineError>
		);
	}

	return "";
};

const FilterEditFormSchema = Yup.object().shape({
	...getBasicYupSchema()
});

const applyModelToForm = (model) => {
	const form = {};

	const cloneModel = cloneDeep(model);

	// Copy top level
	topLevelVariables.forEach(v => {
		const val = cloneModel[v];
		if (val !== undefined && val !== null) {
			form[v] = val;
		}
	});

	return form;
};

const applyFormToModel = (conditionBuilderType, form, model) => {
	const cloneForm = cloneDeep(form);
	const newModel = cloneDeep(model);

	// Copy top level
	topLevelVariables.forEach(v => {
		if (cloneForm[v] !== undefined && cloneForm[v] !== null) {
			newModel[v] = cloneForm[v];
		}

		delete (cloneForm[v]);
	});

	if (form.condition) {
		newModel.condition = extractConditionBuilderForm(conditionBuilderType, form.condition);
	}

	return newModel;
};

export class FilterEditForm extends Component {
	state = {
		conditionBuilderType: undefined
	};

	setConditionBuilderType = (conditionBuilderType) => {
		this.setState(() => ({
			conditionBuilderType: conditionBuilderType
		}));

		return false;
	};

	render () {
		let { editState, onSubmit, buttonBgColor, buttonFontColor, buttonLabel } = this.props;
		let { model } = editState;
		if (!model) {
			model = {};
		}
		if (!buttonLabel) {
			buttonLabel = "Save";
		}

		const loading = editState.loading;
		const error = editState.error;

		let { conditionBuilderType } = this.state;
		if (!conditionBuilderType) {
			conditionBuilderType = BuilderTypeSimple;
		}

		const formData = applyModelToForm(model);

		const onSubmitAdaptor = (form) => {
			return onSubmit(applyFormToModel(conditionBuilderType, form, model));
		};

		return (
			<Formik
				onSubmit={onSubmitAdaptor}
				initialValues={{
					...getBasicInitialValues(formData),
					condition: getConditionBuilderInitialValues(conditionBuilderType, formData.condition)
				}}
				validationSchema={FilterEditFormSchema}
			>
				{({ errors, touched, ...props }) => (
					<Form className="up-form with-tabs">
						<section className="modal-card-body">
							{loading
								? <Loading/>
								: (
									<>
										<Tabs errors={errors} touched={touched} borderColor={buttonBgColor} {...props}>
											<ErrorSection>
												<FilterEditMessages error={error}/>
											</ErrorSection>
											<Tab id="basic" title="Setup" fields={["name", "group_id"]}>
												<BasicFields errors={errors} touched={touched} {...props} />
											</Tab>
											<Tab id="condition" title="Condition" fields={["condition"]}
												 isNested>
												<LogicConditionBuilderFields
													namePath="condition"
													builderType={conditionBuilderType}
													setBuilderType={this.setConditionBuilderType}
													borderColor={buttonBgColor}
													errors={errors} touched={touched} {...props}
												/>
											</Tab>
										</Tabs>
									</>
								)}
						</section>
						<footer className="modal-card-foot">
							<button
								type="submit" className="button is-primary" disabled={loading} style={{
								backgroundColor: buttonBgColor,
								color: buttonFontColor
							}}
							>{buttonLabel}
							</button>
						</footer>
					</Form>
				)}
			</Formik>
		);
	}
}

FilterEditForm.propTypes = {
	editState: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	buttonBgColor: PropTypes.string.isRequired,
	buttonFontColor: PropTypes.string.isRequired
};

export default FilterEditForm;
