import { apiFetch } from "../../../base";
import { getNamespace } from "../../../../lib/auth/token";
import { EnvironmentScopeNamespace } from "../../../../lib/constants";

export function * getEnvironmentVariableQuery (payload) {
	const { model } = payload;
	let { scope, environmentName, path } = model;

	const namespace = getNamespace();

	if (!environmentName) {
		environmentName = "*";
	}

	return yield apiFetch({
		endpoint: `/data/v1/environment/${namespace}/${environmentName}/variable/${path}:`,
		method: "GET",
		authenticated: true,
		withoutAccount: (scope === EnvironmentScopeNamespace)
	});
}
