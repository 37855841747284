import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import classNames from "classnames";
import { isEmpty } from "underscore";

import config, { CfgColors } from "../../../config";
import { InPlaceInput } from "../../common/form/InPlaceInput";
import { BoolIcon } from "../../common/form/InputField";
import { InputField } from "../../common/form";

import "./GroupVariables.scss";

const colorCfg = config.get(CfgColors);

// Validation

const variableNameRe = /^[A-Z_]+[A-Z0-9_]*$/;

const validateVariableName = (val) => {
	if (isEmpty(val)) {
		return "Name cannot be empty";
	}

	if (val.toUpperCase() !== val) {
		return "Name cannot be lowercase";
	}

	if (!variableNameRe.test(val)) {
		return "Name is invalid (must contain A-Z, 0-9 or _)";
	}

	return null;
};

const validateVariableValue = (val) => {
	if (isEmpty(val)) {
		return "Value cannot be empty";
	}

	return null;
};

const groupVariableSchema = Yup.object().shape({
	value: Yup.string()
		.required("Value is required")
		.test("NameValidation", "Name is invalid", function () {
			const err = validateVariableValue(this.parent.value);
			if (err) {
				return this.createError({
					message: err,
					path: "value"
				});
			}

			return true;
		}),
	name: Yup.string()
		.required("Name is required")
		.test("NameValidation", "Name is invalid", function () {
			const err = validateVariableName(this.parent.name);
			if (err) {
				return this.createError({
					message: err,
					path: "name"
				});
			}

			return true;
		})
});

// GroupVariable

const extractGroupVariables = (group) => {
	const variables = (group.variables) ? group.variables : {};
	const sorted = Object.values(variables);

	sorted.sort((a, b) => {
		const aName = a.name || "";
		const bName = b.name || "";

		if (aName < bName) {
			return -1;
		}
		if (aName > bName) {
			return 1;
		}

		return 0;
	});

	return sorted;
};

const GroupVariable = ({
	variable, group, env, path, scope, isLocked, doUpsertVariable, doDeleteVariable,
	fetchEnvironments
}) => {
	let haveLocalEdit = false;
	if (variable.paths) {
		variable.paths.forEach(vp => {
			if (vp === path) {
				haveLocalEdit = true;
			}
		});
	}

	const settings = variable.settings || {};
	const hasEdit = haveLocalEdit && variable.paths && variable.paths.length > 1;
	const isLocal = !hasEdit && variable.path === path;
	const isCurLocked = settings.locked || isLocked;
	const isParentLocked = (isLocked || settings.parentLocked || (!isLocal && settings.locked));
	const lockedColor = isParentLocked ? colorCfg.purple : null;

	const updateField = (changes) => {
		doUpsertVariable({
			model: {
				environmentName: group.name,
				scope: scope,
				path: path,
				name: variable.name,
				...changes
			},
			onSuccess: () => {
				fetchEnvironments();
			}
		});
	};

	const handleSaveName = (newName) => {
		updateField({ newName: newName });
	};

	const handleSaveValue = (newValue) => {
		updateField({ value: newValue });
	};

	const handleDelete = () => {
		doDeleteVariable({
			model: {
				environmentName: group.name,
				scope: scope,
				path: path,
				name: variable.name
			},
			onSuccess: () => {
				fetchEnvironments();
			}
		});
	};

	let handleLockChange = null;
	if (isLocal && !isParentLocked) {
		handleLockChange = () => {
			updateField({
				settings: {
					locked: !settings.locked
				}
			});
		};
	}

	return (
		<div className={classNames(
			"group-variable",
			{ "is-inherited": !isLocal },
			{ "has-edit": hasEdit }
		)}
		>
			<div className="field-name">
				<InPlaceInput
					key={variable.version_id}
					value={variable.name}
					onSave={handleSaveName}
					editDisabled={isCurLocked || !isLocal}
					validate={validateVariableName}
				/>
			</div>
			<div className="field-value">
				<InPlaceInput
					key={variable.version_id}
					value={variable.value}
					isSecret={settings.secret}
					onSave={handleSaveValue}
					editDisabled={isCurLocked}
					validate={validateVariableValue}
				/>
			</div>
			<div className="field-secret">
				<BoolIcon
					value={settings.secret}
					editDisabled
				/>
			</div>
			<div className="field-locked">
				<BoolIcon
					value={isCurLocked}
					color={lockedColor}
					editDisabled={isLocked}
					onClick={handleLockChange}
				/>
			</div>
			<div className="field-actions">
				{(isCurLocked || (!isLocal && !hasEdit))
					? (
						<button
							type="submit"
							className="button is-danger is-light disabled-cursor"
							style={{ color: "#FFF" }}
						>
              <span className="icon is-small">
                <i className="fa fa-trash"/>
              </span>
						</button>
					)
					: (
						<button
							key={hasEdit ? "clear" : "delete"}
							type="submit"
							className={classNames(
								"button",
								{ "is-danger": !hasEdit },
								{ "is-warning": hasEdit }
							)}
							onClick={handleDelete}
						>
              <span className="icon is-small">
                <i className={classNames(
					"fa",
					{ "fa-trash": !hasEdit },
					{ "fa-trash-undo-alt": hasEdit }
				)}
				/>
              </span>
						</button>
					)}
			</div>
		</div>
	);
};

GroupVariable.propTypes = {
	variable: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	env: PropTypes.string.isRequired,
	scope: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	isLocked: PropTypes.bool,
	doUpsertVariable: PropTypes.func.isRequired,
	doDeleteVariable: PropTypes.func.isRequired,
	fetchEnvironments: PropTypes.func.isRequired
};

// GroupVariables

export const GroupVariables = ({
	group, env, scope, path, isLocked, doUpsertVariable, doDeleteVariable, fetchEnvironments, update
}) => {
	const onSubmit = (form) => {
		doUpsertVariable({
			model: {
				environmentName: group.name,
				scope: scope,
				path: path,
				name: form.name,
				newName: form.name, // Required to pass the name.
				value: form.value,
				settings: {
					secret: form.secret,
					locked: form.locked
				}
			},
			onSuccess: () => {
				fetchEnvironments();
			}
		});
	};

	const variables = extractGroupVariables(group);

	return (
		<>
			<div className="group-variables">
				<Formik
					key={update}
					onSubmit={onSubmit}
					initialValues={{
						name: "",
						value: "",
						secret: true,
						locked: false
					}}
					validationSchema={groupVariableSchema}
				>
					{({
						errors, touched, values,
						...props
					}) => (
						<Form className="up-form with-tabs">
							<div className={classNames(
								"new-variable-headers",
								{ "group-locked": isLocked }
							)}
							>
								<div className="padding">
									Variables
								</div>
								<div className="field-secret">
									Secret
								</div>
								<div className="field-locked">
									Locked
								</div>
								<div className="field-actions"/>
							</div>

							{!isLocked && (
								<div className="new-variable">
									<div className="field-name">
										<InputField
											name="name" touched={touched} errors={errors} values={values}
											type="text" placeholder="Name (i.e. MY_VARIABLE)" {...props}
										/>
									</div>
									<div className="field-value">
										<InputField
											name="value" touched={touched} errors={errors} values={values}
											type={values.secret ? "secret" : "text"} placeholder="Value" {...props}
										/>
									</div>
									<div className="field-secret">
										<InputField
											name="secret" touched={touched} errors={errors} values={values}
											type="bool" {...props}
										/>
									</div>
									<div className="field-locked">
										<InputField
											name="locked" touched={touched} errors={errors} values={values}
											disabled={isLocked} type="bool" {...props}
										/>
									</div>
									<div className="field-actions">
										<button type="submit" className="button is-success">
                      <span className="icon is-small">
                        <i className="fa fa-plus"/>
                      </span>
										</button>
									</div>
								</div>
							)}
						</Form>
					)}
				</Formik>
			</div>

			{(variables && variables.length > 0) && (
				<div className="group-variable-items">
					{variables.map(v => {
						return (
							<GroupVariable
								key={v.version_id}
								env={env}
								scope={scope}
								path={path}
								group={group}
								variable={v}
								isLocked={isLocked}
								doUpsertVariable={doUpsertVariable}
								doDeleteVariable={doDeleteVariable}
								fetchEnvironments={fetchEnvironments}
							/>
						);
					})}
				</div>
			)}
		</>
	);
};

GroupVariables.propTypes = {
	group: PropTypes.object.isRequired,
	env: PropTypes.string.isRequired,
	scope: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	isLocked: PropTypes.bool,
	doUpsertVariable: PropTypes.func.isRequired,
	doDeleteVariable: PropTypes.func.isRequired,
	fetchEnvironments: PropTypes.func.isRequired,
	update: PropTypes.number.isRequired
};
