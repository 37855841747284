import React from "react";
import { ImageContainer, PageBuilderComponent } from "./PageBuilderComponent";
import { Droppable } from "react-beautiful-dnd";

class InnerList extends React.PureComponent {
	render () {
		const { components, dragging } = this.props;
		return components.map((component, index) => (
			<PageBuilderComponent
				key={component.id}
				index={index}
				component={component}
				dragging={component.id === dragging}
			/>
		));
	}
}

export class PageBuilderComponentGroup extends React.PureComponent {
	render () {
		const { group } = this.props;

		let groups = [];
		if (group.components.length > 1) {
			const mid = Math.ceil((group.components.length / 2));
			groups.push(group.components.slice(0, mid));
			groups.push(group.components.slice(mid, group.components.length));
		} else {
			groups = [group.components];
		}

		const clone = (group, provided, snapshot, rubric) => {
			const item = groups[group][rubric.source.index];

			return (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={provided.draggableProps.style}
					className={snapshot.isDragging ? "dragging" : ""}
				>
					<ImageContainer image={item.image}/>
				</div>
			);
		};

		return (
			<div className="up-pb-component-list-group-wrapper">
				{groups.map((components, i) => (
					<Droppable
						key={"component-" + i}
						renderClone={(provided, snapshot, rubric) => clone(i, provided, snapshot, rubric)}
						droppableId={`${group.id}-${i}`}
						isDropDisabled
						type={group.type || "ELEMENT"}
					>
						{(provided, snapshot) => (
							<div ref={provided.innerRef} className="up-pb-component-list-group">
								<InnerList components={components} dragging={snapshot.draggingFromThisWith}/>
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				))}
			</div>
		);
	}
}
