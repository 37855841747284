import classNames from "classnames";
import React from "react";
import "./chit.scss";

type ChitProps = { text: React.ReactNode, size?: "tiny" | "normal", className?: string };

export function Chit({
                         text,
                         size = "tiny",
                         className = "",
                     }: ChitProps) {
    return <div className={classNames("chit", size, className)}>
        {text}
    </div>;

}
