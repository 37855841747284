import {FC, PropsWithChildren} from "react";
import {ScreenContextProvider} from "./ScreenContextProvider";
import {UserContextProvider} from "./UserContextProvider";
import {UpwireThemeProvider} from "../theme";
import {QueryClient, QueryClientProvider} from "react-query";

const combineComponents = (...components: FC[]): FC => {
    return components.reduce((AccumulatedComponents: any, CurrentComponent: any) => {
            return ({children}: any): any => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
        },
        ({children}) => <>{children}</>,
    );
};

const queryClient = new QueryClient();

function UpwireQueryClientProvider({children}: PropsWithChildren<FC>) {
    return <QueryClientProvider client={queryClient}>
        {children}
    </QueryClientProvider>;
}

const providers: any[] = [
    ScreenContextProvider,
    UserContextProvider,
    UpwireThemeProvider,
    UpwireQueryClientProvider,
];


export const AppContextProvider: FC = combineComponents(...providers);

