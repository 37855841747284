import { clearToken, setAccountKey, setNamespace, setToken } from "../../lib/auth/token";
import {
	FetchProfileAction,
	FetchProfileFailedAction,
	FetchProfileSuccessAction,
	LoginAction,
	LoginFailedAction,
	LoginSuccessAction,
	LogoutAction,
	LogoutAndElevateAction,
	TokenRefreshSuccessAction
} from "../../actions/auth";
import { registerLoadingActions } from "../loading";

registerLoadingActions(
	[FetchProfileAction.getType(), LoginAction.getType()],
	[FetchProfileSuccessAction.getType(), FetchProfileFailedAction.getType(), LoginSuccessAction.getType(), LoginFailedAction.getType()]
);

const initialState = { "loading": true };

export const profileReducer = (state = initialState, action) => {

	let loggedIn = false;
	let token = null;

	switch (action.type) {
		case LoginAction.getType():
			return { ...state, loading: true };
		case LoginSuccessAction.getType():
		case FetchProfileSuccessAction.getType():
			if (action.payload) {
				if (action.payload.auth && action.payload.auth.token) {
					loggedIn = true;
					setToken(action.payload.auth.token);
				}

				if (action.payload.account) {
					if (action.payload.account.namespace) {
						setNamespace(action.payload.account.namespace);
					}
					if (action.payload.account.account_key) {
						setAccountKey(action.payload.account.account_key);
					}
				}
			}

			return { ...state, loading: false, loggedIn: loggedIn, ...action.payload };
		case LoginFailedAction.getType():
			return { ...state, loading: false };
		case LogoutAction.getType():
			clearToken();
			return { loggedIn: false, loading: false };
		case LogoutAndElevateAction.getType():
			clearToken();
			return { loggedIn: false, requestElevation: true, loading: false };
		case FetchProfileAction.getType():
			return { ...state, loading: true };
		case FetchProfileFailedAction.getType():
			return { ...state, loading: false };
		case TokenRefreshSuccessAction.getType():
			console.log("Token refresh success");
			if (action.payload && action.payload.token) {
				loggedIn = true;
				token = action.payload.token;
				setToken(token);
			}

			return { ...state, loggedIn: loggedIn, auth: { token: token } };
		default:
			return state;
	}
};
