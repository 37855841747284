import { put, takeLatest } from "redux-saga/effects";

import { OpenEditEnvironmentPathModalAction } from "../../../../actions/data/environment/path/edit";
import { EnvironmentVariableQueryAction } from "../../../../actions/data/environment/variable/query";
import { EnvironmentScopeAccount } from "../../../../lib/constants";

/// Edit Environment Variable Path
// Worker
function * openEditEnvironmentPathWorker (action) {
	yield put(EnvironmentVariableQueryAction({
		model: {
			scope: EnvironmentScopeAccount,
			path: action.payload.path
		}
	}));
}

// Saga
export function * openEditEnvironmentPathSaga () {
	yield takeLatest(OpenEditEnvironmentPathModalAction.getType(), openEditEnvironmentPathWorker);
}

// Export

export const editSagas = [
	openEditEnvironmentPathSaga()
];

export default editSagas;
