import React from "react";

import PageBuilder from "./containers/pagebuilder/PageBuilder.container";
import PrivateRoute from "core/components/layout/route/PrivateRoute";

export const PagesRoutes = ({ profile, setFullscreen, isFullscreen }) => [
	<PrivateRoute
		key="pages" exact path="/pages" component={PageBuilder}
		profile={profile} setFullscreen={setFullscreen} isFullscreen={isFullscreen}
	/>
];

export default PagesRoutes;
