import React from "react";
import * as Yup from "yup";
import cloneDeep from "lodash/cloneDeep";

import { defaultNodeBranching } from "../../../../../../../../../lib/playground/canvas/phase/node/init";
import BranchingOutcome, { getOutcomeYupSchema } from "./BranchingOutcome";
import Tabs, { Tab } from "core/components/common/tabs/Tabs";
import { capitalizeFirstLetter } from "core/lib/variable/keys";
import { getNodeBranchingOutcomes } from "../../../../../../../../../config/node/util";

export const getBranchingInitialValues = (model, nodeProps) => {
	if (model.branching) {
		return cloneDeep(model.branching);
	}

	const defaults = {};

	getNodeBranchingOutcomes(nodeProps).forEach(o => {
		defaults[o] = defaultNodeBranching();
	});

	return defaults;
};

export const getBranchingYupSchema = (nodeProps) => {
	const schema = {};

	getNodeBranchingOutcomes(nodeProps).forEach(o => {
		schema[o] = getOutcomeYupSchema();
	});

	return Yup.object().shape(schema);
};

export const getBranchingTabs = (playgroundProps, nodeProps, nodeInfoCbRef, setModalHidden) => {
	return (
		<Tab
			id="branching"
			key="branching"
			icon="fad fa-random"
			title="Branching"
			fields={["branching"]}
			position={15}
			isNested
		>
			<Tabs>
				{getNodeBranchingOutcomes(nodeProps).map(outcome => (
					<Tab
						id={"branching-" + outcome}
						key={"branching-" + outcome}
						title={capitalizeFirstLetter(outcome)}
						fields={[`branching.${outcome}`]}
						className="no-padding"
					>
						<BranchingOutcome
							outcome={outcome}
							nodeProps={nodeProps}
							playgroundProps={playgroundProps}
							nodeInfoCbRef={nodeInfoCbRef}
							setModalHidden={setModalHidden}
						/>
					</Tab>
				))}
			</Tabs>
		</Tab>
	);
};
