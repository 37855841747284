import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isFirefox, isIE } from "react-device-detect";

import "./InPlaceInput.scss";

export class InPlaceInput extends Component {
	constructor (props) {
		super(props);

		const { value, isSecret } = props;

		this.state = {
			currentValue: isSecret ? "" : value,
			editing: false,
			error: null
		};

		this.inputRef = React.createRef();

		this.handleChange = this.handleChange.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.resetState = this.resetState.bind(this);
	}

	setCurrentValue = (val) => {
		const { editDisabled } = this.props;

		if (editDisabled) {
			return false;
		}

		this.setState(() => ({
			currentValue: val
		}));

		return false;
	};

	resetState = () => {
		const { value, isSecret } = this.props;

		let currentVal = value;
		if (isSecret) {
			currentVal = "";
		}

		this.setCurrentValue(currentVal);

		this.setState(() => ({
			error: null
		}));
	};

	setEditing = (val) => {
		const { editDisabled } = this.props;
		const { editing } = this.state;

		if (!val) {
			this.resetState();
		}

		if (editing === val || editDisabled) {
			return false;
		}

		this.setState(() => ({
			editing: val
		}));

		return false;
	};

	handleChange = (e) => {
		this.setCurrentValue(e.target.value);
	};

	handleCancel = (e) => {
		if (e) {
			e.preventDefault();
		}

		this.setEditing(false);

		return false;
	};

	handleSave = (e) => {
		const { value, isSecret, editDisabled, validate, onSave } = this.props;
		const { currentValue } = this.state;

		e.preventDefault();

		// Short circuit
		if (editDisabled || (!isSecret && value === currentValue)) {
			this.setEditing(false);

			return false;
		}

		if (validate) {
			const err = validate(currentValue);
			if (err) {
				this.setState(() => ({
					error: err
				}));

				return false;
			}
		}

		onSave(currentValue);
		this.setEditing(false);

		if (isSecret) {
			this.setCurrentValue("");
		}

		return false;
	};

	render () {
		let { placeholder, type, isSecret, editDisabled } = this.props;
		let { currentValue, editing, error } = this.state;

		if (!type) {
			type = "text";
		}
		if (isSecret) {
			if (isFirefox || isIE) {
				type = "password";
			} else {
				type = "text";
			}

			if (!editing) {
				currentValue = "";
				placeholder = "■■■■■■■■■■■";
			}
		}

		return (
			<form className={classNames(
				"in-place-input",
				{ editing: editing },
				{ "edit-disabled": editDisabled },
				{ "text-selection-none": editDisabled },
				{ "is-secret": isSecret }
			)}
			>
				<input
					ref={this.inputRef}
					type={type}
					className={classNames(
						"input"
					)}
					placeholder={placeholder}
					value={currentValue}
					onChange={this.handleChange}
					onMouseDown={(e) => {
						if (editDisabled) {
							e.preventDefault();
							e.target.blur();

							return false;
						}

						this.setEditing(true);

						return true;
					}}
				/>
				{error && (
					<article className="message is-danger edit-errors">
						<div className="message-body">
							{error}
						</div>
					</article>
				)}
				<div className="edit-controls">
					<button
						type="button"
						className="button is-small is-info"
						onClick={this.handleCancel}
					>
            <span className="icon is-small">
              <i className="fa fa-times"/>
            </span>
					</button>
					<button
						type="button"
						className="button is-small is-info"
						onClick={this.handleSave}
					>
            <span className="icon is-small">
              <i className="fa fa-check"/>
            </span>
					</button>
				</div>
			</form>
		);
	}
}

InPlaceInput.propTypes = {
	onSave: PropTypes.func.isRequired,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	isSecret: PropTypes.bool,
	editDisabled: PropTypes.bool,
	validate: PropTypes.func
};
