import React from "react";

export default class Element extends React.PureComponent {
	static defaultProps = {
		renderAs: "div",
		classes: []
	};

	render () {
		const { renderAs, classes, children, innerRef } = this.props;

		return React.createElement(renderAs, {
			className: classes.join(" "),
			ref: innerRef
			// ...props
		}, children);
	}
}
