import {API_BASE_URL, FormatData, FormatterOutput} from "./formatter";

const header = `
curl --request POST \\
  --url |API_BASE|/|ENDPOINT| \\
  --header 'Authorization: Bearer |TOKEN|' \\
  --header 'Content-Type: application/json' \\
  --header 'UP-Account-Key: |ACCOUNT_KEY|' \\
  --data '|DATA|'`;


const ending = `

curl --request POST \\
  --url http://localhost:50000/api/templates \\
  --header 'Authorization: Bearer f11cf35b-2414-49b9-a12e-525f0c337f48' \\
  --header 'Content-Type: application/json' \\
  --data '{"action": "list"}'

curl --request POST \\
  --url https://forte.preview.upwire.com/api/templates \\
  --header 'Authorization: Bearer 75e24da8-b8ae-4a7f-88ec-1bc42b20a7a2 \\
  --header 'Content-Type: application/json' \\
  --data '{"action":"list"}'

`;

export function format_curl({payload, endpoint, accountKey, token}: FormatData): FormatterOutput {
    let code = header.replace(/\|DATA\|/, JSON.stringify(payload, null, 2));
    code = code.replace(/\|ENDPOINT\|/, endpoint);
    code = code.replace(/\|TOKEN\|/, token);
    code = code.replace(/\|API_BASE\|/, API_BASE_URL);
    code = code.replace(/\|ACCOUNT_KEY\|/, accountKey);

    const end = "}),";
    if (code.endsWith(end))
        code = code.substring(0, code.length - end.length) + "  })\n}";

    return {
        code: code,
        language: "curl"
    };
}
