import React from "react";
import { FieldArray } from "formik";
import * as Yup from "yup";
import { extractArrayErrors, extractErrorMessageFromArrayErrors } from "core/util/form/helper";
import { RawField } from "core/";

import "./VariableFields.scss";
import { DragAndDropVariableAnalyzer } from "../../../common/DragAndDropVariableAnalyzer/DragAndDropVariableAnalyzer";

export const VariableTypeString = "STRING";
export const VariableTypeNumber = "NUMBER";
export const VariableTypeDate = "DATE";

const variableTypeOptions = [
	{ value: VariableTypeString, label: "String" },
	{ value: VariableTypeNumber, label: "Number" },
	{ value: VariableTypeDate, label: "Date" }
];

const newEmptyVariable = (name = "") => {
	return {
		type: VariableTypeString,
		name: name,
		default: "",
		required: false
	};
};

export const getDataVariableInitialValues = (model) => {
	return (model.variables) ? model.variables : [];
};

export const getVariablesYupSchema = () => {
	return Yup.array()
		.of(Yup.object().shape({
				type: Yup.string().required("Variable type is required"),
				name: Yup.string().required("Variable name is required"),
				default: Yup.string().test("NameRequired", "Default value is invalid", function () {

					if (this.parent.default !== undefined && this.parent.default !== null) {
						// TODO: Add more variable validations
						switch (this.parent.type) {
							case VariableTypeNumber:
								if (isNaN(this.parent.default))
									return false;
								break;
							default:
						}
					}

					return true;
				})
			})
		);
};

const VariableField = ({ index, touched, values, errors, arrayHelpers, ...props }) => {
	const errorData = extractArrayErrors("variables", index, errors, touched);
	const errorMessage = extractErrorMessageFromArrayErrors(errorData);

	return (
		<div className="data-variable-field-container">
			<div className="data-variable-field">
				<div className="field-type">
					<RawField
						name={`variables[${index}].type`} values={values} touched={touched} errors={errors}
						hasError={!!errorData.type} type="select" placeholder="Select type"
						options={variableTypeOptions} {...props}
					/>
				</div>
				<div className="field-name">
					<RawField
						name={`variables[${index}].name`} values={values} touched={touched} errors={errors}
						hasError={!!errorData.name} type="text" placeholder="Variable name" {...props}
					/>
				</div>
				<div className="field-default">
					<RawField
						name={`variables[${index}].default`} values={values} touched={touched} errors={errors}
						hasError={!!errorData.default}
						type="text" placeholder="Default value" {...props}
					/>
				</div>
				<div className="field-required">
					<RawField
						name={`variables[${index}].required`} values={values} touched={touched} errors={errors}
						hasError={!!errorData.required} type="bool" {...props}
					/>
				</div>
				<div className="field-actions">
					<button type="button" className="button is-danger" onClick={() => arrayHelpers.remove(index)}>
            <span className="icon is-small">
              <i className="fa fa-trash"/>
            </span>
					</button>
				</div>
			</div>
			{errorMessage ? <p className="help is-danger">{errorMessage}</p> : ""}
		</div>
	);
};

const VariableFields = ({ values, ...props }) => {

	// NB. Push gets inited only once. It's super confusing.
	function push (ah, name = "") {
		ah.push(newEmptyVariable(name));
	}

	return (
		<FieldArray name="variables">
			{arrayHelpers => {

				return (
					<>
						{values.variables.length > 0
							? (
								<div className="data-variable-header-fields">
									<div className="padding"/>
									<div className="field-required">Required</div>
									<div className="field-actions"/>
								</div>
							)
							: ""}
						<div className="data-variable-fields">
							{values.variables.map((variable, index) => (
								<VariableField key={index} index={index} arrayHelpers={arrayHelpers}
											   values={values} {...props} />
							))}
						</div>
						<div className="data-variable-field-new">
							<div className="new-action-label">Add new variable</div>
							<div className="new-action-button">
								<button type="button" className="button is-success"
										onClick={() => push(arrayHelpers)}>
									<span className="icon is-small">
									  <i className="fa fa-plus-circle"/>
									</span>
								</button>
							</div>
						</div>

						<div className="drag-and-drop-variable-analyzer-container">
							<DragAndDropVariableAnalyzer addVariable={(name) => push(arrayHelpers, name)}/>
						</div>
					</>
				);
			}}
		</FieldArray>
	);
};

export default VariableFields;
