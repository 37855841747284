import { combineReducers } from "redux-immutable";

import { queryReducer } from "./query";
import { upsertReducer } from "./upsert";
import { deleteReducer } from "./delete";

export default combineReducers({
	query: queryReducer,
	upsert: upsertReducer,
	delete: deleteReducer
});
