import React, { Component } from "react";
import { Form, Formik } from "formik";
import InputField from "../../common/form/InputField";
import * as Yup from "yup";
import InlineError from "../../common/message/InlineError";
import InlineInfo from "../../common/message/InlineInfo";
import { ErrCodeUserNotFound } from "../../../errs";
import SendResetSuccess from "./SendResetSuccess";
import Loading from "../../../../components/common/loading/Loading";

const ResetFormSchema = Yup.object().shape({
	email: Yup.string()
		.required("Email is required.")
		.email("Email is invalid")
});

const SendResetMessage = ({ error, invalid }) => {
	if (invalid) {
		return (
			<InlineError title="Password Reset Failed :(" error={error}>
				<p>Something went wrong or the link you followed may be incorrect.
					To have another one sent to you please enter your email and click the action below.
				</p>
			</InlineError>
		);
	}

	if (error) {
		if (ErrCodeUserNotFound === error.error_code) {
			return (
				<InlineError error={error} title="Authentication Error">
					<p>The email address you entered doesn't appear to be linked to any account.
						Please verify it and try again.
					</p>
				</InlineError>
			);
		}

		return (
			<InlineError error={error} title="Internal Error">
				<p>Well this is embarrassing, it looks like we encountered an error while trying to
					send your reset link. Please try again later and contact support if the
					issue persists.
				</p>
			</InlineError>
		);
	}
	return (
		<InlineInfo>
			<p>
				We've all forgotten our password before!
				No worries, enter your email below and we'll send you a reset link.
			</p>
		</InlineInfo>
	);
};

class SendResetForm extends Component {
	render () {
		let { reset, closeModal, sendReset, isDialog } = this.props;
		if (!reset) {
			reset = {};
		}

		if (reset.success) {
			return (
				<SendResetSuccess
					closeModal={closeModal}
					isDialog={isDialog}
				/>
			);
		}

		const onSubmit = (form) => {
			sendReset(form);
		};

		return (
			<Formik
				onSubmit={onSubmit}
				initialValues={{
					email: reset.email || ""
				}}
				validationSchema={ResetFormSchema}
				render={({ setFieldValue, ...props }) => (
					<Form className="ed-form">
						<section className="modal-card-body">
							{!reset.loading
								? (
									<>
										<SendResetMessage error={reset.error} invalid={reset.invalid}/>

										<InputField
											name="email" label="Email"
											type="email" placeholder="Your email" {...props}
										/>
									</>
								)
								: <Loading/>}
						</section>
						<footer className="modal-card-foot">
							<button type="submit" className="button is-primary" disabled={reset.loading}>Reset
								Password
							</button>
							{!isDialog
								? <button type="button" className="button" onClick={closeModal}>Close</button>
								: ""}
						</footer>
					</Form>
				)}
			/>
		);
	}
}

export default SendResetForm;
