// eslint-disable-next-line no-redeclare
/* global performance */

import { registerLoadingActions } from "../../../loading";
import { registerErrorActions } from "../../../error";
import {
	EnvironmentVariableQueryAction,
	EnvironmentVariableQueryFailedAction,
	EnvironmentVariableQuerySuccessAction
} from "../../../../actions/data/environment/variable/query";

registerLoadingActions(
	[EnvironmentVariableQueryAction.getType()],
	[EnvironmentVariableQuerySuccessAction.getType(), EnvironmentVariableQueryFailedAction.getType()]
);

registerErrorActions([
	EnvironmentVariableQueryFailedAction.getType()
]);

export const queryReducer = (state = {}, action) => {
	switch (action.type) {
		case EnvironmentVariableQueryAction.getType():
			return { ...state, error: null, model: action.payload };
		case EnvironmentVariableQuerySuccessAction.getType():
			// Ensure we clear prior state.
			return { update: performance.now(), ...action.payload };
		case EnvironmentVariableQueryFailedAction.getType():
			return { ...state, error: action.payload };
		default:
			return state;
	}
};
