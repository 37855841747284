import { registerLoadingActions } from "../../../loading";
import { registerErrorActions } from "../../../error";
import {
	EnvironmentVariableUpsertAction,
	EnvironmentVariableUpsertFailedAction,
	EnvironmentVariableUpsertSuccessAction
} from "../../../../actions/data/environment/variable/upsert";

registerLoadingActions(
	[EnvironmentVariableUpsertAction.getType()],
	[EnvironmentVariableUpsertSuccessAction.getType(), EnvironmentVariableUpsertFailedAction.getType()]
);

registerErrorActions([
	EnvironmentVariableUpsertFailedAction.getType()
]);

export const upsertReducer = (state = {}, action) => {
	switch (action.type) {
		case EnvironmentVariableUpsertAction.getType():
			return { ...state, loading: true, error: null, model: action.payload };
		case EnvironmentVariableUpsertSuccessAction.getType():
			return { ...state, loading: false, ...action.payload };
		case EnvironmentVariableUpsertFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};
