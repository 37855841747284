import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { OpenNewEnvironmentModalAction } from "../../../actions/data/environment/new";
import WindowContainer from "../../../../components/common/window/WindowContainer";
import WindowContent from "../../../../components/common/window/WindowContent";
import EnvironmentsWindowContent from "../../../components/settings/environments/EnvironmentsWindowContent";
import { SetPreferenceAction } from "../../../actions/preferences";
import { EnvironmentVariableQueryAction } from "../../../actions/data/environment/variable/query";
import { EnvironmentScopeAccount } from "../../../lib/constants";
import { ConfirmOpenModalAction } from "../../../actions/info/confirm";
import { EnvironmentDeleteAction } from "../../../actions/data/environment/delete";
import { EnvironmentUpdateAction } from "../../../actions/data/environment/update";
import { EnvironmentVariableUpsertAction } from "../../../actions/data/environment/variable/upsert";
import { EnvironmentVariableDeleteAction } from "../../../actions/data/environment/variable/delete";
import { LogoutAndElevateAction } from "../../../actions/auth";

class Environments extends Component {
	componentDidMount () {
		this.props.setFullscreen(false);
		this.props.fetchEnvironments();
	}

	render () {
		const {
			environments, isFullscreen, openNewModal, doUpdateEnvironment,
			doDeleteEnvironment, doUpsertVariable, doDeleteVariable,
			preferences, setPreference, requestElevation, fetchEnvironments,
			openConfirm
		} = this.props;

		const error = environments ? environments.error : null;
		const loading = (!environments || environments.loading);
		const update = environments.update || 0;

		return (
			<WindowContainer isFullscreen={isFullscreen} isDiscrete={!error}>
				<WindowContent error={error} loading={loading}>
					<EnvironmentsWindowContent
						model={environments}
						openNewModal={openNewModal}
						doUpdateEnvironment={doUpdateEnvironment}
						doDeleteEnvironment={doDeleteEnvironment}
						doUpsertVariable={doUpsertVariable}
						doDeleteVariable={doDeleteVariable}
						preferences={preferences}
						setPreference={setPreference}
						requestElevation={requestElevation}
						fetchEnvironments={fetchEnvironments}
						openConfirm={openConfirm}
						update={update}
					/>
				</WindowContent>
			</WindowContainer>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		openNewModal: (payload) => dispatch(OpenNewEnvironmentModalAction(payload)),
		doUpdateEnvironment: (payload) => dispatch(EnvironmentUpdateAction(payload)),
		doDeleteEnvironment: (payload) => dispatch(EnvironmentDeleteAction(payload)),
		doUpsertVariable: (payload) => dispatch(EnvironmentVariableUpsertAction(payload)),
		doDeleteVariable: (payload) => dispatch(EnvironmentVariableDeleteAction(payload)),
		setPreference: (payload) => dispatch(SetPreferenceAction(payload)),
		requestElevation: (payload) => dispatch(LogoutAndElevateAction(payload)),
		fetchEnvironments: () => dispatch(EnvironmentVariableQueryAction({
			model: {
				scope: EnvironmentScopeAccount,
				path: EnvironmentScopeAccount
			}
		})),
		openConfirm: (payload) => dispatch(ConfirmOpenModalAction(payload))
	};
};

const mapStateToProps = (state) => {
	const core = state.get("core");
	const data = core.get("data");
	const environment = data.get("environment");
	const variable = environment.get("variable");

	return {
		core: core,
		preferences: core.get("preferences"),
		environments: variable.get("query")
	};
};

Environments.propTypes = {
	setFullscreen: PropTypes.func.isRequired,
	isFullscreen: PropTypes.bool
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Environments));
