import React from "react";
import "./PageBuilderComponent.scss";
import { Draggable } from "react-beautiful-dnd";

export class ImageContainer extends React.PureComponent {
	render () {
		const { image, innerRef, ...props } = this.props;
		return (
			<div ref={innerRef} {...props}>
				<div className="up-pb-component-list-component">
					<img src={image} alt=""/>
				</div>
			</div>
		);
	}
}

export class PageBuilderComponent extends React.PureComponent {
	render () {
		const { index, component, dragging } = this.props;

		return dragging
			? <ImageContainer image={component.image}/>
			: (
				<Draggable draggableId={component.id} index={index}>
					{(provided) => (
						<ImageContainer
							image={component.image}
							innerRef={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
						/>
					)}
				</Draggable>
			);
	}
}
