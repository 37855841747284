import { elements } from "./index";
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ColumnElement } from "./StructureElements";

export class Layout extends React.PureComponent {
	render () {
		const { def, index, setActiveElement, setValue } = this.props;

		if (!def.layout.def) {
			return <div>Uh oh, that layout has no definition</div>;
		}

		return (
			<Draggable draggableId={def.id} index={index} type="LAYOUT">
				{(provided) => (
					<section
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						className="section"
					>
						<PageBuilderComponent
							def={{ id: def.id, ...def.layout.def, props: def.props }} columns={def.columns}
							setActiveElement={setActiveElement} setValue={setValue}
						/>
					</section>
				)}
			</Draggable>
		);
	}
}

class PageBuilderComponent extends React.PureComponent {
	static defaultProps = {
		index: 0
	};

	render () {
		const { def, parentId, parentZone, index, columns, setActiveElement, setValue } = this.props;
		if (typeof def === "string") {
			return def;
		}

		// Is a droppable column
		if (def.type === "column" && typeof def.dropzone !== "undefined") {
			return (
				<DroppableColumnElement
					id={(parentId || def.id) + `-${def.dropzone}`}
					parentId={parentId || def.id}
					classes={def.classes}
					def={columns[def.dropzone] || {}}
					dropzone={def.dropzone}
					setActiveElement={setActiveElement}
					setValue={setValue}
				/>
			);
		}

		// Not a dropzone.. So it's either a row or a non-droppable column (which in turn would have row as child)
		const children = def.children ? Array.isArray(def.children) ? def.children : [def.children] : [];
		const element = typeof elements[def.type] !== "undefined"
			? elements[def.type]
			: "div";

		const key = ([parentId, def.id, index]).join("-");
		return React.createElement(
			element,
			{
				...def.props,
				index,
				id: def.id,
				parentId,
				parentZone,
				setActiveElement,
				setValue: (value) => { setValue && setValue(def.id, value); }
			},
			children.map((child, cidx) => (
				<PageBuilderComponent
					key={`${key}-${cidx}`}
					index={index + cidx}
					def={child}
					parentId={parentId || def.id}
					columns={columns}
					parentZone={parentZone}
					setActiveElement={setActiveElement}
					setValue={setValue}
				/>
			))
		);
	}
}

export const DroppableColumnElement = (props) => {
	const { id, def, parentId, dropzone, setActiveElement, setValue } = props;
	const { children, ...columnProps } = def;
	const classes = props.classes ? props.classes : [];

	return (
		<React.Fragment key={id}>
			<Droppable
				droppableId={id}
				type="ELEMENT"
			>
				{(provided, snapshot) => (
					<ColumnElement
						innerRef={provided.innerRef}
						dropzone={dropzone}
						{...provided.droppableProps}
						{...columnProps}
						classes={[children && children.length ? "" : "is-empty", snapshot.isDraggingOver ? "is-dragging-over" : "", ...classes]}
					>
						{children && children.map((child, index) => (
							<PageBuilderComponent index={index} key={child.id} def={child} parentId={parentId || id}
												  parentZone={dropzone} setActiveElement={setActiveElement}
												  setValue={setValue}/>
						))}
						{provided.placeholder}
					</ColumnElement>
				)}
			</Droppable>
		</React.Fragment>
	);
};
