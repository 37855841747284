import { registerLoadingActions } from "../../loading";
import { registerErrorActions } from "../../error";
import {
	CloseNewEnvironmentModalAction,
	EnvironmentNewAction,
	EnvironmentNewFailedAction,
	EnvironmentNewSuccessAction,
	OpenNewEnvironmentModalAction
} from "../../../actions/data/environment/new";

registerLoadingActions(
	[EnvironmentNewAction.getType()],
	[EnvironmentNewSuccessAction.getType(), EnvironmentNewFailedAction.getType()]
);

registerErrorActions([
	EnvironmentNewFailedAction.getType()
]);

export const newReducer = (state = null, action) => {
	switch (action.type) {
		case OpenNewEnvironmentModalAction.getType():
			return { open: true, ...action.payload };
		case CloseNewEnvironmentModalAction.getType():
			return { open: false };
		case EnvironmentNewAction.getType():
			return { ...state, loading: true, error: null, model: action.payload };
		case EnvironmentNewSuccessAction.getType():
			return { open: false };
		case EnvironmentNewFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};
