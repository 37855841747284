import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import config, { CfgColors } from "../../../config";
import {
	EnvironmentLabel,
	EnvironmentList,
	EnvironmentScopeAccount,
	EnvironmentScopeNamespace
} from "../../../lib/constants";
import { Group, GroupNew } from "./Group";
import Tabs, { Tab } from "../../common/tabs/Tabs";
import { ElevatedAuthRequired } from "../../auth/access/ElevatedAuthRequired";
import { RoleRequired } from "../../auth/access/RoleRequired";

import "./Environments.scss";
import { authHasAnyRoles, RootOrSuperRoles } from "../../../lib/auth/token";

const colorCfg = config.get(CfgColors);

// Util

const applyToEnvironmentGroupsForScope = (env, environments, scope, assembled) => {
	const cur = environments[scope];
	if (!cur) {
		return assembled;
	}

	const curEnv = cur[env];
	if (!curEnv) {
		return assembled;
	}

	for (const [k, v] of Object.entries(curEnv)) {
		if (!assembled[k]) {
			assembled[k] = {
				...v,
				name: k,
				variables: {}
			};
		}

		const variables = assembled[k].variables;

		if (v && v.variables) {
			for (const vi of Object.values(v.variables)) {
				const curSettings = vi.settings || {};

				if (!variables[vi.name]) {
					variables[vi.name] = {
						...vi,
						settings: {
							parentLocked: false,
							locked: false,
							secret: false,
							...curSettings
						},
						paths: [vi.path]
					};
				} else {
					const prevSettings = variables[vi.name].settings || {};
					const prevPaths = variables[vi.name].paths;

					variables[vi.name] = {
						...vi,
						settings: {
							...prevSettings,
							parentLocked: (prevSettings.locked || curSettings.parentLocked),
							locked: (prevSettings.locked || curSettings.locked),
							secret: (prevSettings.secret || curSettings.secret)
						},
						paths: [vi.path, ...prevPaths]
					};
				}
			}
		}

		assembled[k].variables = variables;
	}

	return assembled;
};

export const getEnvironmentGroupsFor = (env, environments, scope, inherits) => {
	let assembled = {};

	// Apply inherits
	if (inherits && inherits.length > 0) {
		inherits.forEach(ih => {
			assembled = applyToEnvironmentGroupsForScope(env, environments, ih, assembled);
		});
	}

	// Apply main scope
	assembled = applyToEnvironmentGroupsForScope(env, environments, scope, assembled);

	// Turn to sorted list
	const sorted = Object.values(assembled);
	sorted.sort((a, b) => {
		const aWeight = a.weight || 0;
		const bWeight = b.weight || 0;

		if (aWeight < bWeight) {
			return -1;
		}
		if (aWeight > bWeight) {
			return 1;
		}

		return 0;
	});

	return sorted;
};

// Environment

export class Environment extends Component {
	render () {
		const {
			env, model, scope, path, inherits, openNewModal, doUpdateEnvironment, doDeleteEnvironment, doDeleteVariable,
			doUpsertVariable, preferences, setPreference, fetchEnvironments, openConfirm, update, allowNew, allowEdit
		} = this.props;

		const environments = model.environments || {};

		if (!EnvironmentLabel[env]) {
			throw new Error("invalid environment key: " + env);
		}
		const envLabel = EnvironmentLabel[env];

		const preferencesPath = `environments[${path}][${env}]`;
		const groups = getEnvironmentGroupsFor(env, environments, path, inherits);

		return (
			<div className={classNames(
				"environment-container",
				env
			)}
			>
				<p className="environment-container-title">
					{envLabel}
				</p>

				<div className="environment-container-groups">
					{(groups && groups.length > 0)
						? (
							groups.map((g, i) => {
								return (
									<Group
										key={g.name + i}
										group={g}
										env={env}
										scope={scope}
										path={path}
										inherits={inherits}
										preferencesPath={preferencesPath}
										preferences={preferences}
										setPreference={setPreference}
										openConfirm={openConfirm}
										doUpdateEnvironment={doUpdateEnvironment}
										doDeleteEnvironment={doDeleteEnvironment}
										doUpsertVariable={doUpsertVariable}
										doDeleteVariable={doDeleteVariable}
										fetchEnvironments={fetchEnvironments}
										update={update}
										allowEdit={allowEdit}
									/>
								);
							})
						)
						: (
							!allowNew && (
								<p className="no-env-msg">No <a
									href="/account/environments">environments</a> defined.</p>
							)
						)}

					{allowNew && (
						<GroupNew
							path={path}
							env={env}
							openNewModal={openNewModal}
							setPreference={setPreference}
						/>
					)}
				</div>
			</div>
		);
	}
}

Environment.propTypes = {
	env: PropTypes.string.isRequired,
	model: PropTypes.object.isRequired,
	scope: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	inherits: PropTypes.array.isRequired,
	openNewModal: PropTypes.func,
	doUpdateEnvironment: PropTypes.func.isRequired,
	doDeleteEnvironment: PropTypes.func.isRequired,
	doUpsertVariable: PropTypes.func.isRequired,
	doDeleteVariable: PropTypes.func.isRequired,
	preferences: PropTypes.object.isRequired,
	setPreference: PropTypes.func.isRequired,
	fetchEnvironments: PropTypes.func.isRequired,
	openConfirm: PropTypes.func,
	update: PropTypes.number.isRequired,
	allowNew: PropTypes.bool.isRequired,
	allowEdit: PropTypes.bool.isRequired
};

// Environments

export const Environments = ({
	model, scope, path, inherits, openNewModal, doUpdateEnvironment, doDeleteEnvironment,
	doUpsertVariable, doDeleteVariable, preferences, setPreference, fetchEnvironments, openConfirm,
	update, allowNew, allowEdit
}) => {
	return (
		<div className="environments-outer">
			{EnvironmentList.map(env => {
				return (
					<Environment
						key={env}
						env={env}
						model={model}
						scope={scope}
						path={path}
						inherits={inherits}
						openNewModal={openNewModal}
						doUpdateEnvironment={doUpdateEnvironment}
						doDeleteEnvironment={doDeleteEnvironment}
						doUpsertVariable={doUpsertVariable}
						doDeleteVariable={doDeleteVariable}
						preferences={preferences}
						setPreference={setPreference}
						fetchEnvironments={fetchEnvironments}
						openConfirm={openConfirm}
						update={update}
						allowNew={allowNew}
						allowEdit={allowEdit}
					/>
				);
			})}
		</div>
	);
};

Environments.propTypes = {
	model: PropTypes.object.isRequired,
	scope: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	inherits: PropTypes.array.isRequired,
	openNewModal: PropTypes.func,
	doUpdateEnvironment: PropTypes.func.isRequired,
	doDeleteEnvironment: PropTypes.func.isRequired,
	doUpsertVariable: PropTypes.func.isRequired,
	doDeleteVariable: PropTypes.func.isRequired,
	preferences: PropTypes.object.isRequired,
	setPreference: PropTypes.func.isRequired,
	fetchEnvironments: PropTypes.func.isRequired,
	openConfirm: PropTypes.func,
	update: PropTypes.number.isRequired,
	allowNew: PropTypes.bool.isRequired,
	allowEdit: PropTypes.bool.isRequired
};

// EnvironmentScopes

export const EnvironmentScopes = ({
	model, openNewModal, doUpdateEnvironment, doDeleteEnvironment, doUpsertVariable, doDeleteVariable,
	preferences, setPreference, requestElevation, fetchEnvironments, openConfirm, update
}) => {
	const haveRootOrSuper = authHasAnyRoles(RootOrSuperRoles);

	return (
		<div className="up-form with-tabs">
			<div className="modal-card-body">
				<Tabs borderColor={colorCfg.blue}>
					<Tab id="account" title="Account">
						<Environments
							model={model}
							scope={EnvironmentScopeAccount}
							path={EnvironmentScopeAccount}
							inherits={[EnvironmentScopeNamespace]}
							openNewModal={openNewModal}
							doUpdateEnvironment={doUpdateEnvironment}
							doDeleteEnvironment={doDeleteEnvironment}
							doUpsertVariable={doUpsertVariable}
							doDeleteVariable={doDeleteVariable}
							preferences={preferences}
							setPreference={setPreference}
							fetchEnvironments={fetchEnvironments}
							openConfirm={openConfirm}
							update={update}
							allowNew
							allowEdit
						/>
					</Tab>
					<Tab id="global" title="Global" disabled={!haveRootOrSuper}>
						<RoleRequired requireAnyRoles={RootOrSuperRoles}>
							<ElevatedAuthRequired requestElevation={requestElevation}>
								<Environments
									model={model}
									scope={EnvironmentScopeNamespace}
									path={EnvironmentScopeNamespace}
									inherits={[]}
									openNewModal={openNewModal}
									doUpdateEnvironment={doUpdateEnvironment}
									doDeleteEnvironment={doDeleteEnvironment}
									doUpsertVariable={doUpsertVariable}
									doDeleteVariable={doDeleteVariable}
									preferences={preferences}
									setPreference={setPreference}
									fetchEnvironments={fetchEnvironments}
									openConfirm={openConfirm}
									update={update}
									allowNew
									allowEdit
								/>
							</ElevatedAuthRequired>
						</RoleRequired>
					</Tab>
				</Tabs>
			</div>
		</div>
	);
};

EnvironmentScopes.propTypes = {
	model: PropTypes.object.isRequired,
	openNewModal: PropTypes.func.isRequired,
	doUpdateEnvironment: PropTypes.func.isRequired,
	doDeleteEnvironment: PropTypes.func.isRequired,
	doUpsertVariable: PropTypes.func.isRequired,
	doDeleteVariable: PropTypes.func.isRequired,
	preferences: PropTypes.object.isRequired,
	setPreference: PropTypes.func.isRequired,
	requestElevation: PropTypes.func.isRequired,
	fetchEnvironments: PropTypes.func.isRequired,
	openConfirm: PropTypes.func.isRequired,
	update: PropTypes.number.isRequired
};
