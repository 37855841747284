import { registerLoadingActions } from "../../loading";
import { registerErrorActions } from "../../error";
import {
	EnvironmentDeleteAction,
	EnvironmentDeleteFailedAction,
	EnvironmentDeleteSuccessAction
} from "../../../actions/data/environment/delete";

registerLoadingActions(
	[EnvironmentDeleteAction.getType()],
	[EnvironmentDeleteSuccessAction.getType(), EnvironmentDeleteFailedAction.getType()]
);

registerErrorActions([
	EnvironmentDeleteFailedAction.getType()
]);

export const deleteReducer = (state = null, action) => {
	switch (action.type) {
		default:
			return state;
	}
};
