import React from "react";
import { Accordion, AccordionGroup } from "core/components/common/accordion";
import { PageBuilderComponentGroup } from "./PageBuilderComponentGroup";

const GroupContainer = ({ group }) => (
	<AccordionGroup icon={group.icon} title={group.title}>
		<PageBuilderComponentGroup group={group}/>
	</AccordionGroup>
);

export const PageBuilderComponentList = ({ components, componentGroups }) => (
	<Accordion>
		{(componentGroups).map((group) => {
			group = {
				...group,
				components: group.components.map(c => components[c])
			};

			return <GroupContainer key={group.id} group={group}/>;
		})}
	</Accordion>
);

export default PageBuilderComponentList;
